import React, { useState, useEffect, useRef } from "react";
import './FAQs.css';
import DesktopBG from '../assets/bg.png';
import MobileBG from '../assets/bg.png';
import { useNavigate, Link } from 'react-router-dom';
import {  generateSvgBackgroundDataUrl } from "./SvgBackground"; // Adjust the path as needed



// Import PDFs from assets
import Pdf1 from '../assets/Howtocreateapoll_PDF.pdf';
import Pdf2 from '../assets/Howtodeletepolls_PDF.pdf';
import Pdf3 from '../assets/Howtosharepolls_PDF.pdf';
import Pdf4 from '../assets/Howtodownloadpolldata_PDF.pdf';
import Pdf5 from '../assets/Howtogenerateadatareportonline_PDF.pdf';
import Pdf6 from '../assets/Howtoresetyourpassword_PDF.pdf';
import Pdf7 from '../assets/HowdoISubscribetootherOPINARIusers_PDF.pdf';
import Pdf8 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf9 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf11 from '../assets/Howtocreateconnectedpolls_PDF.pdf';
import Pdf12 from '../assets/Howtoresetyourpassword_PDF.pdf';
import Pdf13 from '../assets/HowdoISubscribetootherOPINARIusers_PDF.pdf';
import Pdf14 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf15 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf16 from '../assets/Howtocreateconnectedpolls_PDF.pdf';
import Pdf17 from '../assets/Howtocreateconnectedpolls_PDF.pdf';
import Pdf10 from '../assets/Howtocreateconnectedpolls_PDF.pdf';




import Logo from '../assets/logo.png';
import HeroImg from '../assets/Hero.png';
import YouTubeIcon from '../assets/youtube.png';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import LinkedInIcon from '../assets/linkedin.png';
import PinterestIcon from '../assets/pinterest.png';
import RedditIcon from '../assets/reddit.png';
import SnapchatIcon from '../assets/snapchat.png';
import TikTokIcon from '../assets/tiktok.png';
import XIcon from '../assets/x.png';
import OpinionsImg from '../assets/opinions.png';
import FlexibilityImg from '../assets/flexibility.png';
import HandledImg from '../assets/handled.png';

const FAQPage = () => {
  const isDesktop = window.innerWidth >= 768;

   const [bgDataUrl, setBgDataUrl] = useState(""); // Background SVG
      const [dotColor, setDotColor] = useState("#222222"); // Default dot color
    

  const backgroundStyle = {
    backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };


   useEffect(() => {
      // Generate initial background
      setBgDataUrl(
        generateSvgBackgroundDataUrl({
          dotColor,
          bgColor: "#0c0c0c",
          spacing: 20,
          dotRadius: 1,
        })
      );
    }, [dotColor]);

  const openPdf = (pdfSrc) => {
    window.open(pdfSrc, '_blank');
  };

  const faqItems = [
    { pdf: Pdf11, question: "Why should I use OPINARI?", description: "OPINARI is the only platform designed to help you earn at the speed of social, turning your audience's opinions into profit through opinion-driven shopping." },

    { pdf: Pdf11, question: "How is revenue distributed?", description: "An exciting 70% gross sales affiliate commission goes to you as a poll creator from all sales through your polls. See earnings in your OPINARI dashboard anytime." },

    {
      pdf: Pdf11,
      question: "What are the affiliate commission rates I earn from?",
      description: (
        <>
          You can check Amazon's official commission rates here:{" "}
          <a
            href="https://affiliate-program.amazon.com/help/node/topic/GRXPHT8U84RAYDXZ"
            target="_blank"
            rel="noopener noreferrer"
          >
            Amazon Commission Rates
          </a>.
        </>
      )
    },
    
    
    { pdf: Pdf11, question: "How is this possible?", description: "OPINARI’s patent-pending revolutionary color wheel boosts user engagement with lightning-fast, thumb-friendly navigation, perfectly optimized for mobile." },

    { pdf: Pdf11, question: "Who provides the shopping experience?", description: "Amazon, our ultimate shopping partner, gives you access to the largest and most diverse product range, ensuring big earnings from unbeatable items." },

    { 
      pdf: Pdf11, 
      question: "When do I get paid?", 
      description: "Payments are sent approximately 60 days after the end of the month in which you earned commissions. For example, earnings in January will be paid by the end of March. After this initial payment, you will receive monthly commissions."
      
    },

    { pdf: Pdf11, question: "How do I make money?", description: "Earn money every time someone buys a product from your poll. You can create as many polls as you like." },

    { pdf: Pdf13, question: "How do I get paid?", description: "We process all payments to our registered users via PayPal directly from your OPINARI dashboard." },


    { pdf: Pdf15, question: "How long does it take to start?", description: "Immediately after you create your account you can build your first poll and share it for anyone to vote on and start making money." },

    { pdf: Pdf16, question: "How do I make a poll?", description: "Navigate to the Dashboard page by signing in. Once in the Dashboard simply click the “+” plus button." },

    { pdf: Pdf17, question: "How do I delete polls?", description: "Select the poll you want to delete and then scroll to the bottom of the poll and click “Delete Poll” button." },

    { pdf: Pdf1, question: "How do I share polls?", description: "Click the share icon in the top right corner of every poll that you want to share - it’s the arrow icon." },

    { pdf: Pdf2, question: "How do I download my poll data?", description: "Click the “Download Data” button within the tools dropdown located in the top left-hand corner of your poll - it’s the gear icon." },

    { pdf: Pdf3, question: "How do I generate a report?", description: "Click the “Data Report” button within the tools dropdown located in the top left-hand corner of the poll - it’s the gear icon." },

    { pdf: Pdf6, question: "How do I reset my password?", description: "Click the \"Login\" button in the top right-hand corner of the home page. Click the \"Forgot Password\" button below login info. Enter in your account email and hit \"Send Reset Link\". Follow the instructions within the password reset email." },

    { pdf: Pdf8, question: "How do I report polls?", description: "Click the three dots in the top left-hand corner of the poll you wish to report. Follow the instructions within the report poll window. Once submitted, our team will review the poll and take further action." },

];


  return (
    <div className="faq-page-wrapper"
    style={{
      backgroundImage: `url(${bgDataUrl})`,
      backgroundAttachment: "fixed", // Ensures parallax effect
      backgroundRepeat: "repeat", // Ensures the pattern tiles
      backgroundSize: `20px 20px`, // Makes sure the pattern tiles correctly
      minHeight: "100%",
      paddingTop: '10px'
    }}>
      
      <div className="faq-page">
        
        <div className="plain-page-header">

<h2>Frequently Asked Questions</h2>

</div>         

        <div className="faq-grid">
          {faqItems.map((item, index) => (
            <div 
              key={index} 
              className="faq-item"
            >
              <div className="faq-item-content">
                <h3 className="faq-item-title">{`${item.question}`}</h3>
                <p className="faq-item-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      
    </div>
  );
};

export default FAQPage;