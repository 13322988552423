import React, { useEffect, useState, useRef } from "react";
import { Clipboard, QrCode, Check, Share, CornerDownRight } from "lucide-react";
import QRCodeGeneratorCreate from "./QRCodeGeneratorCreate"; // Import QR code component
import "./HomeShare.css";
import Icon from '../assets/logo-icon.png';

const HomeShare = ({ pollLink }) => {
  const [copied, setCopied] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const modalRef = useRef(null);

  // Trigger fade-in effect
  useEffect(() => {
    setTimeout(() => setFadeIn(true), 100);
  }, []);

  // Copy to clipboard functionality
  const handleCopy = () => {
    navigator.clipboard.writeText(pollLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2s
  };

  // Open OS share options
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Poll Invitation",
          text: "What's your opinion?",
          url: pollLink,
        })
        .catch((error) => console.error("Error sharing:", error));
    } else {
      alert("Sharing is not supported on this device.");
    }
  };

  return (
    <div>
      <div className={`home-share-container ${fadeIn ? "fade-in" : ""}`}>
        {/* Arrow Icon positioned outside and to the left */}
        <div className="home-share-arrow">
          <CornerDownRight size={30} />
        </div>

        {/* Main share container */}
        <div className="home-share-box">
          <a href={pollLink} target="_blank" rel="noopener noreferrer" className="home-share-button view-demo-button">
            View
            <img src={Icon} alt="Poll Icon" className="home-share-icon" />
          </a>
          <button className="home-share-button" onClick={handleCopy}>
            {copied ? <Check size={18} /> : <Clipboard size={18} />}
          </button>
          <button className="home-share-button" onClick={() => setShowQRModal(true)}>
            <QrCode size={18} />
          </button>
          <button className="home-share-button" onClick={handleShare}>
            <Share size={18} />
          </button>
        </div>
      </div>

      {/* QR Code Modal */}
      {showQRModal && (
        <div className="poll-success-modal-overlay">
          <div className="poll-qr-modal-content" ref={modalRef}>
            <h2 className="poll-success-modal-title">Poll QR Code</h2>
            <div className="poll-success-qr-container">
              <QRCodeGeneratorCreate pollLink={pollLink} />
            </div>
            <div className="poll-success-modal-buttons">
              <button onClick={() => setShowQRModal(false)} className="poll-success-close-button">
                Back
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeShare;
