import React, { useState, useEffect } from 'react';
import './About.css';
import DesktopBG from '../assets/bg.png';
import MobileBG from '../assets/bg.png';
import ScrollingPollsVertical from './ScrollingPollsVertical';
import ScrollingPollsVerticalReverse from './ScrollingPollsVerticalReverse';
import { useNavigate, Link } from 'react-router-dom';
import {  generateSvgBackgroundDataUrl } from "./SvgBackground"; // Adjust the path as needed



import Logo from '../assets/logo.png';
import HeroImg from '../assets/Hero.png';
import YouTubeIcon from '../assets/youtube.png';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import LinkedInIcon from '../assets/linkedin.png';
import PinterestIcon from '../assets/pinterest.png';
import RedditIcon from '../assets/reddit.png';
import SnapchatIcon from '../assets/snapchat.png';
import TikTokIcon from '../assets/tiktok.png';
import XIcon from '../assets/x.png';
import OpinionsImg from '../assets/opinions.png';
import FlexibilityImg from '../assets/flexibility.png';
import HandledImg from '../assets/handled.png';
import Icon from '../assets/logo-icon.png';

import Tutorial1 from "../assets/tutorial1.jpg";
import Tutorial2 from "../assets/tutorial2.jpg";
import Tutorial3 from "../assets/tutorial3.jpg";
import Tutorial4 from "../assets/tutorial4.jpg";
import Tutorial5 from "../assets/tutorial5.jpg";
import Tutorial6 from "../assets/tutorial6.jpg";
import Tutorial7 from "../assets/tutorial7.jpg";


const handleClick = () => {
  window.location.href = "/signup";
};


const About = ({ isLoggedIn }) => {
    const isDesktop = window.innerWidth >= 768;

    const backgroundStyle = {
      backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
    };

    const navigate = useNavigate();

      const [bgDataUrl, setBgDataUrl] = useState(""); // Background SVG
      const [dotColor, setDotColor] = useState("#222222"); // Default dot color
    

    const goToSignUp = () => {
      navigate('/signup');
    };
    const goToDashboard = () => {
      navigate('/dashboard/main');
    };
    const goToFeed = () => {
        navigate('/feed');
      };

       useEffect(() => {
          // Generate initial background
          setBgDataUrl(
            generateSvgBackgroundDataUrl({
              dotColor,
              bgColor: "#0c0c0c",
              spacing: 20,
              dotRadius: 1,
            })
          );
        }, [dotColor]);

    return (
      
        <div className="about-page-wrapper"
        style={{
          backgroundImage: `url(${bgDataUrl})`,
          backgroundAttachment: "fixed", // Ensures parallax effect
          backgroundRepeat: "repeat", // Ensures the pattern tiles
          backgroundSize: `20px 20px`, // Makes sure the pattern tiles correctly
          minHeight: "100%",
          paddingTop: '10px'
        }}>


                <div className="plain-page-header">

                <h2>How It Works</h2>

                </div>

            <div className="about-container">
                <div className="combined-sections-container">
                <section className="about-para-section">
  <div className="text-content">
    <p>
    <h3 class="about-item-title">Step 1</h3>
Head to your dashboard -  <a href="https://opinari.io/dashboard/main" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>https://opinari.io/dashboard/main</a>.
<br /><br />
<img
  src={Tutorial1}
  alt="Tutorial step 1"
  className="tutorial-image"
/>
<br /><br />




      <h3 class="about-item-title">Step 2</h3>
      Start by creating a poll by hitting the "Create Poll" Button.
      <br /><br />

      <img
              src={Tutorial2}
              alt="Tutorial step 1"
              className="tutorial-image"
            />

<br /><br />


      <h3 class="about-item-title">Step 3</h3>
      Once you have created a poll, use our plethora of share tools to reach your audience on any social platform.
      <br /><br />

      <img
              src={Tutorial3}
              alt="Tutorial step 1"
              className="tutorial-image"
            />

<br /><br />


      <h3 class="about-item-title">Step 4</h3>
      Once your poll receives its first vote, the product fetching process will begin! We search over 350 million products to find the perfect matches for your poll.
      <br /><br />

      <img
              src={Tutorial4}
              alt="Tutorial step 1"
              className="tutorial-image"
            />

<br /><br />


      <h3 class="about-item-title">Step 6</h3>
      All the products on your poll are now fully set up for you to start earning from, every purchase from your polls will automatically be attributed to you, and paid out to your PayPal account.
      <br /><br />

      <img
              src={Tutorial6}
              alt="Tutorial step 1"
              className="tutorial-image"
            />

<br /><br />


      <h3 class="about-item-title">Step 7</h3>
      Finally, you can refer friends using your referal link to earn 9% of all revenue they generate, paid from our share, not theirs!
      <br /><br />

      <img
              src={Tutorial7}
              alt="Tutorial step 1"
              className="tutorial-image"
            />




      {/* <iframe 
        src="https://www.youtube.com/embed/acWPCFGJUMs?si=9Zb-J0iCvUkNDqbL"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        style={{
          width: "100%",
          height: "auto",
          aspectRatio: "16 / 9",
          borderRadius: "10px"
        }}
      ></iframe> */}

      <br /><br />

   

      <div className="about-footer">
        <button className="start-earning-button" onClick={handleClick}>
          <img src={Icon} alt="Icon" className="button-icon" />
          <span>Start Earning!</span>
        </button>
      </div>
    </p>
  </div>
</section>



  

 
  




                    
                </div>
            </div>

            
        </div>
    );
};

export default About;