import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import Home from './components/Home';
import HomeBrands from './components/HomeBrands';

import Feed from './components/Feed';
import StandalonePoll from './components/StandalonePoll';
import ReviewPoll from './components/ReviewPoll';

import EmbedPoll from './components/EmbedPoll';
import SignUpPage from './components/SignUpPage'; // Updated import
import LoginPage from './components/LoginPage'; // Updated import
import Profile from './components/Profile';
import PublicProfile from './components/PublicProfile';
import { jwtDecode } from 'jwt-decode'; // Fixed import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import VerifyEmail from './components/VerifyEmail';
import Poll from './components/Poll';
import MainDashboard from './components/MainDashboard';
import BrandDashboard from './components/BrandDashboard';

import ResetPassword from './components/ResetPassword';
import InitiateResetPassword from './components/InitiateResetPassword';
import LiveDashboard from './components/LiveDashboard'; // Import LiveDashboard
import ConnectedPoll from './components/ConnectedPoll'; // Import ConnectedPoll component
import NotificationsPage from './components/NotificationsPage'; // Import NotificationsPage component
import PaymentSuccess from './components/PaymentSuccess';
import CreatePoll from './components/CreatePoll';
import logger from './components/logger';
import Contact from './components/Contact';
import About from './components/About';
import Privacy from './components/Privacy';
import UserAgreement from './components/UserAgreement';
import Terms from './components/Terms';
import FAQs from './components/FAQs';
import Team from './components/Team';
import ProfileSettings from './components/ProfileSettings'; // Import ProfileSettings
import Logo from './assets/logo.png';
import AdminDashboard from './components/AdminDashboard'; // Import AdminDashboard at the top.
import Storefront from './components/Storefront'; // Import AdminDashboard at the top.
import HowItWorks from './components/HowItWorks'; 



import NavSection from './NavSection';
import { Youtube, Instagram, Twitter, Linkedin } from "lucide-react";












function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [userEmail, setUserEmail] = useState('');
    // Determine if the current route is for login or signup
  const isHomePage = location.pathname === '/'; // Add this inside the App component
  const [subscriptionStatus, setSubscriptionStatus] = useState(''); // State for subscription status
  const isAuthRoute = location.pathname === '/signup' || location.pathname === '/login';
  const isEmbedRoute = location.pathname.startsWith('/embed/');
  const hideNavBarRoutes = ['/dashboard/main', '/poll'];
  const [firstName, setFirstName] = useState(''); // Add firstName state
  const [lastName, setLastName] = useState('');  // Add lastName state
  const [isHomeBrands, setIsHomeBrands] = useState(false); // State to toggle between Home and HomeBrands

  
  const hideTopBarRoutes = ['/dashboard/main'];

  const shouldHideTopBar = hideTopBarRoutes.includes(location.pathname);

  const [hash, setHash] = useState(''); // Add this line

  const [currentDashboard, setCurrentDashboard] = useState('creator');

  const toggleDashboard = () => {
    setCurrentDashboard((prev) => (prev === 'creator' ? 'brand' : 'creator'));
  };


  const isPollRoute = location.pathname.startsWith('/poll/'); 
const shouldHideNavBar = isEmbedRoute || isAuthRoute || hideNavBarRoutes.includes(location.pathname) || isPollRoute;


  // Function to fetch the token
  const fetchToken = async () => {
    try {
      const response = await fetch('/api/generate-token');
      const { accessToken } = await response.json();
      localStorage.setItem('accessToken', accessToken); // Store the access token in localStorage

      // Decode the token to get the expiration time
      const decodedToken = jwtDecode(accessToken);
      logger.log('Access token fetched and stored:', accessToken);
      logger.log('Token expires at:', new Date(decodedToken.exp * 1000).toISOString());
    } catch (error) {
      logger.error('Error fetching access token:', error);
    }
  };


  useEffect(() => {
    // If the user is not logged in, fetch a token when the app loads
    if (!isLoggedIn) {
      fetchToken();
    }
  }, [isLoggedIn]);


  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
        if (!userId) return; // Only fetch if userId is available
  
        try {
            const response = await fetch(`/auth/subscription-status/${userId}`);
            if (response.ok) {
                const data = await response.json();
                setSubscriptionStatus(data.subscriptionStatus); // Update subscription status
                setUsername(data.username);
            } else {
                logger.error('Failed to fetch subscription status, setting as Free');
                setSubscriptionStatus('Free'); // Default to Free if fetching fails
            }
        } catch (error) {
            logger.error('Error fetching subscription status:', error);
            setSubscriptionStatus('Free'); // Default to Free on any error
        }
    };
  
    fetchSubscriptionStatus();
}, [userId]);

  
    // Scroll to top whenever the route changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  


  const getSubscriptionColor = () => {
    switch (subscriptionStatus.toLowerCase()) {
      case 'free':
        return '#02db2a'; // Custom hex for green
      case 'pro':
        return '#0967ff'; // Custom hex for blue
      case 'elite':
        return 'orange'; // Custom hex for orange
      case 'unlimited':
        return '#de00de'; // Custom hex for purple
      default:
        return '#808080'; // Custom hex for gray
    }
  };




  useEffect(() => {
    const trackPageView = (url) => {
      if (window.gtag) {
        // Track the general page view
        window.gtag('config', 'G-6CYJ9YMLWP', {
          page_path: url,
        });
  
        // Custom events for specific pages
        if (url === '/') {
          window.gtag('event', 'homepage_view', {
            event_category: 'Page Views',
            event_label: 'Home Page',
          });
        } else if (url === '/feed') {
          window.gtag('event', 'feedpage_view', {
            event_category: 'Page Views',
            event_label: 'Feed Page',
          });
        } else if (url.startsWith('/poll/')) {
          window.gtag('event', 'standalone_poll_view', {
            event_category: 'Page Views',
            event_label: 'Standalone Poll',
          });
        }
      }
    };
  
    // Track the initial page load
    trackPageView(window.location.pathname + window.location.search);
  
    // Track page changes
    const unlisten = () => {
      trackPageView(window.location.pathname + window.location.search);
    };
    return () => {
      unlisten();
    };
  }, [location]);
  


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        if (decoded.exp * 1000 > Date.now()) {
          setIsLoggedIn(true);
          setUserId(decoded.userId);
          fetch(`/auth/user/${decoded.userId}`)
            .then((response) => response.json())
            .then((data) => {
              logger.log('User data fetched from backend:', data);
              setUsername(data.username);
              setUserEmail(data.email);
              setFirstName(data.firstName || ''); // Set firstName
              setLastName(data.lastName || '');  // Set lastName
              setHash(data.hash || '');         // Add this line to set the hash
            })
            .catch((error) => logger.error('Error fetching user data:', error))
            .finally(() => setIsLoadingUser(false));
        } else {
          localStorage.removeItem('token');
          setIsLoadingUser(false);
        }
      } catch (error) {
        localStorage.removeItem('token');
        setIsLoadingUser(false);
      }
    } else {
      setIsLoadingUser(false);
    }
  }, [navigate]);

   // Combine firstName and lastName into a full name
   const fullName = `${firstName} ${lastName}`.trim();
  

  const handleSignUp = (token, userId, username) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    setUserId(userId);
    setUsername(username);
    navigate('/dashboard/main'); // Update this to redirect to /dashboard/main
  };
  
  const handleLogin = (token, userId, username) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    setUserId(userId);
    setUsername(username);
    navigate('/dashboard/main'); // Update this to redirect to /dashboard/main
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUserId(null);
    setUsername('');
    setIsMenuOpen(false);
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const toggleHomeView = () => {
    setIsHomeBrands((prev) => !prev);
  };
  

  useEffect(() => {
    if (isEmbedRoute) {
      document.body.classList.add('embed-poll-body');
    } else {
      document.body.classList.remove('embed-poll-body');
    }
  }, [isEmbedRoute]);

  return (
    <div className="App">
      
      {isLoadingUser ? (
        <div>Loading...</div> // Or a spinner, etc.
      ) : (
        <>
 {!shouldHideNavBar && (
        <NavSection isLoggedIn={isLoggedIn}   toggleHomeView={toggleHomeView} // Pass the toggle function
        isHomeBrands={isHomeBrands} />

)}

          {/* Main Content */}
          <main className={`App-main ${shouldHideTopBar ? 'without-topbar' : ''}`}>
          <Routes>
          <Route
            exact
            path="/"
            element={isHomeBrands ? <HomeBrands isLoggedIn={isLoggedIn} /> : <Home isLoggedIn={isLoggedIn} />}
          />

  <Route
    path="/shop"
    element={<Feed isLoggedIn={isLoggedIn} userId={userId} username={username} isProfileView={false} />}
  />

  <Route
    path="/review/:hash"
    element={<ReviewPoll isLoggedIn={isLoggedIn} userId={userId} username={username} />}
  />


  <Route
    path="/poll/:hash"
    element={<StandalonePoll isLoggedIn={isLoggedIn} userId={userId} username={username} />}
  />

<Route path="/storefront/:username" element={<Storefront />} />

  <Route
    path="/embed/:hash"
    element={<EmbedPoll isLoggedIn={isLoggedIn} userId={userId} username={username} />}
  />
  <Route path="/verify-email" element={<VerifyEmail />} />
  <Route path="/initiate-reset-password" element={<InitiateResetPassword />} />
  <Route path="/reset-password/:token" element={<ResetPassword />} />
  <Route
    path="/dashboard/:hash"
    element={<LiveDashboard userId={userId} username={username} userEmail={userEmail} />}
  />
  <Route
    path="/connectedpoll/:hash"
    element={<ConnectedPoll isLoggedIn={isLoggedIn} userId={userId} username={username} />}
  />
  <Route path="/payment-success" element={<PaymentSuccess />} />
  <Route path="/signup" element={<SignUpPage onSignUp={handleSignUp} />} />
  <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
  <Route path="/contact" element={<Contact />} />
  <Route path="/about" element={<About isLoggedIn={isLoggedIn} />} />
  <Route path="/user-agreement" element={<UserAgreement isLoggedIn={isLoggedIn} />} />
  <Route path="/privacy" element={<Privacy />} />
  <Route path="/terms" element={<Terms />} />
  <Route path="/faqs" element={<FAQs />} />
  <Route path="/team" element={<Team />} />
  <Route path="/how-it-works" element={<HowItWorks />} />


  <Route
    path="/dashboard/main"
    element={
      username === 'Admin' ? (
        <AdminDashboard /> // Render the AdminDashboard for admin user
      ) : subscriptionStatus === 'Enterprise' ? (
        <BrandDashboard
          userId={userId}
          username={username}
          userEmail={userEmail}
          isProfileView={true}
          isLoggedIn={isLoggedIn}
          subscriptionStatus={subscriptionStatus}
          userHash={hash} // Pass the hash prop here
        />
      ) : (
        <MainDashboard
          userId={userId}
          username={username}
          userEmail={userEmail}
          isProfileView={true}
          isLoggedIn={isLoggedIn}
          subscriptionStatus={subscriptionStatus}
          name={fullName} // Pass the full name here
          userHash={hash} // Pass the hash prop here

        />
        
      )
    }
  />
</Routes>


{!location.pathname.startsWith('/dashboard') && 
 !location.pathname.startsWith('/poll') && 
 !location.pathname.startsWith('/shop') && 
 !location.pathname.startsWith('/storefront') && 
 location.pathname !== '/' && (
  <footer className="footer">
    <div className="footer-content">
      <div className="footer-socials">
        <a href="https://www.youtube.com/@Opinaripolls" className="footer-icon"><Youtube strokeWidth={1.25} /></a>
        <a href="https://www.instagram.com/opinari.io/" className="footer-icon"><Instagram strokeWidth={1.25} /></a>
        <a href="https://x.com/Opinaripolls" className="footer-icon"><Twitter strokeWidth={1.25} /></a>
        <a href="https://www.linkedin.com/company/opinari-technologies/" className="footer-icon"><Linkedin strokeWidth={1.25} /></a>
      </div>

      <div className="footer-links">
        <a href="/privacy">Privacy</a>
        <a href="/terms">Terms</a>
        <a href="/contact">Contact</a>
      </div>

      <p className="footer-copyright">
        &copy; 2025 OPINARI Technologies Inc.
      </p>
      <p className="footer-patent">Patent Pending</p>
    </div>
  </footer>
)}




          </main>
  
          
        </>
      )}
    </div>
  );
  
  
  
}

window.Poll = Poll;

export default App;
