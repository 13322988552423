// SvgBackground.js

/**
 * Generates a data URL for an SVG background with a grid of dots.
 *
 * @param {Object} options
 * @param {string} options.dotColor - The color of the dots (default: "#ff69b4" – hot pink).
 * @param {string} options.bgColor - The background color (default: "#1a1a1a" – dark gray).
 * @param {number} options.spacing - The spacing between dots (default: 40px).
 * @param {number} options.dotRadius - The radius of each dot (default: 6px).
 * @returns {string} A data URL that can be used as a CSS background image.
 */
export const generateSvgBackgroundDataUrl = ({
    dotColor = "#ff69b4",  
    bgColor = "#1a1a1a",
    spacing = 40,
    dotRadius = 6,
  } = {}) => {
    const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" width="${spacing}" height="${spacing}">
        <rect width="100%" height="100%" fill="${bgColor}" />
        <circle cx="${spacing / 2}" cy="${spacing / 2}" r="${dotRadius}" fill="${dotColor}" />
      </svg>
    `;
  
    const encodedSvg = encodeURIComponent(svgString)
      .replace(/'/g, "%27")
      .replace(/"/g, "%22");
  
    return `data:image/svg+xml;charset=utf8,${encodedSvg}`;
  };
  