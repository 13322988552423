import React, { useEffect, useRef, useState } from 'react';
import './PollSuccessModal.css';
import logger from './logger';
import QRCodeGenerator from './QRCodeGeneratorStorefront'; // Import the new QRCodeGenerator component

const StorefrontShareModal = ({ storefrontLink, onClose, userId, username }) => {
  const [isCopied, setIsCopied] = useState(false);
  const modalRef = useRef(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(storefrontLink)
      .then(() => setIsCopied(true))
      .catch((error) => logger.error('Failed to copy link:', error));
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: `${username}'s Storefront`,
        text: `Check out ${username}'s polls!`,
        url: storefrontLink,
      });
    } catch (error) {
      logger.error('Error sharing storefront link:', error);
      alert('Failed to share storefront link');
    }
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="poll-success-modal-overlay">
      <div className="poll-success-modal-content" ref={modalRef}>
        <h2 className="poll-success-modal-title">Share Storefront!</h2>
        <div className="poll-success-link-container">
          <input type="text" value={storefrontLink} readOnly className="poll-success-link-input" />
          <button
            onClick={handleCopy}
            className={`poll-success-copy-button ${isCopied ? 'copied' : ''}`}
          >
            <i className={isCopied ? 'fa-regular fa-check' : 'fa-regular fa-copy'}></i>
          </button>
        </div>

        <div className="poll-success-qr-container" style={{ textAlign: 'left', marginBottom: '25px' }}>
  <QRCodeGenerator userId={userId} username={username} />
</div>


        <div className="poll-success-modal-buttons">
          <button onClick={handleShare} className="poll-success-close-button">More Share Options</button>
          <button onClick={onClose} className="poll-success-close-button">Close</button>
        </div>
      </div>
    </div>
  );
};

export default StorefrontShareModal;
