import React, { useState, useEffect } from 'react';
import Poll from './Poll'; // Import the Poll component
import PollSuccessModal from './PollSuccessModal'; // Import PollSuccessModal

const CampaignPolls = ({ 
    campaignId, 
    userId, 
    username, 
    userEmail, 
    isLoggedIn, 
    pollSuccessData, 
    onCloseSuccessModal, 
    onOpenCreatePoll // New prop
}) => {
    const [polls, setPolls] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [campaignStatus, setCampaignStatus] = useState('Live'); 

    const fetchCampaignDetails = async () => {
        try {
            const response = await fetch(`/api/campaigns/${campaignId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch campaign details');
            }
    
            const campaign = await response.json();
            setCampaignStatus(campaign.active ? 'Live' : 'Paused'); // Set the initial status
        } catch (error) {
            console.error('Error fetching campaign details:', error);
        }
    };


    const toggleCampaignStatus = async () => {
        try {
            const newStatus = campaignStatus === 'Live' ? 'Paused' : 'Live';
            const response = await fetch(`/api/campaigns/${campaignId}/toggle-status`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ active: newStatus === 'Live' }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to toggle campaign status');
            }
    
            const data = await response.json();
            setCampaignStatus(newStatus); // Update campaign status in the UI
            fetchCampaignPolls(); // Refresh polls after status change
        } catch (error) {
            console.error('Error toggling campaign status:', error);
            alert('Failed to update campaign status. Please try again.');
        }
    };
    

 
    const deleteCampaign = () => {
        alert('Campaign deleted! (Frontend only for now)');
    };

    const handleCreatePoll = () => {
        onOpenCreatePoll(campaignId); // Pass the campaignId to the parent
    };


    const fetchCampaignPolls = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`/campaigns/${campaignId}/polls`);
            if (!response.ok) {
                throw new Error('Failed to fetch campaign polls');
            }
            const data = await response.json();
            setPolls(data.polls);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCampaignPolls();
    }, [campaignId]);

    useEffect(() => {
        if (pollSuccessData) {
            setShowSuccessModal(true);
            fetchCampaignPolls(); // Refresh polls immediately after creation
        }
    }, [pollSuccessData]);

    useEffect(() => {
        fetchCampaignDetails();
        fetchCampaignPolls();
    }, [campaignId]);
    

    useEffect(() => {
        if (pollSuccessData) {
            setShowSuccessModal(true);
        }
    }, [pollSuccessData]);


    const handleDeletePoll = (pollId) => {
        setPolls((prevPolls) => prevPolls.filter((poll) => poll._id !== pollId));
    };

    const closeModal = () => {
        setShowSuccessModal(false);
        onCloseSuccessModal(); // Reset modal state in the parent component
    };

    if (isLoading) {
        return <p>Loading polls...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }


    

    return (

        <div className="null">

        {/* Configuration Bar */}
        <div className="campaign-config-bar">
    <div className="campaign-status">
        <div className={`status-indicator ${campaignStatus.toLowerCase()}`}></div>
        <span>{campaignStatus}</span>
    </div>
    <button className="status-toggle-button" onClick={toggleCampaignStatus}>
        {campaignStatus === 'Live' ? 'Pause Campaign' : 'Resume Campaign'}
    </button>
    <button className="delete-campaign-button" onClick={deleteCampaign}>
        Delete Campaign
    </button>
    <button
                    className="add-poll-button"
                    onClick={handleCreatePoll}
                >
                    Create New Poll
                </button>
</div>

        <div className="Root-grid-container">
            {showSuccessModal && pollSuccessData && (
                <PollSuccessModal
                    pollLink={pollSuccessData.pollLink}
                    isTemporary={pollSuccessData.isTemporary}
                    onClose={closeModal}
                />
            )}

            {polls.map((poll) => (
                <div
                    key={poll._id}
                    className="Poll-item"
                    style={{ position: 'relative', opacity: poll.approved ? 1 : 1 }}
                >
                    {!poll.approved && (
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgb(15 15 15 / 55%)',
                                zIndex: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontSize: '24px',
                                borderRadius: '10px',
                            }}
                        >
                            Paused
                        </div>
                    )}

                    <Poll
                        pollId={poll._id}
                        question={poll.question}
                        labels={poll.labels}
                        logo={poll.logo || "/logo-poll.png"}
                        logoFill={poll.logoFill}
                        votes={poll.votes}
                        hash={poll.hash}
                        creatorUsername={poll.creatorUsername}
                        creatorId={poll.creator}
                        isLoggedIn={isLoggedIn}
                        userId={userId}
                        username={username}
                        onDelete={() => handleDeletePoll(poll._id)}
                        isProfileView={true}
                        voteAuthenticationMethod={poll.voteAuthenticationMethod}
                        confidenceLabels={poll.confidenceLabels}
                        imageAction={poll.imageAction}
                        roundedCorners={poll.roundedCorners}
                        startDate={poll.startDate}
                        endDate={poll.endDate}
                        scheduledPoll={poll.scheduledPoll}
                        userEmail={userEmail}
                        totalVotes={poll.totalVotes}
                        isTemporary={poll.isTemporary}
                        isLegacyPoll={poll.isLegacyPoll}
                        approved={poll.approved}
                        backgroundColor={poll.backgroundColor}
                    />
                </div>
            ))}

            
        </div>
        </div>
    );
};

export default CampaignPolls;
