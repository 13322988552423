import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileSettings.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import imageCompression from 'browser-image-compression';

const ProfileSettings = ({ userId }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [bio, setBio] = useState('');
  const [newBio, setNewBio] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await fetch(`/auth/user/${userId}`);
        const data = await response.json();
        
        if (data.email) setEmail(data.email);
        if (data.bio) {
          setBio(data.bio);
          setNewBio(data.bio);
        }
        if (data.profileImageUrl) setProfileImageUrl(data.profileImageUrl);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    if (userId) fetchUser();
  }, [userId]);

  const handleSaveEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/auth/update/email', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, email })
      });

      const data = await response.json();
      if (data.success) alert('Email updated successfully');
    } catch (error) {
      console.error('Error updating email:', error);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);

    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 350,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const formData = new FormData();
      formData.append('image', compressedFile);
      formData.append('userId', userId);

      const response = await fetch('/upload-profile-image', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) throw new Error('Failed to upload image');

      const data = await response.json();
      setProfileImageUrl(`${data.profileImageUrl}?t=${new Date().getTime()}`);
      setIsUploading(false);
    } catch (error) {
      console.error('Error uploading profile image:', error);
      setIsUploading(false);
    }
  };

  const handleSaveBio = () => {
    fetch('/auth/update', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId, bio: newBio })
    })
      .then(response => response.json())
      .then(() => setBio(newBio))
      .catch(error => console.error('Error updating bio:', error));
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action is irreversible.')) {
      try {
        const response = await fetch(`/auth/delete-profile/${userId}`, {
          method: 'DELETE'
        });

        const data = await response.json();
        if (data.success) navigate('/');
      } catch (error) {
        console.error('Error deleting account:', error);
      }
    }
  };

  return (
    <div className="profile-settings dark-theme">

      {/* Profile Image Upload */}
      <div className="profile-settings__section">
        <div className="profile-settings_heading">Profile Image</div>
        <label htmlFor="profile-image-upload" style={{ cursor: 'pointer' }}>
          {profileImageUrl ? (
            <img
              src={profileImageUrl}
              alt="Profile"
              style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '0.5rem' }}
            />
          ) : (
            <div style={{
              width: '100px', height: '100px', borderRadius: '50%', backgroundColor: '#0066ff',
              display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white',
              fontSize: '36px', fontWeight: 'bold'
            }}>P</div>
          )}
        </label>
        <input
          id="profile-image-upload"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
        {isUploading && <div>Uploading...</div>}
      </div>

       {/* Edit Bio Section */}
       <div className="profile-settings__section">
        <div className="profile-settings_heading">Edit Bio</div>
        <input
          type="text"
          value={newBio}
          onChange={(e) => setNewBio(e.target.value)}
          className="profile-settings__bio-input"
        />
        <button onClick={handleSaveBio} className="profile-settings__save-button">Save Bio</button>
      </div>

      {/* Change Email Section */}
      <form onSubmit={handleSaveEmail} className="profile-settings__form">
        <div className="profile-settings__section">
          <div className="profile-settings_heading">Change Email</div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="profile-settings__save-button">Save Email</button>
      </form>

     

      {/* Delete Account Section */}
      <div className="profile-settings__section">
        <div className="profile-settings_heading">Delete Account</div>
        <button className="profile-settings__delete-account" onClick={handleDeleteAccount}>
          Delete Account
        </button>
      </div>
    </div>
  );
};

export default ProfileSettings;
