// Easy-to-tweak variables for brightness adjustment
const BRIGHTEN_PERCENTAGE = 10; // Default percentage to brighten light colors
const DARKEN_PERCENTAGE = -10; // Default percentage to darken dark colors

// Convert hex to HSL
export function hexToHsl(hex) {
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h, s, l;
  l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h * 360, s * 100, l * 100];
}

// Convert HSL to hex
export function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // Convert to hex and pad
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

// Adjust brightness with easy-to-tweak variables
export function adjustBrightness(hex, percentage = null) {
  const [h, s, l] = hexToHsl(hex);

  // Use the provided percentage or default to the global variables
  const adjustment = percentage !== null ? percentage : l < 50 ? BRIGHTEN_PERCENTAGE : DARKEN_PERCENTAGE;

  const newLightness = Math.max(0, Math.min(100, l + adjustment)); // Clamp between 0 and 100
  return hslToHex(h, s, newLightness);
}
