import React from 'react';
import './Privacy.css';
import DesktopBG from '../assets/desktop-bg-login.jpg';
import MobileBG from '../assets/mobile-bg-login.jpg';

const Privacy = () => {
    const isDesktop = window.innerWidth >= 768;

    const backgroundStyle = {
        backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
    };

    return (
        <div className="PrivacyPage-container">
            <h1 className="PrivacyPage-heading">Privacy Policy</h1>
            
            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">OPINARI Technologies Inc.</h2>
                <p className="PrivacyPage-paragraph">
                    Last updated: February 2025
                </p>
                <p className="PrivacyPage-paragraph">
                    OPINARI Technologies Inc. ("we", "our", "us") values your privacy. This Privacy Policy ("Policy") describes how we collect, use, and protect your personal information when you visit our website <a href="https://www.opinari.io" target="_blank">https://www.opinari.io</a> ("Website").
                </p>
                <p className="PrivacyPage-paragraph">
                    By accessing or using our Website, you agree to the collection and use of information in accordance with this Policy. If you do not agree with this Policy, please do not use our Website.
                </p>
            </section>

            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">1. Information We Collect</h2>
                <p className="PrivacyPage-paragraph">
                    We collect various types of information to provide and improve our Website for you:
                </p>
                <p className="PrivacyPage-paragraph">
                    <strong>Personal Information:</strong> We may collect personal data such as your name, email address, phone number, and billing information when you subscribe to our services, contact us, or make a purchase.
                </p>
                <p className="PrivacyPage-paragraph">
                    <strong>Non-Personal Information:</strong> We collect non-personal information automatically when you visit our Website, such as your IP address, browser type, device information, and browsing activity.
                </p>
                <p className="PrivacyPage-paragraph">
                    <strong>Cookies and Tracking Technologies:</strong> Our Website uses cookies and similar technologies to enhance user experience. Cookies help us track your preferences, remember your login details, and improve our content. You can control cookie settings in your browser, but please note that disabling cookies may affect your experience on our Website.
                </p>
            </section>

            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">2. How We Use Your Information</h2>
                <p className="PrivacyPage-paragraph">
                    We use the information we collect for various purposes, including:
                </p>
                <ul className="PrivacyPage-list">
                    <li>To provide and maintain our Website</li>
                    <li>To personalize your experience and improve our services</li>
                    <li>To process transactions and fulfill your orders</li>
                    <li>To send you promotional emails or newsletters (if you have opted in)</li>
                    <li>To respond to inquiries, comments, or customer service requests</li>
                    <li>To analyze Website usage and trends</li>
                </ul>
            </section>

            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">3. Sharing Your Information</h2>
                <p className="PrivacyPage-paragraph">
                    We will not share or sell your personal information to third parties without your consent, except in the following circumstances:
                </p>
                <p className="PrivacyPage-paragraph">
                    <strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our Website, conducting business, or providing services to you, such as payment processors or email marketing platforms. These providers are obligated to protect your information and use it solely for the services they provide.
                </p>
                <p className="PrivacyPage-paragraph">
                    <strong>Legal Requirements:</strong> We may disclose your personal information if required by law, such as to comply with a subpoena, court order, or other legal process.
                </p>
            </section>

            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">4. Data Security</h2>
                <p className="PrivacyPage-paragraph">
                    We use reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
                </p>
            </section>

            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">5. Your Rights and Choices</h2>
                <p className="PrivacyPage-paragraph">
                    You have the right to:
                </p>
                <ul className="PrivacyPage-list">
                    <li>Access and update your personal information</li>
                    <li>Opt-out of receiving marketing emails or newsletters</li>
                    <li>Request the deletion of your personal information (subject to legal or contractual obligations)</li>
                </ul>
                <p className="PrivacyPage-paragraph">
                    To exercise these rights, please contact us at <a href="mailto:admin@opinari.io">admin@opinari.io</a>.
                </p>
            </section>

            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">6. Links to Other Websites</h2>
                <p className="PrivacyPage-paragraph">
                    Our Website may contain links to third-party websites that are not operated by us. We are not responsible for the privacy practices of these websites. We encourage you to review their privacy policies before providing any personal information.
                </p>
            </section>

            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">7. Children's Privacy</h2>
                <p className="PrivacyPage-paragraph">
                    Our Website is not intended for individuals under the age of 13, and we do not knowingly collect personal information from children. If you believe we have collected information from a child, please contact us, and we will take steps to remove the information.
                </p>
            </section>

            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">8. Changes to This Privacy Policy</h2>
                <p className="PrivacyPage-paragraph">
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this Policy periodically to stay informed about how we protect your information.
                </p>
            </section>

            <section className="PrivacyPage-section">
                <h2 className="PrivacyPage-subheading">9. Contact Us</h2>
                <p className="PrivacyPage-paragraph">
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                </p>
                <p className="PrivacyPage-paragraph">
                    <strong>OPINARI Technologies Inc.</strong><br />
                    Email: <a href="mailto:contact@opinari.io">contact@opinari.io</a>
                </p>
            </section>
        </div>
    );
};

export default Privacy;
