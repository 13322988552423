// CreatePoll.jsx
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import "./CreatePoll.css";
import UpgradeModal from "./UpgradeModal";
import BrandPreviewPoll from "./BrandPreviewPoll";
import "./datepicker-dark-theme.css"; // Import your custom dark theme CSS
import captchaImage from "../assets/captcha.png";
import Info from "./Info";
import logger from "./logger";
import AiIcon from "../assets/ai.png";
import LoadingIcon from "../assets/loading-small.png";
import ImageCropperModal from "./ImageCropperModal";
import ColorWheelBuilder from "./ColorWheelBuilder";
import AdEditor from "./AdEditor";
import AdEditorSingle from "./AdEditorSingle";

import { SketchPicker } from 'react-color'; // Optional: Use react-color for advanced color picker



// Removed unused imports for BrandAdMockup and AdType images

const BrandCreatePoll = ({
  isLoggedIn,
  userId,
  username,
  onCreate,
  subscriptionStatus,
  initialSelectedCampaign, // Renamed prop
}) => {
  const [newPollQuestion, setNewPollQuestion] = useState("");
  const [newPollLabels, setNewPollLabels] = useState([
    "Agree",
    "Neutral",
    "Disagree",
  ]);
  const [newPollCategory, setNewPollCategory] = useState("Technology");
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [redirectLink, setRedirectLink] = useState("");
  const [redirectMessage, setRedirectMessage] = useState("");
  const [redirectMessageError, setRedirectMessageError] = useState("");
  const [voteAuthenticationMethod, setVoteAuthenticationMethod] = useState(0);
  const [pollQuestionError, setPollQuestionError] = useState("");
  const [useCustomLabels, setUseCustomLabels] = useState(true); // Default to using custom answers
const [customLabels, setCustomLabels] = useState([
  "Answer 1",
  "Answer 2",
  "Answer 3",
]); 
  const [customConfidenceLabels, setCustomConfidenceLabels] = useState([
    ["Sub Answer 1", "Sub Answer 2", "Sub Answer 3"],
    ["Sub Answer 1", "Sub Answer 2", "Sub Answer 3"],
    ["Sub Answer 1", "Sub Answer 2", "Sub Answer 3"],
  ]); 

  const [visibility, setVisibility] = useState("Private"); // Default to Private
  const [logo, setLogo] = useState(null);
  const [tempLogoUrl, setTempLogoUrl] = useState("/logo-poll.png");
  const textareaRef = useRef(null);
  const [fillBackground, setFillBackground] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [imageAction, setImageAction] = useState(0);
  const [roundCorners, setRoundCorners] = useState(false);
  const [useSchedule, setUseSchedule] = useState(false);
  const [startDate, setStartDate] = useState(new Date()); // Default to "Now"
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
  ); // Default to 7 days from now
  const [useCaptcha, setUseCaptcha] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const linkConfidenceLevelsRef = useRef(false);
  const [mainOptionAnimations, setMainOptionAnimations] = useState([
    false,
    false,
    false,
  ]); // For three main options
  const [confidenceLevelAnimations, setConfidenceLevelAnimations] =
    useState(
      customConfidenceLabels.map(() => [false, false, false, false, false])
    ); // Initialize animation states for confidence levels
  const [forceUpdate, setForceUpdate] = useState(false); // A state to force re-render
  const [isCropping, setIsCropping] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const labelRefs = useRef([]); // Create refs for each label input
  const confidenceRefs = useRef([]); // Refs for confidence level inputs
  const [isAdvertising, setIsAdvertising] = useState(false);

  const [keywords, setKeywords] = useState([]);
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [budget, setBudget] = useState("");


  const [backgroundColor, setBackgroundColor] = useState('#191919'); // Default color

  // New ads state – each ad will have an image, productName, price, and link.
  const [ads, setAds] = useState([]);

  // Add this state definition near the top of your component, alongside other useState hooks
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [campaigns, setCampaigns] = useState([]);

  const [promotionMode, setPromotionMode] = useState("Dynamic"); // Default to Dynamic

  useEffect(() => {
    if (initialSelectedCampaign) {
      setSelectedCampaign(initialSelectedCampaign); // Initialize state from prop
    } else if (campaigns.length > 0) {
      setSelectedCampaign(campaigns[0]._id); // Default to the first campaign
    }
  }, [initialSelectedCampaign, campaigns]);
  

  const pollOptions = [
    ["Yes", "Maybe", "No"],
    ["Agree", "Neutral", "Disagree"],
    ["Positive", "Neutral", "Negative"],
    ["Approve", "Indifferent", "Disapprove"],
    ["Good", "Average", "Bad"],
    ["Support", "Neutral", "Oppose"],
    ["True", "Unsure", "False"],
    ["High", "Medium", "Low"],
    ["Like", "Neutral", "Dislike"],
    ["Happy", "Neutral", "Unhappy"],
    ["Satisfied", "Neutral", "Unsatisfied"],
  ];

  const confidenceMapping = {
    "Yes, Maybe, No": [
      ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
      ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
      ["Weak", "Unsure", "Moderate", "Strong", "Confident"],
    ],
    "Agree, Neutral, Disagree": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "Positive, Neutral, Negative": [
      ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
      ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
      ["Marginally", "Slightly", "Moderately", "Strongly", "Extremely"],
    ],
    "Approve, Indifferent, Disapprove": [
      ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Minimally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "Good, Average, Bad": [
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    ],
    "Support, Neutral, Oppose": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "High, Medium, Low": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
    ],
    "Like, Neutral, Dislike": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Strongly"],
    ],
    "Happy, Neutral, Unhappy": [
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
      ["Marginally", "Slightly", "Somewhat", "Moderately", "Extremely"],
    ],
    "Satisfied, Neutral, Unsatisfied": [
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    ],
    "True, Unsure, False": [
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
      ["Marginally", "Slightly", "Somewhat", "Kinda", "Very"],
    ],
  };

  const categories = [
    "Automotive",
    "Business",
    "Education",
    "Entertainment",
    "Environment",
    "Fashion",
    "Food",
    "Games",
    "Health",
    "Health & Beauty",
    "Lifestyle",
    "Pets",
    "Politics",
    "Products",
    "Services",
    "Sports",
    "Technology",
    "Travel",
  ];

  // Function to ensure refs are properly initialized
  const ensureConfidenceRefsInitialized = (optionIndex) => {
    if (!confidenceRefs.current[optionIndex]) {
      confidenceRefs.current[optionIndex] = [];
    }
  };

  // Function to convert HSL to RGB
  function hslToRgb(h, s, l) {
    let a = s * Math.min(l, 1 - l);
    const f = (n) => {
      const k = (n + h / 30) % 12;
      return l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    };
    return [
      Math.round(f(0) * 255),
      Math.round(f(8) * 255),
      Math.round(f(4) * 255),
    ];
  }

  // Color wheel options for the preview
  const colorWheelOptions = customLabels.map((label, optionIndex) => {
    const finalLabel =
      label.trim() !== "" ? label.trim() : `Option ${optionIndex + 1}`;

    // Ensure customConfidenceLabels[optionIndex] exists before mapping over it
    const finalConfidenceLevels = (customConfidenceLabels[optionIndex] || []).map(
      (confLabel, labelIndex) => {
        const trimmedConfLabel =
          typeof confLabel === "string" ? confLabel.trim() : "";
        return trimmedConfLabel !== ""
          ? trimmedConfLabel
          : `Confidence ${labelIndex + 1}`;
      }
    );

    return {
      label: finalLabel,
      confidenceLevels: finalConfidenceLevels,
    };
  });

  // Handler for node click in the color wheel
  const handleNodeClick = (optionIndex, levelIndex = null) => {
    if (levelIndex === null) {
      const targetField = labelRefs.current[optionIndex];
      if (targetField) {
        targetField.scrollIntoView({ behavior: "smooth", block: "center" });
        targetField.focus();
      }
    } else {
      const targetField = confidenceRefs.current[optionIndex]?.[levelIndex];
      if (targetField) {
        targetField.scrollIntoView({ behavior: "smooth", block: "center" });
        targetField.focus();
      }
    }
  };

  // Updated function to handle API response and update state
  const handleGeneratePollOptions = async () => {
    if (!newPollQuestion.trim()) {
      alert("Poll question cannot be empty.");
      return;
    }
  
    setIsGenerating(true); // Show spinner and hide button/checkbox
    try {
      const response = await fetch("/api/generate-poll-options", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question: newPollQuestion }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to fetch poll options.");
      }
  
      const data = await response.json();
  
      // **Update the question if AI modified it**
      if (data.validatedQuestion && data.validatedQuestion !== newPollQuestion) {
        setNewPollQuestion(data.validatedQuestion); // Update question field
      }
  
      // **Update the category if AI suggested one**
      if (data.category) {
        setNewPollCategory(data.category);
      }
  
      if (
        data.answers &&
        data.answers.customLabels &&
        data.answers.customConfidenceLabels
      ) {
        setNewPollLabels(data.answers.customLabels);
        setCustomLabels(data.answers.customLabels);
        setUseCustomLabels(true); // Enable custom labels automatically
        setCustomConfidenceLabels(data.answers.customConfidenceLabels || []);
      } else if (data.answers?.explanation && data.answers?.recommendations) {
        // Handle explanation and recommendations
        alert(
          `Explanation: ${data.answers.explanation}\n\nRecommendations:\n- ${data.answers.recommendations.join(
            "\n- "
          )}`
        );
      } else {
        console.error("Unexpected response structure:", data);
        alert("An unexpected error occurred. Please try again later.");
      }
    } catch (error) {
      console.error("Error generating poll options:", error);
      alert(error.message);
    } finally {
      setIsGenerating(false); // Hide spinner and show button/checkbox
    }
  };
  

  // CustomInput component for date pickers
  const CustomInput = React.forwardRef(({ value, onClick, label }, ref) => (
    <div className="custom-date-input" onClick={onClick} ref={ref}>
      {label}: {value || `Select ${label.toLowerCase()}`}
    </div>
  ));

  // Calculate dynamic colors function
  const calculateDynamicColors = (confidenceLevels, baseHue) => {
    const minSaturation = 50; // Adjust to your preference
    const maxSaturation = 100;
    const step =
      confidenceLevels.length > 1
        ? (maxSaturation - minSaturation) / (confidenceLevels.length - 1)
        : 0;

    return confidenceLevels.map((_, index) => {
      const saturation = minSaturation + step * index;
      return `hsl(${baseHue}, ${saturation}%, 50%)`;
    });
  };

  // Handle image upload for the poll logo
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result); // Show image in the cropping modal
      setIsCropping(true); // Show cropping modal
      setRoundCorners(true); // Turn on rounded corners by default after image upload
    };
    reader.readAsDataURL(file);
  };

  // Handle crop complete
  const handleCropComplete = (croppedImage) => {
    setLogo(croppedImage); // Use the cropped image
    setTempLogoUrl(URL.createObjectURL(croppedImage)); // Preview the cropped image
    setIsCropping(false); // Hide cropping modal
  };

  // Handle form submission to create poll
  // Handle form submission to create poll
   // Handle form submission to create poll
   const handleConfirmCreatePoll = async () => {
    const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
    logger.log("Starting poll creation process");

    // Check if the poll question is provided
    if (!newPollQuestion.trim()) {
      setPollQuestionError("Poll question is required.");
      setIsLoading(false); // Stop loading if validation fails early
      return;
    } else {
      setPollQuestionError("");
    }

    

    // Validate ads if isAdvertising is true
    // Validate ads if isAdvertising is true
// Validate ads if isAdvertising is true
if (isAdvertising) {
  if (promotionMode === "Dynamic") {
    // Dynamic Mode: Validate all ads
    const incompleteAds = ads.some(
      (ad) =>
        (!ad.image) || // Image is always required
        (!ad.link?.trim()) // Link is always required
    );

    if (incompleteAds) {
      alert(
        "Please fill out all fields (including image, name, price, and link) for all ads before creating the poll."
      );
      return; // Stop submission
    }
  } else if (promotionMode === "Static") {
    // Static Mode: Validate only the first ad
    const staticAd = ads[0]; // Only the first ad is relevant in Static mode
    if (
      !staticAd?.image || // Image is required
      !staticAd?.link?.trim() // Link is required
    ) {
      alert(
        "Please fill out all fields (including image, name, price, and link) for the static promotion before creating the poll."
      );
      return; // Stop submission
    }
  }
}





    // Set default creator and creatorUsername based on the login status
    let finalCreator = userId;
    let finalCreatorUsername = username;
    let isTemporary = false; // Track if the poll is temporary

    // If the user is not logged in, assign anonymous values
    if (!isLoggedIn) {
      finalCreator = "66f2fc9406a25b4676dcc980"; // Anonymous creator ID
      finalCreatorUsername = "Anonymous";
      isTemporary = true; // Set temporary poll to true
    }

    // Validate redirect message length
    if (redirectMessage.length > 70) {
      setRedirectMessageError("Redirect message cannot exceed 70 characters.");
      setIsLoading(false);
      return;
    } else {
        setRedirectMessageError("");
    }

    // Format redirect link
    let formattedRedirectLink = redirectLink;
    if (
      redirectLink &&
      !redirectLink.startsWith("http://") &&
      !redirectLink.startsWith("https://")
    ) {
      formattedRedirectLink = `https://${redirectLink}`;
    }

    // Prepare final poll labels and confidence levels
    let finalLabels = useCustomLabels
      ? customLabels.map((label, i) => {
          const trimmedLabel = label.trim();
          return trimmedLabel !== "" ? trimmedLabel : `Option ${i + 1}`;
        })
      : newPollLabels; // Note: newPollLabels might be empty if presets were removed

    let finalConfidenceLabels = customConfidenceLabels.map(
      (confidenceArray, optionIndex) =>
        (confidenceArray || []).map((confLabel, confIndex) => { // Add default empty array
          const trimmedConfLabel =
            typeof confLabel === "string" ? confLabel.trim() : "";
          return trimmedConfLabel !== ""
            ? trimmedConfLabel
            : `Sub Option ${confIndex + 1}`;
        })
    );

    // *** Construct confidenceLinks in the correct nested structure ***
    const finalConfidenceLinks =
    promotionMode === "Dynamic"
      ? finalLabels.map((mainLabel, optionIndex) => {
          const confLevels = finalConfidenceLabels[optionIndex] || [];
          if (confLevels.length > 0) {
            return confLevels.map((confLabel, levelIndex) => {
              const matchingAd = ads.find(
                (ad) =>
                  ad.optionIndex === optionIndex &&
                  ad.levelIndex === levelIndex
              );
              return {
                level: confLabel,
                url: matchingAd?.link?.trim() || "#",
                name: matchingAd?.productName?.trim() || confLabel,
                price: matchingAd?.price?.trim() || "N/A",
                image:
                  matchingAd?.image ||
                  "https://opinari-main.nyc3.cdn.digitaloceanspaces.com/default-featured-image.png.jpg",
                voteCount: 0,
              };
            });
          } else {
            const matchingAd = ads.find(
              (ad) =>
                ad.optionIndex === optionIndex &&
                ad.levelIndex === null
            );
            return [
              {
                level: mainLabel,
                url: matchingAd?.link?.trim() || "#",
                name: matchingAd?.productName?.trim() || mainLabel,
                price: matchingAd?.price?.trim() || "N/A",
                image:
                  matchingAd?.image ||
                  "https://opinari-main.nyc3.cdn.digitaloceanspaces.com/default-featured-image.png.jpg",
                voteCount: 0,
              },
            ];
          }
        })
      : [
          // Static Mode: Create a single confidence link
          {
            level: finalLabels[0], // Attach to the first option's first confidence level
            url: ads[0]?.link?.trim() || "#",
            name: ads[0]?.productName?.trim() || finalLabels[0],
            price: ads[0]?.price?.trim() || "N/A",
            image:
              ads[0]?.image ||
              "https://opinari-main.nyc3.cdn.digitaloceanspaces.com/default-featured-image.png.jpg",
            voteCount: 0,
          },
        ];


    // Prepare form data for poll creation
    const formData = new FormData();
    formData.append("question", newPollQuestion);
    formData.append("labels", JSON.stringify(finalLabels));
    formData.append("confidenceLabels", JSON.stringify(finalConfidenceLabels));
    formData.append("confidenceLinks", JSON.stringify(finalConfidenceLinks)); // *** Send the constructed array ***
    formData.append("category", newPollCategory);
    formData.append("creator", finalCreator);
    formData.append("creatorUsername", finalCreatorUsername);
    formData.append("redirectLink", formattedRedirectLink);
    formData.append("redirectMessage", redirectMessage);
    formData.append("voteAuthenticationMethod", voteAuthenticationMethod);
    formData.append("visibility", visibility);
    formData.append("logoFill", fillBackground);
    formData.append("imageAction", imageAction);
    formData.append("roundedCorners", roundCorners);
    formData.append("useCaptcha", useCaptcha);
    formData.append("isTemporary", isTemporary);
    formData.append("isAdvertising", isAdvertising); // Send the flag
    formData.append("pollType", "Brand");
    formData.append("budget", budget || 0);
    formData.append('backgroundColor', backgroundColor); // Add background color
    formData.append("promotionMode", promotionMode); // Add promotionMode to the request


    // formData.append("ads", JSON.stringify(ads)); // Only if the separate 'ads' field in schema is still needed

    // Handle logo upload - Check if logo is a File or Blob
    if (logo instanceof File || logo instanceof Blob) {
        formData.append("logo", logo, `logo_${Date.now()}.png`); // Provide a filename
    } else if (typeof logo === 'string' && logo.startsWith('blob:')) {
        // If logo is a blob URL from cropper, fetch the blob and append
        try {
           const response = await fetch(logo);
           const blob = await response.blob();
           formData.append("logo", blob, `logo_${Date.now()}.png`); // Provide a filename
        } catch (blobError) {
           console.error("Error fetching blob from logo URL:", blobError);
           // Handle error - maybe don't send logo or show message
        }
     }


    // Handle poll scheduling data
    formData.append("scheduledPoll", useSchedule);
    if (useSchedule) {
      formData.append("startDate", startDate.toISOString());
      // Handle potential 'Infinite' end date if applicable, otherwise send ISO string or null
      formData.append("endDate", endDate ? endDate.toISOString() : null);

      const now = new Date();
      const pollActive = startDate <= now && (!endDate || endDate >= now);
      formData.append("pollActive", pollActive);
    } else {
      formData.append("pollActive", true); // Default to active if not scheduled
    }

    // Ensure start and end date are valid if scheduling
    if (useSchedule && startDate && endDate && startDate > endDate) {
      alert("End date cannot be before the start date.");
      setIsLoading(false);
      return;
    }

    // Make the API call to create the poll
    fetch("/brand/polls", {
      method: "POST",
      headers: {
        Authorization: isLoggedIn ? `Bearer ${localStorage.getItem("token")}` : "",
        // No Content-Type for FormData
      },
      body: formData,
    })
      .then(async (response) => { // Make async to parse potential error JSON
        if (!response.ok) {
          const errorData = await response.text(); // Get error text
          console.error("Server response error:", errorData);
          throw new Error(`Network response was not ok: ${response.statusText} - ${errorData}`);
        }
        return response.json();
      })
      .then((data) => {
        const fullPollLink = `${baseUrl}/poll/${data.hash}`;

        onCreate({
          pollLink: fullPollLink,
          isTemporary: isTemporary,
          selectedCampaign,
        });

        // Reset form fields
        setNewPollQuestion("");
        // Reset labels/confidence to initial defaults
        setCustomLabels(["Answer 1", "Answer 2", "Answer 3"]);
        setCustomConfidenceLabels([
            ["Sub Answer 1", "Sub Answer 2", "Sub Answer 3"],
            ["Sub Answer 1", "Sub Answer 2", "Sub Answer 3"],
            ["Sub Answer 1", "Sub Answer 2", "Sub Answer 3"],
        ]);
        setAds([]); // Clear ads state
        setNewPollCategory("Technology");
        setRedirectLink("");
        setRedirectMessage("");
        setTempLogoUrl("/logo-poll.png");
        setLogo(null);
        // Reset other relevant states like budget, schedule etc.
        setBudget("");
        setUseSchedule(false);
        setStartDate(new Date());
        setEndDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000));


        // Add poll to campaign only if a campaign is selected
        if (selectedCampaign) {
            return fetch(`/campaigns/${selectedCampaign}/addPoll`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ pollId: data._id }),
            });
        }
      })
      .then(campaignResponse => {
          if (campaignResponse && !campaignResponse.ok) {
              console.error("Failed to add poll to campaign");
              // Maybe show a non-blocking warning to the user
          }
      })
      .catch((error) => {
        logger.error("Error adding poll or adding to campaign:", error);
        alert(`An error occurred: ${error.message}. Please try again.`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  

  

  // Handle custom label changes
  const handleCustomLabelChange = (index, value) => {
    if (value.length <= 50) {
      setCustomLabels((prevLabels) => {
        const updatedLabels = [...prevLabels];
        updatedLabels[index] = value;
        return updatedLabels;
      });
    }
  };

  const handleBlur = (index) => {
    setCustomLabels((prevLabels) => {
      const updatedLabels = [...prevLabels];
      if (updatedLabels[index].trim() === "") {
        updatedLabels[index] = `Option ${index + 1}`;
      }
      return updatedLabels;
    });
  };

  // Handle adding main opinions
  const handleAddMainOpinion = () => {
    if (customLabels.length >= 12) return;
  
    setCustomLabels((prevLabels) => [
      ...prevLabels,
      `Answer ${prevLabels.length + 1}`,
    ]);
    setCustomConfidenceLabels((prevConfidenceLabels) => [
      ...prevConfidenceLabels,
      ["Sub Answer 1", "Sub Answer 2", "Sub Answer 3"],
    ]);
  
    setAds((prevAds) => [
      ...prevAds,
      {
        optionIndex: customLabels.length, // New option index
        levelIndex: null,
        image: "",
        productName: `Option ${customLabels.length + 1}`,
        price: "",
        link: "",
      },
    ]);
  };
  

  const handleDeleteMainOpinion = (index) => {
    if (customLabels.length <= 3) return;
  
    setCustomLabels((prevLabels) => prevLabels.filter((_, i) => i !== index));
    setCustomConfidenceLabels((prevConfidenceLabels) =>
      prevConfidenceLabels.filter((_, i) => i !== index)
    );
  
    setAds((prevAds) =>
      prevAds.filter((ad) => ad.optionIndex !== index)
    );
  };
  

  // Handle custom confidence label changes
  const handleCustomConfidenceLabelChange = (optionIndex, labelIndex, value) => {
    if (value.length <= 40) {
      const updatedLabels = [...customConfidenceLabels];

      if (linkConfidenceLevelsRef.current) {
        updatedLabels.forEach((_, i) => {
          updatedLabels[i][labelIndex] = value;
        });
      } else {
        updatedLabels[optionIndex][labelIndex] = value;
      }
      setCustomConfidenceLabels(updatedLabels);
    }
  };

  // Handle adding confidence levels
  const handleAddConfidenceLevel = (optionIndex) => {
    linkConfidenceLevelsRef.current = false;

    setCustomConfidenceLabels((prevConfidenceLabels) => {
      const updatedConfidenceLabels = [...prevConfidenceLabels];
      if (updatedConfidenceLabels[optionIndex].length < 5) {
        updatedConfidenceLabels[optionIndex] = [
          ...updatedConfidenceLabels[optionIndex],
          "",
        ];
      }
      return updatedConfidenceLabels;
    });
  };

  const handleDeleteConfidenceLevel = (optionIndex, labelIndex) => {
    linkConfidenceLevelsRef.current = false;
  
    setCustomConfidenceLabels((prevConfidenceLabels) => {
      const updatedConfidenceLabels = [...prevConfidenceLabels];
      if (updatedConfidenceLabels[optionIndex]) {
        updatedConfidenceLabels[optionIndex].splice(labelIndex, 1);
      }
      return updatedConfidenceLabels;
    });
  
    setAds((prevAds) =>
      prevAds.filter(
        (ad) =>
          !(ad.optionIndex === optionIndex && ad.levelIndex === labelIndex)
      )
    );
  };
  

  // Update confidence labels when useCustomLabels changes or number of customLabels changes
  // Update confidence labels when the number of customLabels changes
useEffect(() => {
  setCustomConfidenceLabels((prevConfidenceLabels) => {
    const prevLength = prevConfidenceLabels.length;
    const newLength = customLabels.length;

    if (prevLength !== newLength) {
      return customLabels.map(() => ["Sub Answer 1", "Sub Answer 2", "Sub Answer 3"]);
    } else {
      return prevConfidenceLabels;
    }
  });
}, [customLabels.length]);

  

  // Update confidence labels when customLabels change
  useEffect(() => {
    if (useCustomLabels) {
      setCustomConfidenceLabels((prevConfidenceLabels) => {
        const updatedConfidenceLabels = [...prevConfidenceLabels];
        const currentLength = updatedConfidenceLabels.length;
        const desiredLength = customLabels.length;

        if (currentLength < desiredLength) {
          for (let i = currentLength; i < desiredLength; i++) {
            updatedConfidenceLabels.push(["Marginally", "Somewhat", "Strongly"]);
          }
        } else if (currentLength > desiredLength) {
          updatedConfidenceLabels.splice(desiredLength);
        }
        return updatedConfidenceLabels;
      });
    }
  }, [customLabels, useCustomLabels]);

  // Handle text area auto-resize
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newPollQuestion]);

  // Set default value of isAdvertising based on subscription status
  useEffect(() => {
    if (subscriptionStatus === "Enterprise") {
      setIsAdvertising(true); // Enable by default for Enterprise
    } else {
      setIsAdvertising(false); // Disable for non-Enterprise users
    }
  }, [subscriptionStatus]);

  useEffect(() => {
    setVisibility("Private"); // Always keep visibility as Private
  }, []);

  

  // Prepare finalLabels and finalConfidenceLabels for the preview
  let finalLabels = useCustomLabels
    ? customLabels.map((label, i) => {
        const trimmedLabel = label.trim();
        return trimmedLabel !== "" ? trimmedLabel : `Option ${i + 1}`;
      })
    : newPollLabels;

  let finalConfidenceLabels = customConfidenceLabels.map(
    (confidenceArray, optionIndex) =>
      confidenceArray.map((confLabel, confIndex) => {
        const trimmedConfLabel =
          typeof confLabel === "string" ? confLabel.trim() : "";
        return trimmedConfLabel !== ""
          ? trimmedConfLabel
          : `Sub Option ${confIndex + 1}`;
      })
  );

  // Prepare pollConfig for the preview
  const pollConfig = {
    question:
      newPollQuestion.trim() !== ""
        ? newPollQuestion
        : "Enter poll question",
    labels: finalLabels,
    confidenceLabels: finalConfidenceLabels,
    category: newPollCategory,
    creator: userId,
    creatorUsername: username,
    redirectLink,
    redirectMessage,
    voteAuthenticationMethod,
    visibility,
    logo: tempLogoUrl,
    logoFill: fillBackground,
    imageAction,
    roundedCorners: roundCorners,
  };

  // Handle ad image upload – reusing the image upload endpoint
   // Handle ad image upload – uploads immediately and stores URL
   const handleAdImageUpload = async (e, optionIndex, levelIndex) => {
    const file = e.target.files[0];
    if (!file) return;

    // Create FormData to send the file
    const formData = new FormData();
    formData.append("file", file); // 'file' is the key expected by /upload-ad-image

    // Optional: Add loading state for the specific ad being uploaded
    // e.g., setAds(prev => update specific ad with isLoading: true)

    try {
      const response = await fetch("/upload-ad-image", { // Your dedicated image upload endpoint
        method: "POST",
        // No 'Content-Type' header needed for FormData with files
        // Add Authorization if your /upload-ad-image endpoint requires it
        // headers: {
        //   Authorization: isLoggedIn ? `Bearer ${localStorage.getItem("token")}` : "",
        // },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error("Image upload failed:", errorData);
        throw new Error(`Failed to upload ad image: ${response.statusText}`);
      }

      const { imageUrl } = await response.json(); // Get the final URL from the server
      console.log("Uploaded image URL:", imageUrl);

      // Update the 'ads' state with the final URL
      setAds((prevAds) => {
        // Create a new array to avoid direct state mutation
        const updatedAds = prevAds.map(ad => ({ ...ad }));
        const adIndex = updatedAds.findIndex(
          (item) => item.optionIndex === optionIndex && item.levelIndex === levelIndex
        );
        if (adIndex !== -1) {
          updatedAds[adIndex].image = imageUrl; // Store the final URL
          // updatedAds[adIndex].isLoading = false; // Optional: remove loading state
        } else {
            console.warn(`Could not find ad to update image for optionIndex: ${optionIndex}, levelIndex: ${levelIndex}`);
        }
        return updatedAds;
      });
    } catch (error) {
      console.error("Error uploading ad image:", error);
      alert(`Error uploading image: ${error.message}`);
      // Optional: Update ad state to remove loading indicator on error
      // setAds(prev => update specific ad with isLoading: false, error: true)
    }
  };

  
  

  // New function: handle ad input changes for product name, price, and link
  const handleAdInputChange = (optionIndex, levelIndex, field, value) => {
    setAds((prevAds) => {
      const updatedAds = [...prevAds];
      const adToUpdate = updatedAds.find(
        (item) => item.optionIndex === optionIndex && item.levelIndex === levelIndex
      );
      if (adToUpdate) {
        adToUpdate[field] = value; // Update the correct field
      }
      return updatedAds;
    });
  };
  

  // New function: trigger the file input click for a given ad editor
  const handleAdUploadClick = (index) => {
    const input = document.getElementById(`ad-upload-${index}`);
    if (input) {
      input.click();
    }
  };

  // This useEffect builds the ads list based on the current poll options and
  // their confidence levels. For each poll option, if confidence levels exist,
  // one ad is created per confidence level; otherwise one ad is created for the
  // option.
  useEffect(() => {
    const effectiveLabels = useCustomLabels ? customLabels : newPollLabels;
    const effectiveConfidenceLevels = customConfidenceLabels;
  
    setAds((prevAds) => {
      const newAds = [];
  
      effectiveLabels.forEach((label, optionIndex) => {
        const confLevels = effectiveConfidenceLevels[optionIndex] || [];
        if (confLevels.length > 0) {
          confLevels.forEach((conf, levelIndex) => {
            // Check if the ad already exists
            const existingAd = prevAds.find(
              (ad) =>
                ad.optionIndex === optionIndex && ad.levelIndex === levelIndex
            );
  
            newAds.push(
              existingAd || {
                optionIndex,
                levelIndex,
                image: "",
                productName: "",
                price: "",
                link: "",
              }
            );
          });
        } else {
          // Handle case with no confidence levels
          const existingAd = prevAds.find(
            (ad) => ad.optionIndex === optionIndex && ad.levelIndex === null
          );
  
          newAds.push(
            existingAd || {
              optionIndex,
              levelIndex: null,
              image: "",
              productName: label, // Default name is the main option
              price: "",
              link: "",
            }
          );
        }
      });
  
      return newAds;
    });
  }, [useCustomLabels, newPollLabels, customLabels, customConfidenceLabels]);
  
  

  // Fetch campaigns for this user
  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await fetch(`/campaigns?creator=${userId}`);
        if (!response.ok) throw new Error("Failed to fetch campaigns");
        const data = await response.json();
        setCampaigns(data.campaigns);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };
    fetchCampaigns();
  }, [userId]);
  

  return (
    <div className="master">
      <div className="campaign-dropdown">
        <select
          id="campaign-select"
          value={selectedCampaign || ""}
          onChange={(e) => setSelectedCampaign(e.target.value)}
          className="campaign-select"
        >
          <option value="" disabled>
            Campaign: Select a Campaign
          </option>
          {campaigns.map((campaign) => (
            <option key={campaign._id} value={campaign._id}>
              Campaign: {campaign.campaignName}
            </option>
          ))}
        </select>
      </div>

      <div className="create-poll-page">
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-content">
              <div className="loading-text">Creating Poll</div>
              <div className="loading-spinner"></div>
            </div>
          </div>
        )}

        <div className="create-poll-and-preview">
          <div className="create-poll-content">
            <div className="custom-switch">
              <input
                type="checkbox"
                id="isAdvertising"
                checked={isAdvertising}
                onChange={(e) => setIsAdvertising(e.target.checked)}
                disabled={subscriptionStatus !== "Enterprise"}
              />
              <label htmlFor="isAdvertising" className="switch-label">
                <span className="switch-slider" />
              </label>
              <span className="switch-text">
                {isAdvertising
                  ? "Promotions Enabled"
                  : "Promotions Disabled"}
              </span>
            </div>

            {/* Image Upload Section */}
            <div className="image-upload-container">
              <div
                className={`logo-background ${
                  fillBackground ? "filled" : ""
                }`}
              >
                <img
                  src={logo ? tempLogoUrl : "/logo.png"}
                  alt="Poll Logo"
                  className="poll-logo"
                  onClick={() =>
                    document.getElementById("logo-upload").click()
                  }
                  style={{
                    borderRadius: roundCorners ? "10px" : "0px",
                  }}
                />
              </div>
              <button
                type="button"
                className="upload-logo-button"
                onClick={() =>
                  document.getElementById("logo-upload").click()
                }
              >
                Upload Image
              </button>
              <div className="image-options-small-text">
                Optimum Size - 16:9
              </div>
              <input
                type="file"
                accept="image/*"
                id="logo-upload"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />

              {isCropping && selectedImage && (
                <ImageCropperModal
                  imageSrc={selectedImage}
                  onCropComplete={handleCropComplete}
                  onCancel={() => setIsCropping(false)}
                />
              )}
            </div>

            {logo && (
              <div className="image-options">
                <h3 className="image-options-title">Image Options</h3>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="roundCorners"
                    checked={roundCorners}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setRoundCorners(true);
                        setFillBackground(false);
                      } else {
                        setRoundCorners(false);
                      }
                    }}
                  />
                  <label htmlFor="roundCorners">Round Corners</label>
                </div>

                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="fillBackground"
                    checked={fillBackground}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setRoundCorners(false);
                      }
                      setFillBackground(e.target.checked);
                    }}
                  />
                  <label htmlFor="fillBackground">
                    Fill Transparent Background
                  </label>
                </div>
                <h4 className="image-action-title">Image Action</h4>
                <div className="radio-group">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="imageAction"
                      value={0}
                      checked={imageAction === 0}
                      onChange={() => setImageAction(0)}
                    />
                    Link to Poll
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="imageAction"
                      value={1}
                      checked={imageAction === 1}
                      onChange={() => setImageAction(1)}
                    />
                    Expand Image
                  </label>
                </div>

                
              </div>
            )}


<div className="background-color-picker">
  <label
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      fontSize: '16px',
      color: '#fff', // Match the text color to the theme
      marginTop: '20px'
    }}
  >
    Brand Color
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '20px',
        borderRadius: '5px',
        border: `2px solid #9b9b9b`, // Use the selected color for the border
        backgroundColor: backgroundColor, // Use the selected color for the background
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)', // Add a subtle shadow for depth
        cursor: 'pointer',
      }}
      onClick={() => document.getElementById('brand-color-picker').click()} // Trigger the color picker
    ></div>
    <input
      id="brand-color-picker"
      type="color"
      value={backgroundColor}
      onChange={(e) => setBackgroundColor(e.target.value)}
      style={{
        position: 'absolute', // Position it absolutely
        opacity: 0, // Make it invisible but still interactable
        width: '40px', // Match the size of the custom picker
        height: '20px',
        cursor: 'pointer',
      }}
    />
  </label>
</div>



            {/* Poll Question Input */}
            <div className="textarea-container">
              <textarea
                ref={textareaRef}
                placeholder="Enter poll question..."
                value={newPollQuestion}
                onChange={(e) => {
                  setNewPollQuestion(e.target.value);
                  if (e.target.value.trim()) {
                    setPollQuestionError("");
                  }
                }}
                className={`poll-question-input ${
                  pollQuestionError ? "input-error" : ""
                }`}
                rows={1}
                onInput={(e) => {
                  e.target.style.height = "auto";
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                readOnly={isTyping}
              />
              {pollQuestionError && (
                <p className="error-message" style={{ color: "red" }}>
                  {pollQuestionError}
                </p>
              )}
            </div>

            <div className="generate-poll-options">
              {isGenerating ? (
                <div className="loading-spinner-container">
                  <img
                    src={LoadingIcon}
                    alt="Loading..."
                    className="loading-spinner"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </div>
              ) : (
                <>
                  <button
                    type="button"
                    className="generate-button"
                    onClick={handleGeneratePollOptions}
                    style={{
                      backgroundColor: "#0252ff",
                      color: "white",
                      border: "none",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      margin: "10px 0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                      marginBottom: "25px",
                    }}
                  >
                    Generate Options
                    <img
                      src={AiIcon}
                      alt="AI Icon"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </button>

                  
                </>
              )}


              {/* Custom labels and confidence levels input */}
              {useCustomLabels && (
                <div className="custom-labels-inputs">
                  <button
                    className="add-main-opinion-button"
                    onClick={handleAddMainOpinion}
                    disabled={customLabels.length >= 12}
                  >
                    Add Option +
                  </button>

                  {customLabels.map((label, optionIndex) => {
                    ensureConfidenceRefsInitialized(optionIndex);
                    const confidenceLevels =
                      customConfidenceLabels?.[optionIndex] || [];
                    const numOpinions = customLabels.length;
                    const segmentSize = 360 / numOpinions;

                    const offsetMap = {
                      2: 180,
                      3: 0,
                      4: 130,
                      5: 145,
                      6: 145,
                      7: 155,
                      8: 160,
                      9: 165,
                      10: 165,
                      11: 170,
                      12: 170,
                    };

                    const initialOffset = offsetMap[numOpinions] || 120;
                    const baseHue =
                      (optionIndex * segmentSize + initialOffset) % 360;
                    const mainColor = `hsl(${baseHue}, 100%, 50%)`;

                    const calculateOpacity = (index, total) => {
                      const minOpacity = 0.05;
                      const maxOpacity = 0.8;
                      return (
                        minOpacity +
                        (index / (total - 1)) *
                          (maxOpacity - minOpacity)
                      );
                    };

                    const confidenceColors = (
                      customConfidenceLabels[optionIndex] || []
                    ).map((_, levelIndex, arr) => {
                      const opacity = calculateOpacity(
                        levelIndex,
                        arr.length
                      );
                      return `rgba(${hslToRgb(baseHue, 1, 0.5)}, ${opacity})`;
                    });

                    return (
                      <div
                        key={optionIndex}
                        className={`option-group option-${
                          optionIndex + 1
                        }`}
                      >
                        <div className="main-option-container">
                          <input
                            ref={(el) =>
                              (labelRefs.current[optionIndex] = el)
                            }
                            type="text"
                            value={label}
                            onChange={(e) =>
                              handleCustomLabelChange(
                                optionIndex,
                                e.target.value
                              )
                            }
                            onBlur={() => handleBlur(optionIndex)}
                            className={`main-option-input ${
                              mainOptionAnimations[optionIndex]
                                ? "animated"
                                : ""
                            }`}
                            style={{
                              borderColor: mainColor,
                              backgroundColor: "transparent",
                            }}
                          />
                          <button
                            className="delete-main-opinion-button"
                            onClick={() =>
                              handleDeleteMainOpinion(optionIndex)
                            }
                            disabled={customLabels.length <= 3}
                          >
                            &times;
                          </button>
                        </div>

                        <div className="confidence-levels-inputs">
                          <button
                            className="add-confidence-level-button"
                            onClick={() =>
                              handleAddConfidenceLevel(optionIndex)
                            }
                            disabled={confidenceLevels.length >= 5}
                          >
                            +
                          </button>
                          {confidenceLevels.map((confLabel, labelIndex) => (
                            <div
                              key={labelIndex}
                              className="confidence-input-container"
                            >
                              <input
                                ref={(el) =>
                                  (confidenceRefs.current[optionIndex][
                                    labelIndex
                                  ] = el)
                                }
                                type="text"
                                value={confLabel}
                                onChange={(e) =>
                                  handleCustomConfidenceLabelChange(
                                    optionIndex,
                                    labelIndex,
                                    e.target.value
                                  )
                                }
                                placeholder={`Sub Option ${
                                  labelIndex + 1
                                }`}
                                className={`confidence-input ${
                                  confidenceLevelAnimations[optionIndex]?.[
                                    labelIndex
                                  ]
                                    ? "animated"
                                    : ""
                                }`}
                                style={{
                                  borderColor: mainColor,
                                  backgroundColor:
                                    confidenceColors[labelIndex],
                                }}
                              />
                              <button
                                className="delete-confidence-button"
                                onClick={() =>
                                  handleDeleteConfidenceLevel(
                                    optionIndex,
                                    labelIndex
                                  )
                                }
                              >
                                &times;
                              </button>
                            </div>
                          ))}

                          {(!customConfidenceLabels[optionIndex] ||
                            customConfidenceLabels[optionIndex]
                              .length === 0) && (
                            <p className="no-confidence-labels">
                              No confidence levels available
                            </p>
                          )}

                          <div className="link-button-container">
                            <button
                              className={`link-confidence-button ${
                                linkConfidenceLevelsRef.current
                                  ? "linked"
                                  : "unlinked"
                              }`}
                              onClick={() => {
                                linkConfidenceLevelsRef.current =
                                  !linkConfidenceLevelsRef.current;
                                setForceUpdate((prev) => !prev);
                              }}
                            >
                              <i
                                className={`fa ${
                                  linkConfidenceLevelsRef.current
                                    ? "fa-link"
                                    : "fa-unlink"
                                }`}
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <ColorWheelBuilder
                    options={colorWheelOptions}
                    calculateDynamicColors={calculateDynamicColors}
                    onNodeClick={handleNodeClick}
                  />
                </div>
              )}
            </div>

            {/* Poll Category */}
            <div className="poll-category">
              <label>
                Category
                <Info infoCase="Category" />
                <select
                  value={newPollCategory}
                  onChange={(e) =>
                    setNewPollCategory(e.target.value)
                  }
                  className="category-select"
                >
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            {/* Voting Rules */}
            <div className="poll-auth-method">
              <label>
                Voting Rules
                <Info infoCase="Voting Rules" />
                <select
                  value={voteAuthenticationMethod}
                  onChange={(e) =>
                    setVoteAuthenticationMethod(
                      parseInt(e.target.value)
                    )
                  }
                  className="auth-method-select"
                >
                  <option value={1}>One vote per person</option>
                  <option value={0}>
                    Allow multiple votes per person
                  </option>
                </select>
              </label>
            </div>

            {/* Extra Options */}
            <div className="extra-options">
              {!isAdvertising && (
                <>
                  <div className="poll-create">
                    <label>
                      Redirect After Vote
                      <Info infoCase="Redirect" />
                      <input
                        type="url"
                        value={redirectLink}
                        onChange={(e) =>
                          setRedirectLink(e.target.value)
                        }
                        placeholder="https://example.com"
                        className="redirect-input"
                        style={{
                          height: "25px",
                          marginTop: "5px",
                        }}
                      />
                    </label>
                  </div>
                  <div className="poll-create">
                    <label>
                      Message
                      <textarea
                        value={redirectMessage}
                        onChange={(e) => {
                          if (e.target.value.length <= 70) {
                            setRedirectMessage(e.target.value);
                            setRedirectMessageError("");
                          }
                        }}
                        placeholder="Check out our website!"
                        className="redirect-message"
                        style={{ marginTop: "5px" }}
                      />
                      {redirectMessageError && (
                        <p className="error-message">
                          {redirectMessageError}
                        </p>
                      )}
                    </label>
                  </div>
                </>
              )}

              {visibility === "Private" && (
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="useCaptcha"
                    checked={useCaptcha}
                    onChange={(e) =>
                      setUseCaptcha(e.target.checked)
                    }
                  />
                  <label
                    htmlFor="useCaptcha"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Enable reCAPTCHA
                    <img
                      src={captchaImage}
                      alt="Info"
                      style={{
                        marginLeft: "8px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </label>
                </div>
              )}

              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="useSchedule"
                  checked={useSchedule}
                  onChange={(e) =>
                    setUseSchedule(e.target.checked)
                  }
                />
                <label htmlFor="useSchedule">
                  Set Poll Schedule
                  <Info infoCase="Schedule" />
                </label>
              </div>

              {useSchedule && (
                <div className="schedule-options">
                  <div className="date-picker">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          const now = new Date();
                          const selectedStartDate =
                            date < now ? now : date;

                          if (
                            endDate &&
                            selectedStartDate.toDateString() ===
                              endDate.toDateString()
                          ) {
                            const adjustedEndDate = new Date(endDate);
                            if (selectedStartDate >= endDate) {
                              adjustedEndDate.setHours(
                                selectedStartDate.getHours() + 1
                              );
                              setEndDate(adjustedEndDate);
                            }
                          }
                          setStartDate(selectedStartDate);
                        }}
                        showTimeSelect
                        timeIntervals={1}
                        dateFormat="Pp"
                        placeholderText="Select start date"
                        className="date-picker-input"
                        customInput={<CustomInput label="Start" />}
                        maxDate={endDate}
                      />
                      <button
                        type="button"
                        onClick={() => setStartDate(new Date())}
                        className="now-button"
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "#1a73e8",
                          color: "white",
                          border: "none",
                          padding: "8px 10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Now
                      </button>
                    </div>
                  </div>

                  <div className="date-picker">
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => {
                        const selectedEndDate = new Date(date);
                        if (
                          startDate &&
                          selectedEndDate.toDateString() ===
                            startDate.toDateString()
                        ) {
                          if (selectedEndDate <= startDate) {
                            selectedEndDate.setHours(
                              startDate.getHours() + 1
                            );
                          }
                        }
                        setEndDate(selectedEndDate);
                      }}
                      showTimeSelect
                      timeIntervals={1}
                      dateFormat="Pp"
                      placeholderText="Select end date"
                      className="date-picker-input"
                      customInput={<CustomInput label="End" />}
                      minDate={startDate}
                    />
                  </div>

                  
                </div>
              )}
            </div>
          </div>






        </div>


                  {/* New Promotions Section */}
{isAdvertising && (

  
  <div className="create-poll-content-ads">
    <h2 className="promotions-headline">Promotions</h2>
    
    <div className="promotion-mode-dropdown">
  <select
    id="promotion-mode"
    className="promotion-mode"
    value={promotionMode}
    onChange={(e) => setPromotionMode(e.target.value)}
  >
    <option value="" disabled hidden>
      Promotion Type:
    </option>
    <option value="Dynamic">Vote Based</option>
    <option value="Static">Single</option>
  </select>
</div>





{promotionMode === "Dynamic" ? (
  // Dynamic Mode: Render ads for each option and confidence level
  (useCustomLabels ? customLabels : newPollLabels).map((mainOption, optionIndex) => {
    const numOpinions = customLabels.length;
    const segmentSize = 360 / numOpinions;

    const offsetMap = {
      2: 180,
      3: 0,
      4: 130,
      5: 145,
      6: 145,
      7: 155,
      8: 160,
      9: 165,
      10: 165,
      11: 170,
      12: 170,
    };

    const initialOffset = offsetMap[numOpinions] || 120;
    const baseHue = (optionIndex * segmentSize + initialOffset) % 360;
    const mainColor = `hsl(${baseHue}, 100%, 50%)`;

    return (
      <div key={optionIndex} className="ad-group">
        <h3
          className="ad-group-heading"
          style={{
            backgroundColor: `hsla(${baseHue}, 100%, 50%, 0.8)`,
          }}
        >
          {mainOption.trim() || `Option ${optionIndex + 1}`}
        </h3>

        {ads
          .filter((ad) => ad.optionIndex === optionIndex)
          .map((ad, levelIndex) => {
            const hasConfidenceLevels =
              customConfidenceLabels[optionIndex]?.length > 0;

            const defaultName =
              ad.levelIndex !== null
                ? customConfidenceLabels[optionIndex]?.[ad.levelIndex] ||
                  `Confidence ${ad.levelIndex + 1}`
                : mainOption;

            return (
              <AdEditor
                key={`${optionIndex}-${levelIndex}`}
                optionIndex={optionIndex}
                levelIndex={ad.levelIndex}
                image={ad.image}
                name={ad.productName || defaultName}
                price={ad.price}
                link={ad.link}
                onChange={handleAdInputChange}
                handleAdImageUpload={handleAdImageUpload}
                borderColor={mainColor}
                hasConfidenceLevels={hasConfidenceLevels}
              />
            );
          })}
      </div>
    );
  })
) : (
  // Static Mode: Render a single editable ad
  <div className="ad-group">
    <AdEditorSingle
      optionIndex={0} // Pretend it's the first option
      levelIndex={0} // Pretend it's the first confidence level
      image={ads[0]?.image || ""}
      name={ads[0]?.productName || "Promotion Name"}
      price={ads[0]?.price || ""}
      link={ads[0]?.link || ""}
      onChange={handleAdInputChange}
      handleAdImageUpload={handleAdImageUpload}
      borderColor="#0252ff" // Default color for static mode
      hasConfidenceLevels={false} // No confidence levels in static mode
    />
  </div>
)}

  </div>
)}


        {/* Preview Section */}
        <div className="preview-container">
          <div className="Poll-item">
          <BrandPreviewPoll
  pollId={null}
  question={pollConfig.question}
  labels={pollConfig.labels}
  logo={pollConfig.logo}
  logoFill={pollConfig.logoFill}
  hash={"preview-hash"}
  isLoggedIn={isLoggedIn}
  userId={userId}
  creatorId={userId}
  creatorUsername={username}
  voteAuthenticationMethod={pollConfig.voteAuthenticationMethod}
  confidenceLabels={pollConfig.confidenceLabels}
  imageAction={pollConfig.imageAction}
  roundedCorners={pollConfig.roundedCorners}
  backgroundColor={backgroundColor} // Pass the backgroundColor prop
/>

          </div>
          <div className="preview-header"></div>

          {/* Action Buttons */}
          <div className="page-buttons">
            <button
              className="create-button"
              onClick={handleConfirmCreatePoll}
              disabled={!selectedCampaign}
            >
              Create Poll!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandCreatePoll;
