import React, { useState, useEffect } from "react";
import Tutorial1 from "../assets/tutorial1.jpg";
import Tutorial2 from "../assets/tutorial2.jpg";
import Tutorial3 from "../assets/tutorial3.jpg";
import Tutorial4 from "../assets/tutorial4.jpg";
import Tutorial5 from "../assets/tutorial5.jpg";
import Tutorial6 from "../assets/tutorial6.jpg";
import Tutorial7 from "../assets/tutorial7.jpg";
import "./Tutorial.css"; // Keep styles consistent


const Tutorial = ({ onClose, userHash }) => {
  const [step, setStep] = useState(0);
  const [fade, setFade] = useState(true);
  const [referralLink, setReferralLink] = useState("");
  const [copyStatus, setCopyStatus] = useState("Copy Link");

  useEffect(() => {
    if (userHash) {
      setReferralLink(`${window.location.origin}/signup?ref=${userHash}`);
    }
  }, [userHash]);

  const tutorialSteps = [
    {
      title: "Welcome to Your Dashboard",
      image: Tutorial1,
      description:
        "This is your main dashboard where you can manage everything related to your polls, earnings, referrals, payment, and more.",
    },
    {
      title: "Creating a Poll",
      image: Tutorial2,
      description:
        "Easily create new polls by clicking the 'Create Poll' button in the sidebar.",
    },
    {
      title: "Share your Poll",
      image: Tutorial3,
      description:
        "Once you have created a poll, use our plethora of share tools to reach your audience on any social platform.",
    },
    {
      title: "Automatic Monetization",
      image: Tutorial4,
      description:
        "Once your poll receives its first vote, the product fetching process will begin! We search over 350 million products to find the perfect matches for your poll.",
    },
    {
      title: "Edit Products (optional)",
      image: Tutorial5,
      description:
        "If you have a specific product in mind, or one of the products isn’t to your liking, you can easily edit and replace the product with any Amazon.com link.",
    },
    {
      title: "It's that easy!",
      image: Tutorial6,
      description:
        "All the products on your polls are now fully set up for you to start earning from, every purchase from one of your polls will automatically be attributed to you!",
    },
    {
      title: "Refer a friend",
      image: Tutorial7,
      description:
        "If a friend signs up using your referral link, you’ll earn 9% of all revenue they generate—paid from our share, not theirs!",
    },
  ];

  const handleNext = () => {
    if (step < tutorialSteps.length - 1) {
      setFade(false);
      setTimeout(() => {
        setStep(step + 1);
        setFade(true);
      }, 200);
    } else {
      onClose();
    }
  };

  const handlePrevious = () => {
    if (step > 0) {
      setFade(false);
      setTimeout(() => {
        setStep(step - 1);
        setFade(true);
      }, 200);
    }
  };

  const handleFinish = async () => {
    try {
      await fetch("/auth/mark-tutorial-seen", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userHash }),
      });
      onClose(); // Close the tutorial
    } catch (error) {
      console.error("Error marking tutorial as seen:", error);
    }
  };
  

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    setCopyStatus("Copied!");
    setTimeout(() => setCopyStatus("Copy Link"), 3000);
  };

  return (
    <div className="tutorial-overlay">
      <div className="tutorial-container">
        <div className="tutorial-counter">
          {step + 1} / {tutorialSteps.length}
        </div>
        <div className={`tutorial-content ${fade ? "fade-in" : "fade-out"}`}>
          <h2>{tutorialSteps[step].title}</h2>
          <div className="tutorial-content-container">
            <p>{tutorialSteps[step].description}</p>
            <img
              src={tutorialSteps[step].image}
              alt="Tutorial step"
              className="tutorial-image"
            />
            {/* Referral Link Input (Only on Last Slide) */}
            {step === tutorialSteps.length - 1 && (
  <div className="tutorial-affiliate-link-container">
    <input
      type="text"
      value={referralLink}
      readOnly
      className="tutorial-affiliate-link-input"
    />
    <button onClick={handleCopyLink} className="tutorial-affiliate-copy-button">
      {copyStatus}
    </button>
  </div>
)}

          </div>
          <div className="tutorial-buttons">
            <button
              onClick={handlePrevious}
              disabled={step === 0}
              className={step === 0 ? "disabled" : ""}
            >
              Previous
            </button>
            <button onClick={step < tutorialSteps.length - 1 ? handleNext : handleFinish}>
  {step < tutorialSteps.length - 1 ? "Next" : "Finish"}
</button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
