import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Reports.css'; // Import the CSS file
import Onboarding from './Onboarding'; // Import the Onboarding component
import Trash from '../assets/trash.png';
import File from '../assets/file.png';

const Reports = ({ userId, onPollSelect, username }) => {
    const [polls, setPolls] = useState([]);
    const [filteredPolls, setFilteredPolls] = useState([]);
    const [reports, setReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pollSearchQuery, setPollSearchQuery] = useState('');
    const [reportSearchQuery, setReportSearchQuery] = useState('');

    // Fetch user's polls
    useEffect(() => {
        const fetchPolls = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/poll/details?creator=${userId}`);
                const sortedPolls = response.data.polls.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                setPolls(sortedPolls);
                setFilteredPolls(sortedPolls);
            } catch (error) {
                console.error('Error fetching polls:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPolls();
    }, [userId]);

    // Fetch user's reports
    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await axios.get(`/reports?creator=${userId}`);
                const sortedReports = response.data.reports.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                setReports(sortedReports);
                setFilteredReports(sortedReports);
            } catch (error) {
                console.error('Error fetching reports:', error);
            }
        };

        fetchReports();
    }, [userId]);

    const handlePollClick = async (pollId, question) => {
        const defaultName = `${question}`;
        const reportName = prompt('Enter a name for your report:', defaultName);

        if (!reportName) {
            alert('Report creation canceled.');
            return;
        }

        try {
            const response = await axios.post('/reports/create', {
                reportName,
                pollId,
                creator: userId,
            });

            const createdReport = response.data.report;

            onPollSelect(createdReport._id); // Pass the reportId (not pollId) to the parent component
        } catch (error) {
            console.error('Error creating report:', error);
            alert('Failed to create the report.');
        }
    };

    const handleReportClick = (reportId) => {
        onPollSelect(reportId,); // Open the report when clicked
    };

    const handlePollSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setPollSearchQuery(query);
        const filtered = polls.filter((poll) =>
            poll.question.toLowerCase().includes(query)
        );
        setFilteredPolls(filtered);
    };

    const handleDeleteReport = async (reportId) => {
      const confirmDelete = window.confirm('Are you sure you want to delete this report?');
      if (!confirmDelete) return;
    
      try {
        await axios.delete(`/reports/delete/${reportId}`);
        alert('Report deleted successfully');
        setReports((prevReports) => prevReports.filter((report) => report._id !== reportId));
        setFilteredReports((prevFilteredReports) =>
          prevFilteredReports.filter((report) => report._id !== reportId)
        );
      } catch (error) {
        console.error('Error deleting report:', error);
        alert('Failed to delete the report. Please try again.');
      }
    };
    

    const handleReportSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setReportSearchQuery(query);
        const filtered = reports.filter((report) =>
            report.reportName.toLowerCase().includes(query)
        );
        setFilteredReports(filtered);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="reports-page">
            <div className="reports-grid">
                {/* Create New Report Section */}
                <div className="create-report">
                    <h2>Create New Report</h2>
                    <input
                        type="text"
                        className="search-box"
                        placeholder="Search polls by question..."
                        value={pollSearchQuery}
                        onChange={handlePollSearch}
                    />
                    {filteredPolls.length > 0 ? (
                        <div className="poll-grid">
                            {filteredPolls.map((poll) => (
                                <div
                                    key={poll.pollID}
                                    className="poll-card"
                                    onClick={() => handlePollClick(poll.pollID, poll.question)}
                                >
                                    {poll.question}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            style={{ display: 'flex', height: '80vh' }}
                        >
                            <Onboarding
                                username={username}
                                headline="Reports"
                                subheadline="Create a poll to start generating reports!"
                            />
                        </div>
                    )}
                </div>

                {/* Previous Reports Section */}
                <div className="previous-reports">
                    <h2>Previous Reports</h2>
                    <input
                        type="text"
                        className="search-box"
                        placeholder="Search reports by name..."
                        value={reportSearchQuery}
                        onChange={handleReportSearch}
                    />
                    {filteredReports.length > 0 ? (
                        <div className="previous-report-grid">
                            {filteredReports.map((report) => (
                                <div
                                    key={report._id}
                                    className="previous-report-card"
                                >
                                    <div className="previous-report-header">
                                        <p className="previous-report-name">{report.reportName}</p>
                                    </div>
                                    <div className="previous-report-footer">
                                        <div className="previous-report-actions">
                                            <button
                                                className="previous-view-btn"
                                                onClick={() => handleReportClick(report._id)}
                                            >
                                                View
                                            </button>
                                            <button
  className="previous-delete-btn"
  onClick={() => handleDeleteReport(report._id)}
>
  <img src={Trash} alt="Delete" />
</button>

                                        </div>
                                        <span className="previous-report-date">
                                            {new Date(report.createdAt).toLocaleDateString()}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>You havent created any reports yet.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Reports;
