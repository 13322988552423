import React, { useEffect } from 'react';
import './Advertisements.css';

const Advertisements = ({ pollHash }) => {
  useEffect(() => {
    // Add the Taboola loader script dynamically
    const headScript = document.createElement('script');
    headScript.type = 'text/javascript';
    headScript.innerHTML = `
      window._taboola = window._taboola || [];
      _taboola.push({article: 'auto'});
      !function(e, f, u, i) {
        if (!document.getElementById(i)){
          e.async = 1;
          e.src = u;
          e.id = i;
          f.parentNode.insertBefore(e, f);
        }
      }(document.createElement('script'),
      document.getElementsByTagName('script')[0],
      '//cdn.taboola.com/libtrc/opinari-opinari/loader.js',
      'tb_loader_script');
      if (window.performance && typeof window.performance.mark === 'function') {
        window.performance.mark('tbl_ic');
      }
    `;
    document.head.appendChild(headScript);

    return () => {
      if (headScript.parentNode) {
        document.head.removeChild(headScript);
      }
    };
  }, []);

  useEffect(() => {
    const initializeTaboola = () => {
      if (window._taboola) {
        const baseDomain = window.location.origin;
        const pathName = window.location.pathname;
        
        // Construct page URL with UTM parameters using the pollHash
        const pageUrl = `${baseDomain}${pathName}?utm_source=opinari&utm_medium=poll&utm_campaign=${pollHash}`;

        window._taboola.push({
          mode: 'alternating-thumbnails-a',
          container: 'taboola-below-article-thumbnails',
          placement: 'Below Article Thumbnails',
          target_type: 'mix',
          page_url: pageUrl // Set page_url with unique UTM parameters
        });
      }
    };

    initializeTaboola();

    // Flush Taboola scripts
    const flushScript = document.createElement('script');
    flushScript.type = 'text/javascript';
    flushScript.innerHTML = `
      window._taboola = window._taboola || [];
      _taboola.push({flush: true});
    `;
    document.body.appendChild(flushScript);

    return () => {
      if (flushScript.parentNode) {
        document.body.removeChild(flushScript);
      }
    };
  }, [pollHash]);

  return (
    <div className="advertisements-container">
      <div id="taboola-below-article-thumbnails"></div>
    </div>
  );
};

export default Advertisements;



// import React, { useEffect, useState } from 'react';
//  import './Advertisements.css';


// const Advertisements = () => {
//   const [product, setProduct] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchProduct = async () => {
//       try {
//         const response = await fetch('/api/amazon-product');
//         const data = await response.json();

//         if (data.error) {
//           setError(data.error);
//         } else {
//           setProduct(data);
//         }
//       } catch (error) {
//         setError('Failed to fetch product');
//       }
//     };

//     fetchProduct();
//   }, []);

//   return (
//     <div className="advertisements-container">
//       {error ? (
//         <p>{error}</p>
//       ) : product ? (
//         <a href={product.DetailPageURL} target="_blank" rel="noopener noreferrer">
//           {product.ImageURL ? (
//             <img src={product.ImageURL} alt={product.Title} />
//           ) : (
//             <p>No image available</p>
//           )}
//           <p>{product.Title}</p>
//           <p>{product.Price}</p>
//         </a>
//       ) : (
//         <p>Loading product...</p>
//       )}
//     </div>
//   );
// };

// export default Advertisements;

