import React, { useState } from "react";
import "./LargeAd.css";

const LargeAd = ({ id, image, name, price, link, borderColor, voteCount, isTopVoted, creatorUsername, onUpdate, pollId, isOwner, pollQuestion }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newAmazonUrl, setNewAmazonUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleClick = async (e) => {
    if (!link || !creatorUsername) return;
    e.preventDefault(); 

    window.location.href = link;
  };

  return (
    <>
      <div
        className="large-ad-container"
        style={{ border: `2px solid ${borderColor || "gray"}` }}
      >
        <div className="large-ad-image">
          <img
            src={image || "https://bluejuicecomics.com/wp-content/uploads/2017/02/Amazon-icon-white.png"}
            alt={name}
          />
        </div>
        <div className="large-ad-details">
          <div className="large-ad-name">{name || "Product Name"}</div>
          
          <div className="price-votes-button-wrapper">
            <div>
              <div className="large-ad-price">
                {price && !isNaN(parseFloat(price.replace(/[^0-9.]/g, "")))
                  ? `$${parseFloat(price.replace(/[^0-9.]/g, "")).toFixed(2)}`
                  : "$0.00"}
              </div>
              <div className="large-ad-votes">Votes: {voteCount ?? 0}</div>
            </div>

            <button 
              onClick={handleClick} 
              className="view-button"
              style={{ 
                marginTop: "10px",
                padding: "5px 15px",
                cursor: "pointer",
                position: "relative",
                zIndex: 10000000000,
                color: "white",
                background: "#0066ff",
                borderRadius: "5px",
                border: "none"
              }}
            >
              View
            </button>
          </div>
          
        </div>
        {isTopVoted && <div className="large-trophy-icon">🏆</div>}
      </div>
    </>
  );
};

export default LargeAd;
