import React from "react";
import "./Ad.css"; // Use the same CSS as the live Ad component
import Placeholder from "../assets/upload-ad-image.png";

const AdEditor = ({
  image,
  name,
  price,
  link,
  onChange,
  handleAdImageUpload,
  borderColor,
  optionIndex,
  levelIndex,
  hasConfidenceLevels, // New prop to indicate if confidence levels exist
}) => {
  // Handle price input to ensure it's a valid monetary value
  const handlePriceChange = (e) => {
    const value = e.target.value;

    // Allow empty input for initial typing
    if (value === "") {
      onChange(optionIndex, levelIndex, "price", value);
      return;
    }

    // Regex to match valid monetary values (digits with optional 2 decimal places)
    const regex = /^\d+(\.\d{0,2})?$/;

    if (regex.test(value) || value === ".") {
      onChange(optionIndex, levelIndex, "price", value);
    }
  };

  // Link validation and correction function
  const sanitizeLink = (url) => {
    try {
      // Use the URL constructor to validate the URL
      const parsedUrl = new URL(url);
      // Ensure the URL has a valid protocol (http or https)
      if (parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:") {
        return url; // Return the valid URL as-is
      }
    } catch (error) {
      // If the URL constructor throws an error, attempt to fix the URL
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        // Add "http://" if the protocol is missing
        return `http://${url}`;
      }
    }
    return url; // Return the sanitized URL
  };

  // Handle link input with validation and correction
  const handleLinkChange = (e) => {
    const value = e.target.value;

    // Sanitize the link and update the state
    const sanitizedLink = sanitizeLink(value);
    onChange(optionIndex, levelIndex, "link", sanitizedLink);
  };

  return (
    <div className="editable-ad">
      {/* Conditionally render the uneditable name field */}
      {hasConfidenceLevels && (
        <div className="ad-name non-editable-name">
          {name || "Product Name"}
        </div>
      )}

      <div
        className="ad-container-editor"
        style={{ border: `2px solid ${borderColor || "gray"}` }}
      >
        {/* Image Section */}
        <div className="ad-editor-image">
          <img
            src={image || Placeholder}
            alt={name || "Product Name"}
            onClick={() =>
              document
                .getElementById(`ad-image-upload-${optionIndex}-${levelIndex}`)
                .click()
            } // Use unique ID
            style={{ cursor: "pointer" }}
          />
          <input
            type="file"
            accept="image/*"
            id={`ad-image-upload-${optionIndex}-${levelIndex}`} // Unique ID for each ad
            style={{ display: "none" }}
            onChange={(e) => handleAdImageUpload(e, optionIndex, levelIndex)} // Use handleAdImageUpload
          />
        </div>
      </div>

      <div className="ad-editor-price">
        <input
          type="url"
          value={link}
          onChange={handleLinkChange} // Use the link validation and correction handler
          placeholder="Promotion Link"
          className="ad-link"
        />
      </div>
    </div>
  );
};

export default AdEditor;
