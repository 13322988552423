import React, { useState, useEffect } from 'react';
import './About.css';
import DesktopBG from '../assets/bg.png';
import MobileBG from '../assets/bg.png';
import ScrollingPollsVertical from './ScrollingPollsVertical';
import ScrollingPollsVerticalReverse from './ScrollingPollsVerticalReverse';
import { useNavigate, Link } from 'react-router-dom';
import {  generateSvgBackgroundDataUrl } from "./SvgBackground"; // Adjust the path as needed



import Logo from '../assets/logo.png';
import HeroImg from '../assets/Hero.png';
import YouTubeIcon from '../assets/youtube.png';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import LinkedInIcon from '../assets/linkedin.png';
import PinterestIcon from '../assets/pinterest.png';
import RedditIcon from '../assets/reddit.png';
import SnapchatIcon from '../assets/snapchat.png';
import TikTokIcon from '../assets/tiktok.png';
import XIcon from '../assets/x.png';
import OpinionsImg from '../assets/opinions.png';
import FlexibilityImg from '../assets/flexibility.png';
import HandledImg from '../assets/handled.png';
import Icon from '../assets/logo-icon.png';


const handleClick = () => {
  window.location.href = "/signup";
};


const About = ({ isLoggedIn }) => {
    const isDesktop = window.innerWidth >= 768;

    const backgroundStyle = {
      backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
    };

    const navigate = useNavigate();

      const [bgDataUrl, setBgDataUrl] = useState(""); // Background SVG
      const [dotColor, setDotColor] = useState("#222222"); // Default dot color
    

    const goToSignUp = () => {
      navigate('/signup');
    };
    const goToDashboard = () => {
      navigate('/dashboard/main');
    };
    const goToFeed = () => {
        navigate('/feed');
      };

       useEffect(() => {
          // Generate initial background
          setBgDataUrl(
            generateSvgBackgroundDataUrl({
              dotColor,
              bgColor: "#0c0c0c",
              spacing: 20,
              dotRadius: 1,
            })
          );
        }, [dotColor]);

    return (
      
        <div className="about-page-wrapper"
        style={{
          backgroundImage: `url(${bgDataUrl})`,
          backgroundAttachment: "fixed", // Ensures parallax effect
          backgroundRepeat: "repeat", // Ensures the pattern tiles
          backgroundSize: `20px 20px`, // Makes sure the pattern tiles correctly
          minHeight: "100%",
          paddingTop: '10px'
        }}>


                <div className="plain-page-header">

                <h2>About</h2>

                </div>

            <div className="about-container">
                <div className="combined-sections-container">
                <section className="about-para-section">
  <div className="text-content">

    
    <p>
      
  <h3 class="about-item-title">Origin</h3>
      <strong>OPINARI</strong> | <em>oh-pin-r-ee</em> | [verb]  
      <br />
      The Latin word meaning “to be of the opinion” refers to the act of forming or holding an opinion or belief about something.
      <br /><br />

      <h3 class="about-item-title">Mission</h3>

    

OPINARI is the first opinion-driven affiliate marketing platform. Our revolutionary polls automatically connect responses to the highest-converting products, guaranteeing next-level earnings from your audience.

<br /><br />

OPINARI also eliminates all the hassles of affiliate marketing such as sourcing products, lengthy sign-ups, approval processes, and getting paid.

<br /><br />



      <iframe 
    src="https://www.youtube.com/embed/acWPCFGJUMs?si=9Zb-J0iCvUkNDqbL"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerPolicy="strict-origin-when-cross-origin"
    allowFullScreen
    style={{
        width: "100%",
        height: "auto",
        aspectRatio: "16 / 9",
        borderRadius: "10px"
    }}
></iframe>


<br /><br />

<h3 class="about-item-title">How it works </h3>



      OPINARI is 100% free to use and provides an exciting new revenue source for anyone.
      <br /><br />
      <strong>Poll creators earn 70% of affiliate commissions</strong> on every sale, transforming opinions into revenue.
      <br /><br />
      OPINARI's patent-pending <strong>thumb-driven color wheel</strong> elevates social media engagement with a seamless, interactive experience that keeps your audience entertained—effortlessly.
      <br /><br />
      <strong>AI-powered poll creation</strong> seamlessly curates relevant affiliate products for poll responses, eliminating the hassle of manually sourcing affiliate products, images, and links.
      <br /><br />
      OPINARI provides a <strong>dynamic dashboard</strong> with live votes, results, revenue tracking, geographic insights, AI reports, sharing tools, and payment processing.
      <br /><br />
      <strong>Anonymous polling</strong> unlocks shopping opportunities by showcasing real votes that highlight trending products and track brand popularity in real time.
      <br /><br />
      Share polls with a <strong>link, QR code, and powerful tools</strong>—ideal for social platforms like Instagram, YouTube, X, Discord, and more.
      <br /><br />
      Our <strong>affiliate partnership guarantees global shopping coverage</strong> and guaranteed affiliate commissions on every purchase from your poll.
      <br /><br />
      OPINARI is founded by passionate entrepreneurs who absolutely love seeing other entrepreneurs crush their goals—reach out anytime if you wanna chat!
      <br /><br />
      When you join, be sure to participate in our referral program, where we offer an unlimited <strong>9% commission</strong> when anyone you refer makes money.
      <br /><br />
      <em>Built with love from Canada🍁</em>
      <br /><br />

      <div className="about-footer">


      <button className="start-earning-button" onClick={handleClick}>
        <img src={Icon} alt="Icon" className="button-icon" />
        <span>Start Earning!</span>
      </button>

      </div>
    </p>
  </div>
</section>


  

 
  




                    
                </div>
            </div>

            
        </div>
    );
};

export default About;