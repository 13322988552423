import React, { useRef, useEffect, useState, useCallback,  useMemo, } from 'react';
import Ad from './Ad';
import ConfidenceData from './ConfidenceData';
import '../App.css';
import { Trophy } from 'lucide-react';


const ConfidenceBar = ({
  label = '',
  confidencePercentages = [],
  confidenceLabels = [],
  confidenceLinks = [],
  className = '',
  optionPercentage = '0.0',
  compressionFactor = 1,
  isAdvertising = false,
  isGlobalTop,
  creatorUsername,
  textColor
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [hoveredSegment, setHoveredSegment] = useState(null);
  const [sliderLeft, setSliderLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [defaultChosen, setDefaultChosen] = useState(false);
  const [adDetails, setAdDetails] = useState({
    name: 'Default Product',
    link: '#',
    price: 'N/A',
  });

  const barRef = useRef(null);

  // Detect touch devices
  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }, []);

  // Slider default: 80% from the left
  useEffect(() => {
    const updateDefaultSlider = () => {
      if (barRef.current) {
        const barRect = barRef.current.getBoundingClientRect();
        const defaultLeft = barRect.width * 0.8;
        setSliderLeft(Math.min(defaultLeft, barRect.width));
      }
    };
    updateDefaultSlider();
    window.addEventListener('resize', updateDefaultSlider);
    return () => window.removeEventListener('resize', updateDefaultSlider);
  }, []);

  // Stop dragging on mouse up
  useEffect(() => {
    const handleMouseUp = () => setIsDragging(false);
    if (isDragging) {
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, [isDragging]);

  // Filter out segments that have zero votes
  const filteredConfidencePercentages = confidencePercentages.filter(
    (segment) => segment.percentage > 0
  );


  const maxVoteCount = useMemo(() => {
      return filteredConfidencePercentages.reduce(
        (max, segment) => Math.max(max, segment.count || 0),
        0
      );
    }, [filteredConfidencePercentages]);

  /**
   * 1) By default, pick the highest-voted segment (tie → random)
   * 2) Only do this if hasUserInteracted == false AND we haven't chosen a default yet
   */
   // Update ad details based on slider position
   useEffect(() => {
    // Handle the special case: only one confidence link exists
    if (confidenceLinks.length === 1) {
      const linkObj = confidenceLinks[0];
      const voteCount =
        filteredConfidencePercentages.length === 1 && filteredConfidencePercentages[0].count
          ? filteredConfidencePercentages[0].count
          : 0;
      setAdDetails({
        name: linkObj.level || linkObj.name || "Product Name",
        link: linkObj.url || "#",
        price: linkObj.price || "0.00",
        image:
          linkObj.image ||
          "https://bluejuicecomics.com/wp-content/uploads/2017/02/Amazon-icon-white.png",
        voteCount: voteCount,
      });
      return;
    }
  
    // If no segments exist, show default product info.
    if (!barRef.current || filteredConfidencePercentages.length === 0) {
      setAdDetails({
        name: "Product Name",
        link: "#",
        price: "0.00",
        image:
          "https://bluejuicecomics.com/wp-content/uploads/2017/02/Amazon-icon-white.png",
        voteCount: 0,
      });
      return;
    }
  
    const barRect = barRef.current.getBoundingClientRect();
    let accumulatedWidth = 0;
    let segmentIndex = -1;
    for (let i = 0; i < filteredConfidencePercentages.length; i++) {
      accumulatedWidth += (filteredConfidencePercentages[i].percentage / 100) * barRect.width;
      if (sliderLeft <= accumulatedWidth) {
        segmentIndex = i;
        break;
      }
    }
    if (segmentIndex !== -1) {
      setHoveredSegment(segmentIndex);
      const segment = filteredConfidencePercentages[segmentIndex];
      const linkObj =
        confidenceLinks.find((p) => p.level === segment.label) || {
          url: "#",
          name: "Unknown Product",
          price: "0.00",
          image:
            "https://bluejuicecomics.com/wp-content/uploads/2017/02/Amazon-icon-white.png",
        };
  
      setAdDetails({
        name: linkObj.level || segment.label || "Product Name",
        link: linkObj.url || "#",
        price: linkObj.price || "0.00",
        image: linkObj.image,
        voteCount: segment.count ?? 0,
      });
    }
  }, [sliderLeft, filteredConfidencePercentages, confidenceLinks]);
  
   
 
   // Handle mouse or touch interaction on the bar
   const handleSegmentInteraction = useCallback(
     (event) => {
       if (!barRef.current) return;
       const barRect = barRef.current.getBoundingClientRect();
       const clientX = event.clientX || event.touches?.[0]?.clientX;
       if (!clientX) return;
       setHasUserInteracted(true);
       const newSliderLeft = clientX - barRect.left;
       const clampedSliderLeft = Math.max(
         0,
         Math.min(newSliderLeft, barRect.width)
       );
       setSliderLeft(clampedSliderLeft);
       let accumulatedWidth = 0;
       let segmentIndex = -1;
       for (let i = 0; i < filteredConfidencePercentages.length; i++) {
         accumulatedWidth +=
           (filteredConfidencePercentages[i].percentage / 100) * barRect.width;
         if (clampedSliderLeft <= accumulatedWidth) {
           segmentIndex = i;
           break;
         }
       }
       if (segmentIndex !== -1) {
         setHoveredSegment(segmentIndex);
         const segment = filteredConfidencePercentages[segmentIndex];
         const linkObj =
           confidenceLinks.find((p) => p.level === segment.label) || {
             url: '#',
             name: 'Unknown Product',
             price: '0.00',
             image:
               'https://bluejuicecomics.com/wp-content/uploads/2017/02/Amazon-icon-white.png',
           };
         setAdDetails({
           name: linkObj.level || segment.label || 'Product Name',
           link: linkObj.url || '#',
           price: linkObj.price || '0.00',
           image: linkObj.image,
           voteCount: segment.count ?? 0, // Include vote count here
         });
       }
       
     },
     [filteredConfidencePercentages, confidenceLinks]
  );

  let maxConfidenceColor = 'gray';
  if (filteredConfidencePercentages.length > 0) {
    const maxSegment = filteredConfidencePercentages.reduce((prev, cur) =>
      (cur.count ?? 0) > (prev.count ?? 0) ? cur : prev
    );
    maxConfidenceColor = maxSegment?.color ?? 'gray';
  }

  // Global drag handlers to continue dragging even if the pointer moves outside the bar
  useEffect(() => {
    const handleGlobalMove = (e) => {
      handleSegmentInteraction(e);
    };
  
    const handleGlobalUp = () => {
      setIsDragging(false);
    };
  
    if (isDragging) {
      window.addEventListener('mousemove', handleGlobalMove);
      window.addEventListener('touchmove', handleGlobalMove);
      window.addEventListener('mouseup', handleGlobalUp);
      window.addEventListener('touchend', handleGlobalUp);
    }
  
    return () => {
      window.removeEventListener('mousemove', handleGlobalMove);
      window.removeEventListener('touchmove', handleGlobalMove);
      window.removeEventListener('mouseup', handleGlobalUp);
      window.removeEventListener('touchend', handleGlobalUp);
    };
  }, [isDragging, handleSegmentInteraction]);


  useEffect(() => {
    console.log(`Confidence Links for ${label}:`, confidenceLinks);
  }, [confidenceLinks, label]);
  

  return (
    <div
      ref={barRef}
      className={`confidence-bar-wrapper ${className} ${isVisible ? 'visible' : ''}`}
      style={{ position: 'relative', cursor: isDragging ? 'grabbing' : 'grab' }}
      onMouseLeave={() => setHoveredSegment(null)}
    >
      {isVisible && (
        <>
          <div className="confidence-data">
          <h1 className="confidence-label" style={{ display: 'flex', alignItems: 'center', gap: '6px', color: textColor }}>
  {label}

</h1>            <div className="option-percentage">{optionPercentage}%</div>
          </div>
          {isAdvertising ? (
  <Ad
    image={adDetails.image}
    name={adDetails.name}
    link={adDetails.link}
    price={adDetails.price}
    borderColor={maxConfidenceColor}
    voteCount={adDetails.voteCount}
    isTopVoted={isGlobalTop && (adDetails.voteCount === maxVoteCount)}
    creatorUsername={creatorUsername} // ✅ Pass creatorUsername

  />
) : (
  <ConfidenceData
    confidenceLevel={adDetails.name}
    voteCount={
      hoveredSegment !== null
        ? filteredConfidencePercentages[hoveredSegment]?.count ?? 0
        : 0
    }
    borderColor={maxConfidenceColor}
  />
)}

          {/* Confidence Bar Container: dragging starts when clicking/touching here */}
          <div
  className="confidence-bar-container"
  style={{ position: 'relative', overflow: 'hidden' }}
  onMouseDown={(e) => {
    setIsDragging(true);
    handleSegmentInteraction(e);
  }}
  onTouchStart={(e) => {
    setIsDragging(true);
    handleSegmentInteraction(e);
  }}
>
  {confidenceLinks.length === 1 ? (
    <div
      className="confidence-segment"
      style={{
        backgroundColor: confidencePercentages[0]?.color || 'gray',
        width: '100%',
      }}
    />
  ) : filteredConfidencePercentages.length === 0 ? (
    <div
      className="confidence-segment"
      style={{
        backgroundColor: confidencePercentages[0]?.color || 'gray',
        width: '100%',
      }}
    />
  ) : filteredConfidencePercentages.length === 1 ? (
    <div
      className="confidence-segment"
      style={{
        backgroundColor: filteredConfidencePercentages[0].color || 'gray',
        width: '100%',
      }}
    />
  ) : (
    filteredConfidencePercentages.map((segment, index) => (
      <div
        key={index}
        className={`confidence-segment ${hoveredSegment === index ? 'hovered' : ''}`}
        style={{
          backgroundColor: segment.color,
          width: `${segment.percentage}%`,
        }}
      />
    ))
  )}
</div>


          {confidenceLabels.length > 0 && (
            <div className="confidence-vote-counts">
              {filteredConfidencePercentages.map((segment, index) => (
                <div key={index} className="confidence-vote-count">
                  {`${segment.percentage}%`}
                </div>
              ))}
            </div>
          )}

          {/* Slider Handle: also supports direct grabbing */}
          {(confidenceLinks.length > 1 && filteredConfidencePercentages.length > 1) && (
  <div
    className="slider-control"
    style={{
      position: 'absolute',
      top: '149px',
      left: `${sliderLeft - 6}px`,
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      // Subtle top-to-bottom gradient
      background: 'linear-gradient(to bottom, #ffffff,rgb(191, 191, 191))',
      // Single drop shadow
      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.42)',
      zIndex: 1500,
      cursor: isDragging ? 'grabbing' : 'grab',
      transition: isDragging ? 'none' : 'left 1s ease-out',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    onMouseDown={(e) => {
      setIsDragging(true);
      handleSegmentInteraction(e);
    }}
    onTouchStart={(e) => {
      setIsDragging(true);
      handleSegmentInteraction(e);
    }}
  >
    {/* Left Triangle */}
    {/* <div
      className="slider-triangle-left"
      style={{
        position: 'absolute',
        left: '-25px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid white',
        borderTop: '8px solid transparent',
        borderBottom: '8px solid transparent',
      }}
    /> */}

    {/* Right Triangle */}
    {/* <div
      className="slider-triangle-right"
      style={{
        position: 'absolute',
        right: '-25px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '0',
        height: '0',
        borderRight: '10px solid transparent',
        borderLeft: '10px solid white',
        borderTop: '8px solid transparent',
        borderBottom: '8px solid transparent',
      }}
    /> */}
  </div>
)}



          <div className="confidence-divider"> </div>
        </>
      )}
    </div>
  );
};

export default ConfidenceBar;