import React, { useState, forwardRef } from 'react';
import LoadingIcon from '../assets/loading-small-white.png';
import AiIcon from '../assets/ai.png';
import './EasyBuilder.css';

// Use forwardRef to pass the ref to the input field
const EasyBuilder = forwardRef(({ onCreate, onCreating, scrollToTop  }, ref) => {
  const [pollQuestion, setPollQuestion] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [customLabels, setCustomLabels] = useState([]);
  const [customConfidenceLabels, setCustomConfidenceLabels] = useState([]);

  const handleCreatePoll = async () => {
    if (!pollQuestion.trim()) {
      alert("Please enter a question.");
      return;
    }
  
    if (scrollToTop) scrollToTop(); // Scroll to top when poll creation starts
  
    setIsCreating(true);
    if (onCreating) onCreating(true);
  
    try {
      // If no custom labels are provided, generate options via AI endpoint.
      let aiLabels = customLabels;
      let aiConfidence = customConfidenceLabels;
      let validatedQuestion = pollQuestion; // default to original question
      let aiCategory = "Technology"; // default fallback



  
      if (aiLabels.length === 0) {
        const genResponse = await fetch('/api/generate-poll-options', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ question: pollQuestion })
        });
        if (!genResponse.ok) {
          throw new Error("Failed to generate poll options");
        }
        const genData = await genResponse.json();
        if (
          genData.answers &&
          genData.answers.customLabels &&
          genData.answers.customConfidenceLabels
        ) {
          aiLabels = genData.answers.customLabels;
          aiConfidence = genData.answers.customConfidenceLabels;
          aiCategory = genData.answers.category;

          // Update validatedQuestion if available
          if (genData.validatedQuestion) {
            validatedQuestion = genData.validatedQuestion;
          }
        } else {
          alert("Sorry, we had an issue, please try again.");
        }
      }
  
      const pollConfig = {
        question: validatedQuestion.trim(), // use the validated question
        labels: aiLabels.length > 0 ? aiLabels : ['Agree', 'Neutral', 'Disagree'],
        confidenceLabels: aiConfidence.length > 0
          ? aiConfidence
          : [
              ['Marginally', 'Somewhat', 'Strongly'],
              ['Marginally', 'Somewhat', 'Strongly'],
              ['Marginally', 'Somewhat', 'Strongly']
            ],
        category: aiCategory,
        creator: "678545751c84e60ba7534d09",
        creatorUsername: "Admin",
        voteAuthenticationMethod: 1,
        visibility: "Public",
        logo: '/logo-poll.png',
        logoFill: false,
        imageAction: 0,
        roundedCorners: false,
      };
  
      const formData = new FormData();
      formData.append('question', pollConfig.question);
      formData.append('labels', JSON.stringify(pollConfig.labels));
      formData.append('confidenceLabels', JSON.stringify(pollConfig.confidenceLabels));
      formData.append('category', pollConfig.category);
      formData.append('creator', pollConfig.creator);
      formData.append('creatorUsername', pollConfig.creatorUsername);
      formData.append('voteAuthenticationMethod', pollConfig.voteAuthenticationMethod);
      formData.append('visibility', pollConfig.visibility);
      formData.append('logo', pollConfig.logo);
      formData.append('logoFill', pollConfig.logoFill);
      formData.append('imageAction', pollConfig.imageAction);
      formData.append('roundedCorners', pollConfig.roundedCorners);
  
      const createResponse = await fetch('/ai-polls', {
        method: 'POST',
        body: formData,
      });
  
      if (!createResponse.ok) {
        throw new Error("Failed to create poll");
      }
  
      const createData = await createResponse.json();
      if (onCreate) onCreate(createData.hash);
  
      // Clear input and generated answers.
      setPollQuestion('');
      setCustomLabels([]);
      setCustomConfidenceLabels([]);
    } catch (error) {
      console.error("Error creating poll:", error);
      alert("An error occurred while creating the poll. Please try again.");
    } finally {
      setIsCreating(false);
      if (onCreating) onCreating(false);
    }
  };
  

  return (
    <div className="easy-builder simple">
      <input
        type="text"
        ref={ref} // Attach ref properly here
        className="EasyBuilder-input"
        placeholder="What would you like to ask?"
        value={pollQuestion}
        onChange={(e) => setPollQuestion(e.target.value)}
      />
      <button
        onClick={handleCreatePoll}
        disabled={isCreating}
        className="EasyBuilder-create-button"
      >
        {isCreating ? (
          <img
            src={LoadingIcon}
            alt="Loading..."
            style={{ width: '20px', height: '20px' }}
            className="spinner"
          />
        ) : (
          <>
            Create
            <img
              src={AiIcon}
              alt="AI Icon"
              style={{ width: '20px', height: '20px', marginLeft: '10px' }}
            />
          </>
        )}
      </button>
    </div>
  );
});

// Export EasyBuilder with forwardRef
export default EasyBuilder;
