import React from "react";
import "./Learn.css";
import ShareGuide from '../assets/sharing-guide.jpg';
import ShareGuidePDF from '../assets/sharing-guide.pdf';



function Learn() {
  return (
    <div className="learn-container">
      <div className="learn-grid">
        {/* Video Item */}
        <div className="grid-item aspect-ratio">
          <iframe
            src="https://www.youtube.com/embed/Qjaydhptk8o?si=AelxWyf0Vzi_NB2z"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>

        <div className="grid-item aspect-ratio">
          <iframe
            src="https://www.youtube.com/embed/acWPCFGJUMs?si=PvtjBUKvzdM9b1Xg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>

        {/* PDF Link with Image */}
        <div className="grid-item aspect-ratio">
          <a
            href={ShareGuidePDF}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={ShareGuide}
              alt="Link to PDF"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Learn;
