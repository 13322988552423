import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from './assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const DesktopNav = ({ isLoggedIn, toggleHomeView, isHomeBrands }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="nav-bar-container">
      <nav className="nav-navbar">
        <img
          src={Logo}
          alt="Logo"
          className="nav-home-logo"
          onClick={() => navigate("/")}
        />

{/* <div className="nav-toggle-container">
          <div
            className={`nav-toggle ${isHomeBrands ? "brands" : "creators"}`}
            onClick={() => {
              toggleHomeView();
              setIsMenuOpen(false);
            }}
          >
            <span className="toggle-label">Creators</span>
            <span className="toggle-label">Brands</span>
            <div className="toggle-slider"></div>
          </div>
          </div> */}

        <ul className="nav-links">
          <li>
            <NavLink to="/" end className="nav-link">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/shop" className="nav-link">
              Shop
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" className="nav-link">
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" className="nav-link">
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink to="/faqs" className="nav-link">
              FAQs
            </NavLink>
          </li>
          <li>
            <NavLink to="/team" className="nav-link">
              Team
            </NavLink>
          </li>
          <li>
            <NavLink to="/how-it-works" className="nav-link">
              How It Works
            </NavLink>
          </li>
        </ul>
        <div className="nav-auth">
          {isLoggedIn ? (
            <button
              onClick={() => navigate("/dashboard/main")}
              className="nav-dashboard"
            >
              Go to Dashboard
            </button>
          ) : (
            <>
              <button
                onClick={() => navigate("/signup")}
                className="nav-signup"
              >
                Sign Up
              </button>
              <button
                onClick={() => navigate("/login")}
                className="nav-login"
              >
                Log In
              </button>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

const MobileNav = ({ isLoggedIn, isMenuOpen, toggleMenu, setIsMenuOpen, toggleHomeView, isHomeBrands }) => {
  const navigate = useNavigate();
  return (
    <div className="nav-bar-container">
      <nav className="nav-navbar">
        <img
          src={Logo}
          alt="Logo"
          className="nav-home-logo"
          onClick={() => navigate("/")}
        />
        <button className="hamburger-menu" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </button>
        <ul className={`nav-links ${isMenuOpen ? "open" : ""}`}>
          <li>
            <NavLink
              to="/"
              end
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/shop"
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
            >
              SHOP
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
            >
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/faqs"
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
            >
              FAQs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/team"
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
            >
              Team
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/how-it-works"
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
            >
              How It Works
            </NavLink>
          </li>
          <div className="nav-auth">
            {isLoggedIn ? (
              <button
                onClick={() => {
                  navigate("/dashboard/main");
                  setIsMenuOpen(false);
                }}
                className="nav-dashboard"
              >
                Go to Dashboard
              </button>
            ) : (
              <>
                <button
                  onClick={() => {
                    navigate("/signup");
                    setIsMenuOpen(false);
                  }}
                  className="nav-signup"
                >
                  Sign Up
                </button>
                <button
                  onClick={() => {
                    navigate("/login");
                    setIsMenuOpen(false);
                  }}
                  className="nav-login"
                >
                  Log In
                </button>
              </>
            )}
          </div>
        </ul>
        {/* <div className="nav-toggle-container">
          <div
            className={`nav-toggle ${isHomeBrands ? "brands" : "creators"}`}
            onClick={() => {
              toggleHomeView();
              setIsMenuOpen(false);
            }}
          >
            <span className="toggle-label">Creators</span>
            <span className="toggle-label">Brands</span>
            <div className="toggle-slider"></div>
          </div>
          </div> */}
      </nav>
    </div>
  );
};

const NavSection = ({ isLoggedIn, toggleHomeView, isHomeBrands }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <>
      {isMobile ? (
        <MobileNav
          isLoggedIn={isLoggedIn}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          setIsMenuOpen={setIsMenuOpen}
          toggleHomeView={toggleHomeView} // Pass toggleHomeView to MobileNav
          isHomeBrands={isHomeBrands} // Pass isHomeBrands to MobileNav
        />
      ) : (
        <DesktopNav
          isLoggedIn={isLoggedIn}
          toggleHomeView={toggleHomeView} // Pass toggleHomeView to DesktopNav
          isHomeBrands={isHomeBrands} // Pass isHomeBrands to DesktopNav
        />
      )}
    </>
  );
};

export default NavSection;
