import React from "react";
import PayPalLogo from "../assets/PayPal.png";
import Chip from "../assets/Chip.png";
import "./Billing.css";

function CreditCard({ isConnected = false, name = "Not Connected", totalRevenue = 0, affiliateCommission = 0, className = "" }) {
  const netBalance = totalRevenue / 2 + affiliateCommission;

  return (
    <div className={`credit-card ${isConnected ? "" : "credit-card--disabled"} ${className}`}>
      <div className="credit-card__header">
        <img src={PayPalLogo} alt="PayPal Logo" className="credit-card__paypal-logo" />
      </div>
      <div className="credit-card__content">
        <h3>{isConnected ? name : "Not Connected"}</h3>
        <p>Gross Revenue: ${totalRevenue.toFixed(2)}</p>
        <div className="checkout-divider" />
        <p>Rev Share (50%): -${(totalRevenue / 2).toFixed(2)}</p>
        <p className="net-balance">
          Payout Balance: ${netBalance.toFixed(2)}
        </p>
      </div>
      <div className="credit-card__footer">
        <img src={Chip} alt="Card Chip" className="credit-card__chip" />
      </div>
    </div>
  );
}

export default CreditCard;
