import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, Area, Tooltip, ResponsiveContainer, CartesianGrid } from "recharts";
import "./Revenue.css"; // Reuse the same CSS for a similar "look"


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ 
        backgroundColor: "#191919",  // Fully dark background
        padding: "10px", 
        borderRadius: "5px", 
        border: "2px solid grey", 
        color: "#64d169", 
        fontSize: "14px",
      }}>
        <p style={{ margin: 0, fontWeight: "bold", color: "#64d169" }}>{label}</p>
        <p style={{ margin: 0, color: "#64d169" }}>Earnings: ${payload[0].value.toFixed(2)}</p>
      </div>
    );
  }
  return null;
};


const Revenue = ({ userId }) => {
  const [clicks, setClicks] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [timeFilter, setTimeFilter] = useState("all"); // 'week', 'month', 'all'

  useEffect(() => {
    if (!userId) return;
    setLoading(true);

    const fetchAmazonData = async () => {
      try {
        const response = await fetch(`/api/user/${userId}/refresh-amazon`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch amazon data: ${response.status}`);
        }
        const data = await response.json();
        
        const now = new Date();
        const filterTime = (date) => {
          const d = new Date(date);
          if (timeFilter === "week") {
            return d >= new Date(now.setDate(now.getDate() - 7));
          } else if (timeFilter === "month") {
            return d >= new Date(now.setMonth(now.getMonth() - 1));
          }
          return true;
        };

        const sortedClicks = (data.amazonClicks || [])
  .filter((click) => filterTime(click.clickedAt))
  .sort((a, b) => new Date(b.clickedAt) - new Date(a.clickedAt))
  .map((click) => {
    const parsedClick = { ...click };
    if (!parsedClick.pollQuestion) delete parsedClick.pollQuestion;
    return parsedClick;
});


        setClicks(sortedClicks);

        const parsedPurchases = (data.amazonPurchases || []).map((purchase) => ({
          ...purchase,
          salePrice:
            typeof purchase.salePrice === "object" && purchase.salePrice.$numberDecimal
              ? purchase.salePrice.$numberDecimal
              : purchase.salePrice,
          adFee:
            typeof purchase.adFee === "object" && purchase.adFee.$numberDecimal
              ? purchase.adFee.$numberDecimal
              : purchase.adFee,
        }));

        const sortedPurchases = parsedPurchases
          .filter((purchase) => filterTime(purchase.dateShipped))
          .sort((a, b) => new Date(b.dateShipped) - new Date(a.dateShipped));
        setPurchases(sortedPurchases);

        setLastUpdated(new Date());
      } catch (error) {
        console.error("Error fetching amazon data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAmazonData();
  }, [userId, timeFilter]);

  // Format data for Recharts purchases chart
  const purchasesData = purchases.map((purchase) => ({
    date: new Date(purchase.dateShipped).toLocaleDateString(),
    earnings: parseFloat(purchase.adFee) || 0,
  }));

  return (
    <div className="amazon-clicks-container">
      <div className="clicks-header">
        {lastUpdated && (
          <span className="last-updated">Last updated: {lastUpdated.toLocaleString()}</span>
        )}
      </div>

      {/* Time Filter Dropdown */}
      <div className="filters" style={{ marginBottom: '1rem' }}>
        <select value={timeFilter} onChange={(e) => setTimeFilter(e.target.value)} className="filters-select">
          <option value="week">Last Week</option>
          <option value="month">Last Month</option>
          <option value="all">All Time</option>
        </select>
      </div>

      {/* Purchases Chart in a Card */}
      <div className="purchases-chart-container">
        <div className="purchases-chart">
        <ResponsiveContainer width="100%" height={300}>
  <LineChart data={purchasesData}>
    {/* Step 1: Debug Gradient Rendering */}
    <defs>
      <linearGradient id="colorEarnings" x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor="green" stopOpacity={0.8} />
        <stop offset="95%" stopColor="green" stopOpacity={0} />
      </linearGradient>
    </defs>

    <XAxis dataKey="date" stroke="#aaa" />
    <YAxis stroke="#aaa" />
    <Tooltip content={<CustomTooltip />} />
    <CartesianGrid strokeDasharray="3 3" />

    {/* Step 2: Try a Solid Color First */}
    <Area 
      type="monotone" 
      dataKey="earnings" 
      stroke="#64d169" 
      strokeWidth={2} 
      fill="#64d169" 
      fillOpacity={0.3} 
    />

    {/* Step 3: Once Confirmed, Swap to Gradient */}
    <Line type="monotone" dataKey="earnings" stroke="#64d169" strokeWidth={2} />
  </LineChart>
</ResponsiveContainer>



        </div>
      </div>

      <div className="clicks-grid">
        {/* Left Column - Clicks */}
        <div className="clicks-list">
          <h3>Clicks</h3>
          {loading ? (
            <p>Loading...</p>
          ) : clicks.length === 0 ? (
            <p>No clicks yet.</p>
          ) : (
            <ul>
  {clicks.map((click, index) => (
    <li key={index} className="click-item">
      <span className="click-product">{click.productName}</span>
      <span className="click-price">Price: {click.price}</span>
      <span className="click-time">{new Date(click.clickedAt).toLocaleString()}</span>
      {click.pollQuestion && (
        <span className="click-question">Poll Question: {click.pollQuestion}</span>
      )}
    </li>
  ))}
</ul>


          )}
        </div>

        {/* Right Column - Purchases */}
        <div className="purchases-list">
          <h3>Purchases</h3>
          {loading ? (
            <p>Loading...</p>
          ) : purchases.length === 0 ? (
            <p>No purchases yet.</p>
          ) : (
            <ul>
              {purchases.map((purchase, index) => (
                <li key={index} className="purchase-item">
                  <span className="purchase-product">{purchase.name}</span>
                  <span className="purchase-price">
                    Sale: {purchase.salePrice}
                  </span>
                  <span className="purchase-adfee">
                    Your Cut: {purchase.adFee}
                  </span>
                  <span className="purchase-items">
                    Items Shipped: {purchase.itemsShipped}
                  </span>
                  <span className="click-time">
                    {new Date(purchase.dateShipped).toLocaleString()}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      
    </div>
  );
};

export default Revenue;
