import React from 'react';
import './Privacy.css';
import DesktopBG from '../assets/desktop-bg-login.jpg';
import MobileBG from '../assets/mobile-bg-login.jpg';

const Terms = () => {
    const isDesktop = window.innerWidth >= 768;

    const backgroundStyle = {
        backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
    };

    return (
        <div className="TermsPage-container">
            <h1 className="TermsPage-heading">Terms of Service</h1>
            
            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">OPINARI Technologies Inc.</h2>
                <p className="TermsPage-paragraph">Last Updated: February 2025</p>
                <p className="TermsPage-paragraph">
                    These Terms of Service ("Terms") govern your use of the website located at 
                    <a href="https://www.opinari.io" target="_blank"> https://www.opinari.io</a> ("Website") operated by OPINARI Technologies Inc. ("we", "our", "us").
                </p>
                <p className="TermsPage-paragraph">
                    Please read these Terms carefully before accessing or using our Website. By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not access or use the Website.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">1. Use of the Website</h2>
                <p className="TermsPage-paragraph">
                    You agree to use the Website in accordance with all applicable local, state, and international laws and regulations. You may only use the Website for lawful purposes and must not engage in any activities that disrupt or interfere with the functioning of the Website.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">2. Account Registration</h2>
                <p className="TermsPage-paragraph">
                    To access certain features of the Website, you may be required to create an account. By creating an account, you agree to provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">3. User Conduct</h2>
                <p className="TermsPage-paragraph">You agree not to:</p>
                <ul className="TermsPage-list">
                    <li>Violate any applicable laws or regulations</li>
                    <li>Post, upload, or transmit any content that is illegal, defamatory, obscene, or offensive</li>
                    <li>Interfere with or disrupt the security of the Website or servers</li>
                    <li>Attempt to gain unauthorized access to other user accounts or the Website's infrastructure</li>
                    <li>Use the Website for any commercial purpose without our prior written consent</li>
                </ul>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">4. Intellectual Property</h2>
                <p className="TermsPage-paragraph">
                    All content, features, and functionality on the Website, including but not limited to text, graphics, logos, images, audio clips, and software, are the property of OPINARI Technologies Inc. or its licensors and are protected by copyright, trademark, and other intellectual property laws.
                </p>
                <p className="TermsPage-paragraph">
                    You may not use, reproduce, modify, or distribute any content from the Website without prior written permission from us, except for personal, non-commercial purposes.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">5. Products and Services</h2>
                <p className="TermsPage-paragraph">
                    If you purchase any products or services through the Website, you agree to provide accurate payment and billing information. All sales are subject to availability, and we reserve the right to refuse or cancel orders for any reason.
                </p>
                <p className="TermsPage-paragraph">
                    We make no warranties regarding the availability or accuracy of the products or services sold on the Website.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">6. Third-Party Links</h2>
                <p className="TermsPage-paragraph">
                    The Website may contain links to third-party websites or services that are not owned or controlled by OPINARI Technologies Inc. We are not responsible for the content, privacy policies, or practices of any third-party websites. By using the Website, you acknowledge and agree that we are not liable for any damages or losses caused by your use of third-party websites.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">7. Disclaimers and Limitation of Liability</h2>
                <p className="TermsPage-paragraph">
                    The Website and its contents are provided "as is" without any warranties or representations, express or implied, including but not limited to the accuracy, completeness, or reliability of the Website.
                </p>
                <p className="TermsPage-paragraph">
                    To the fullest extent permitted by law, OPINARI Technologies Inc. will not be liable for any damages, losses, or expenses arising out of or in connection with your use of the Website, including but not limited to direct, indirect, incidental, or consequential damages.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">8. Indemnification</h2>
                <p className="TermsPage-paragraph">
                    You agree to indemnify and hold harmless OPINARI Technologies Inc., its affiliates, employees, agents, and contractors from any claims, damages, or expenses (including legal fees) arising from your violation of these Terms or your use of the Website.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">9. Termination</h2>
                <p className="TermsPage-paragraph">
                    We reserve the right to suspend or terminate your access to the Website at our sole discretion, without notice, for any violation of these Terms.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">10. Governing Law</h2>
                <p className="TermsPage-paragraph">
                    These Terms are governed by and construed in accordance with the laws of Ontario, Canada, without regard to its conflict of law principles. Any legal action or proceeding related to these Terms must be brought in the courts located in Toronto, Ontario.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">11. Changes to These Terms</h2>
                <p className="TermsPage-paragraph">
                    We may update or modify these Terms at any time by posting the updated version on this page. Any changes will be effective immediately upon posting. It is your responsibility to review these Terms periodically to stay informed about any changes.
                </p>
            </section>

            <section className="TermsPage-section">
                <h2 className="TermsPage-subheading">12. Contact Us</h2>
                <p className="TermsPage-paragraph">
                    If you have any questions or concerns about these Terms of Service, please contact us at:
                </p>
                <p className="TermsPage-paragraph">
                    <strong>OPINARI Technologies Inc.</strong><br />
                    Email: <a href="mailto:contact@opinari.io">contact@opinari.io</a>
                </p>
            </section>
        </div>
    );
};

export default Terms;
