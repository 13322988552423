import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import logger from './logger';

const QRCodeGeneratorStorefront = ({ userId, username }) => {
  const downloadQRCode = () => {
    const canvas = document.getElementById(`qr-code-canvas-${userId}`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${username}-storefront.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div style={{ display: 'inline-grid' }}>
      <QRCodeCanvas
        id={`qr-code-canvas-${userId}`}
        value={`${window.location.origin}/storefront/${username}`}
        size={512}
        bgColor={"#ffffff"}
        fgColor={"#202021"}
        level={"L"}
        includeMargin={false}
        imageSettings={{
          src: require("./qr-icon.png"),
          height: 100,
          width: 100,
          excavate: true,
        }}
        style={{
          height: '200px',
          width: '200px',
          marginTop: '15px',
          borderRadius: '15px',
          padding: '15px',
          boxSizing: 'content-box',
          background: 'white',
          marginBottom: '10px'
        }}
      />
    </div>
  );
};

export default QRCodeGeneratorStorefront;
