import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './MainDashboard.css';
import MyPolls from './MyPolls';
import CreatePoll from './CreatePoll';
import ProfileIcon from './ProfileIcon';
import logger from './logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Contact from './Contact';
import LiveDashboard from './LiveDashboard';
import PollIcon from '../assets/poll-icon.png';
import LiveIcon from '../assets/live-icon.png';
import RevIcon from '../assets/revenue-icon.png';
import BillingIcon from '../assets/billing-icon.png';
import SettingsIcon from '../assets/settings-icon.png';
import SupportIcon from '../assets/support-icon.png';
import ReportIcon from '../assets/report-icon.png';
import AffiliateIcon from '../assets/affiliate-icon.png';
import LearnIcon from '../assets/learn-icon.png';
import StoreIcon from '../assets/store-icon.png';

import Tutorial from "./Tutorial"; // Import at the top
import Billing from './Billing';
import Apply from './Apply';
import { useLocation } from 'react-router-dom';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Revenue from "./Revenue"; // Add this at the top
import Learn from "./Learn"; // Add this at the top
import ProfileSettings from "./ProfileSettings"; // Add this at the top
import Report from "./Report"; // Add this at the top
import Reports from './Reports'; // Import the new Reports component
import Affiliate from './Affiliate'; // Import the new Reports component











const MainDashboard = ({
    isLoggedIn,
    userId,
    isProfileView,
    userEmail,
    subscriptionStatus,
    username,
    toggleDashboard,
    name,
    userHash
}) => {
    const [activeTab, setActiveTab] = useState('My Polls');
    const [showCreatePoll, setShowCreatePoll] = useState(false);
    const [showApply, setShowApply] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [totalRevenue, setTotalRevenue] = useState(0);
    const navigate = useNavigate();
    const [pollSuccessData, setPollSuccessData] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [pollIdForReport, setPollIdForReport] = useState(null);
    const [currentPollQuestion, setCurrentPollQuestion] = useState('');
    const [isPaypalConnected, setIsPaypalConnected] = useState(true); // Default to true
    const [affiliateCommission, setAffiliateCommission] = useState(0);
    const [showTutorial, setShowTutorial] = useState(false);



    const location = useLocation();

    console.log ({subscriptionStatus}) 


    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
        window.location.reload(); // Optional: Refresh the page
      };
    
      const toggleDropdown = () => {
        setShowDropdown((prev) => !prev);
      };

       // Callback to update PayPal connection status
  const updatePaypalConnection = (status) => {
    setIsPaypalConnected(status);
  };
    

      useEffect(() => {
        const fetchPaypalStatus = async () => {
          try {
            const response = await fetch(`/api/user/${userId}/revenue`);
            const result = await response.json();
      
            if (response.ok && result.paypalEmail) {
              setIsPaypalConnected(true);
            } else {
              setIsPaypalConnected(false);
            }
          } catch (error) {
            console.error('Error checking PayPal status:', error);
            setIsPaypalConnected(false); // Default to not connected on error
          }
        };
      
        if (userId) fetchPaypalStatus();
      }, [userId]);



      useEffect(() => {
        const fetchUserData = async () => {
          try {
            const response = await fetch(`/auth/users/${userId}`);
            const data = await response.json();
      
            if (response.ok) {
              setShowTutorial(!data.hasSeenTutorial); // Show tutorial if false
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        };
      
        if (userId) fetchUserData();
      }, [userId]);
      
      


      // Function to handle report creation click
const handleCreateReportClick = (pollId) => {
    setPollIdForReport(pollId); // Store the pollId for the report
    setActiveTab('Reports'); // Keep the tab in the "Reports" state
    navigate(`/dashboard?openTab=SpecificReport`); // Optionally navigate with query param
};
    

// Add this useEffect to detect the "openTab" parameter
useEffect(() => {
  const queryParams = new URLSearchParams(location.search);
  const openTab = queryParams.get('openTab');
  if (openTab) {
    setActiveTab(openTab); // Dynamically set the active tab based on the query parameter
    if (openTab === 'Create Poll') {
      setShowCreatePoll(true); // Ensure the "Create Poll" state is properly toggled
      setShowApply(false);
    } else {
      setShowCreatePoll(false);
    }
  }
}, [location.search]);


    const handleCreatePoll = (data) => {
        setActiveTab('My Polls');
        setShowCreatePoll(false);
        setPollSuccessData(data);
    };

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login'); // Redirect to login page if not logged in
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
      if (!userId) {
          logger.error('User ID is null or undefined');
          return;
      }
  
      const fetchUserData = async () => {
          try {
              const response = await fetch(`/auth/user/${userId}`);
              const data = await response.json();
              setProfileImageUrl(data.profileImageUrl || '');
  
              if (subscriptionStatus === 'Creator') {
                  const revenueResponse = await fetch(`/api/user/${userId}/poll-revenue`);
                  if (revenueResponse.ok) {
                      const revenueData = await revenueResponse.json();
                      const totalRevenue = revenueData.totalRevenue ? parseFloat(revenueData.totalRevenue) : 0;
                      setTotalRevenue(totalRevenue);
                  } else {
                      logger.error('Failed to fetch aggregated poll revenue');
                  }
  
                  // Fetch affiliate commission
                  const affiliateResponse = await fetch(`/api/affiliate-stats/${userId}`);
                  if (affiliateResponse.ok) {
                    const affiliateData = await affiliateResponse.json();
                    setAffiliateCommission(parseFloat(affiliateData.totalAffiliateCommission) || 0);
                }
                 else {
                      logger.error('Failed to fetch affiliate commission');
                  }
              }
          } catch (error) {
              logger.error('Error fetching user profile or revenue:', error);
          }
      };
  
      fetchUserData();
  }, [userId, subscriptionStatus]);
  
    

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setShowCreatePoll(false);
        setShowApply(false);
        navigate(location.pathname, { replace: true });

    };

    const handleCreatePollClick = () => {
        setShowCreatePoll(true);
        setShowApply(false);
        setActiveTab('Create Poll'); // Set activeTab to "Create Poll"
    };

    const handleMonetizeClick = () => {
        setShowApply(true);
        setShowCreatePoll(false);
        setActiveTab('');
        setActiveTab('Apply'); // Set activeTab to "Create Poll"

    };


    const getHeadlineText = () => {
        switch (activeTab) {
          case 'My Polls':
            return 'My Polls';
          case 'Revenue':
            return 'Revenue';
          case 'Live Dashboard':
            return 'Live Dashboard';
          case 'Payment':
            return 'Payment';
            case 'Referrals':
            return 'Referrals';
          case 'Support':
            return 'Support';
            case 'Learn':
            return 'Learn';
          case 'Settings':
            return 'Settings';
          case 'Create Poll':
            return 'Create a New Poll';
          case 'Apply':
            return 'Get Monetized';
          case 'Reports':
            return 'Poll Reports';
          case 'SpecificReport':
            return currentPollQuestion
              ? `Poll Reports > ${currentPollQuestion}` // Use the report name here
              : 'Poll Reports > Loading...';
          default:
            return '';
        }
      };
    


    return (
        <>
            {/* Top Bar */}
            <div className="Root-top-bar-creator">
        <img
          src="/Logo-Creator.png"
          alt="Logo"
          className="Root-logo-image"
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer' }}
        />
        <div className="Root-user-info">

        <button
  className="Root-storefront-container"
  onClick={() => navigate(`/storefront/${username}`)}
  aria-label="Storefront"
>
  <img src={StoreIcon} className="Root-referral-icon" />
  <span className="Root-referral-text">My Storefront</span>
</button>


<button
  className="Root-referral-container"
  onClick={() => handleTabClick('Referrals')}  // ✅ Correct function to switch to the Referrals tab
  aria-label="Referrals"
>
  <img src={AffiliateIcon} className="Root-referral-icon" />
  <span className="Root-referral-text">Refer & Earn</span>
</button>



<div className="top-bar-divider">
</div>


          <span className="Root-username">{username}</span>
          

          <div className="Root-profile-container" onClick={toggleDropdown}>
            <ProfileIcon firstName={username} size={40} profileImageUrl={profileImageUrl} />
            <FontAwesomeIcon
              icon={faArrowDown}
              className="Root-dropdown-arrow"
            />
          </div>
        </div>
      </div>
      {showDropdown && (
        <div className="Root-dropdown-menu">
          <button className="Root-logout-button" onClick={handleLogout}>
            Log Out
          </button>
        </div>
      )}

            {/* Dashboard Container */}
            <div className="Root-dashboard-container">
                <div className="Root-sidebar">
                    <nav className="Root-nav-menu">
                    <button
  className="Root-create-poll"
  onClick={handleCreatePollClick}
  aria-label="Create Poll"
>
  <span className="Root-create-poll-text">Create Poll +</span>
  <span className="Root-create-poll-icon">+</span>
</button>

<ul>
    <div className="Root-nav-divider-top" />

    {[{ name: 'My Polls', icon: PollIcon },
      { name: 'Live Dashboard', icon: LiveIcon },
      { name: 'Reports', icon: ReportIcon } // Add Reports to sidebar
    ].map(({ name, icon }) => (
        <li
            key={name}
            className={`Root-nav-item ${
                (activeTab === name || (name === 'Reports' && activeTab === 'SpecificReport')) ? 'Root-active' : ''
            } ${subscriptionStatus === 'Free' && name === 'Revenue' ? 'Root-disabled' : ''}`}
            onClick={() => {
                if (subscriptionStatus === 'Free' && name === 'Revenue') {
                    handleMonetizeClick(); // Redirect to Apply page if disabled
                } else {
                    handleTabClick(name);
                }
            }}
        >
            <img src={icon} alt={`${name} icon`} className="Root-nav-icon" />
            <span>{name}</span>
        </li>
    ))}

    <div className="Root-nav-divider" />

    {[      { name: 'Revenue', icon: RevIcon },

      { name: 'Payment', icon: BillingIcon },
  { name: 'Referrals', icon: AffiliateIcon },

].map(({ name, icon }) => (
  <li
    key={name}
    className={`Root-nav-item ${activeTab === name ? 'Root-active' : ''}`}
    onClick={() => handleTabClick(name)}
  >
    <img src={icon} alt={`${name} icon`} className="Root-nav-icon" />
    <span>
      {name}
      {name === 'Payment' && !isPaypalConnected && (
        <span className="red-dot" />
      )}
    </span>
  </li>
))}


    <div className="Root-nav-divider" />
    
    {[{ name: 'Support', icon: SupportIcon },
    { name: 'Learn', icon: LearnIcon },
    
      { name: 'Settings', icon: SettingsIcon },

  
  

].map(({ name, icon }) => (
  <li
    key={name}
    className={`Root-nav-item ${activeTab === name ? 'Root-active' : ''}`}
    onClick={() => handleTabClick(name)}
  >
    <img src={icon} alt={`${name} icon`} className="Root-nav-icon" />
    <span>
      {name}
      {name === 'Payment' && !isPaypalConnected && (
        <span className="red-dot" />
      )}
    </span>
  </li>
))}
    <div className="Root-nav-divider" />

</ul>
                    </nav>

                    {/* Sidebar Section */}
                    <div className="Root-bottom-section">
                        {subscriptionStatus === 'Free' ? (
                            <>
                                <img src="/monetize.png" alt="Logo" className="Root-monetize-image" />
                                <p className="Root-monetize-paragraph">
                                    Apply now to start making money from people voting on your polls.
                                </p>
                                <button className="Root-monetize-button" onClick={handleMonetizeClick}>
                                    Get Monetized $ <FontAwesomeIcon icon={faArrowRight} className="Root-arrow-icon" />
                                </button>
                            </>
                        ) : (
                          <div className="Root-revenue-section">
                          <div className="Root-revenue-amount">
  ${(totalRevenue * 0.7 + affiliateCommission).toFixed(2)}
</div>

                          <div className="Root-revenue-period">Payout Balance</div>
                        </div>
                        
                        )}
                    </div>
                </div>

                <div className="Root-main-content">
    <div className="Root-headline">{getHeadlineText()}</div>
    {showTutorial && <Tutorial userHash={userHash} userId={userId} onClose={() => setShowTutorial(false)} />}

    {showCreatePoll ? (
    <CreatePoll
        isLoggedIn={isLoggedIn}
        userId={userId}
        username={username}
        onCreate={handleCreatePoll}
        subscriptionStatus={subscriptionStatus}
    />
) : showApply ? (
    <Apply userId={userId} userEmail={userEmail} />
) : activeTab === 'My Polls' ? (
    <MyPolls
        userId={userId}
        isLoggedIn={isLoggedIn}
        username={username}
        isProfileView={isProfileView}
        pollSuccessData={pollSuccessData}
        onCloseSuccessModal={() => setPollSuccessData(null)}
        onCreateReportClick={handleCreateReportClick} // Pass to MyPolls
    />
) : activeTab === 'Support' ? (
    <Contact />
) : activeTab === 'Live Dashboard' ? (
    <LiveDashboard
        userId={userId}
        username={username}
        userEmail={null}
    />
) : activeTab === 'Payment' ? (
    <Billing userId={userId} userEmail={userEmail} name={name} updatePaypalConnection={updatePaypalConnection} // Pass callback to Billing
    />
) : activeTab === 'Revenue' ? (
    <Revenue userId={userId} />
) : null} {/* Remove the <p> fallback */}

{activeTab === 'Reports' ? (
  <Reports
    userId={userId}
    onPollSelect={(reportId) => {
      setPollIdForReport(reportId); // Set the reportId here
      setActiveTab('SpecificReport'); // Switch to "SpecificReport"
    }}
  />
) : activeTab === 'SpecificReport' ? (
<Report
  reportId={pollIdForReport}
  userId={userId}
  onReportNameUpdate={(name) => setCurrentPollQuestion(name)} // Update report name here
/>
) : null}
{activeTab === 'Referrals' ? (
  <Affiliate userId={userId} username={username} userHash={userHash} />
) : null}


{activeTab === 'Learn' ? (
  <Learn/>
) : null}




    {activeTab === 'Settings' ? (
        <ProfileSettings
            handleLogout={handleLogout} userId={userId} username={username} userHash={userHash}
        />
    ) : (
        <p></p>
    )}
</div>

            </div>
        </>
    );
};

export default MainDashboard;
