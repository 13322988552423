import React from "react";
import "./Ad.css"; // Reuse Ad.css for styling consistency

const ConfidenceData = ({ confidenceLevel, voteCount = 0, borderColor }) => {
  return (
    <div
      className="ad-container" // Reusing the same styles
      style={{
        border: `2px solid ${borderColor || "gray"}`, // Border styling
      }}
    >
      <div className="ad-details">
        <div className="ad-name">{confidenceLevel || "Confidence Level"}</div>
        <div className="ad-price">
          {voteCount} {voteCount === 1 ? "Vote" : "Votes"}
        </div>
      </div>
    </div>
  );
};

export default ConfidenceData;
