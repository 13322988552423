import React, { useEffect, useState } from "react";
import "./YoutubeComments.css";
import { User } from "lucide-react";

// Sample data
const fakeComments = [
  "The Sony WH-1000XM5 are amazing! The noise cancellation is next level.",
  "I had the Bose QuietComfort 45, but I switched to the Sony WH-1000XM4. The sound quality is just better in my opinion.",
  "Apple AirPods Max are overpriced for what they offer. The Sony WH-1000XM4 is a much better deal!",
  "Jabra Elite 85h are so underrated! The battery life is insane, and they sound great.",
  "I tried the Bose Noise Cancelling Headphones 700, but they felt too tight after long use. Anyone else have this issue?",
  "Sony WH-CH510 is decent for the price, but I wish it had better bass.",
  "Sennheiser Momentum 4 Wireless has such a premium feel. Easily the best-looking headphones I’ve owned.",
  "I was torn between the Apple AirPods Pro and Bose QuietComfort 45, but I went with Bose for the better noise cancellation.",
  "More people should talk about the Sennheiser HD 450BT! Such a solid choice for the price.",
  "Sony WH-1000XM4 still holds up even after all these years. The best headphones I’ve ever owned!",
  "Jabra Elite 45h is a great budget option, but I wish they had better noise cancellation.",
  "Apple AirPods are great for convenience, but honestly, the sound quality doesn’t compare to over-ear headphones.",
  "The Sony WH-1000XM5 is nice, but I actually prefer the WH-1000XM4. They just fit better and are cheaper.",
  "Bose QuietComfort 45 is my go-to for travel. Super comfortable, and the noise cancellation is perfect for flights.",
  "I returned my Apple AirPods Max. Too heavy and overpriced for what they offer.",
  "Sennheiser Momentum 4 Wireless sounds fantastic, but I wish they had a stronger bass punch.",
  "The Jabra Elite 85h surprised me! Didn't expect them to be this good for the price.",
  "AirPods Pro are perfect for everyday use, but I still prefer my Sony WH-1000XM4 for music.",
  "Sony WH-CH510 is okay for casual listening, but the lack of ANC is a dealbreaker for me.",
  "Bose Noise Cancelling Headphones 700 look cool, but I prefer the sound of the QuietComfort 45.",
  "The Apple AirPods Max is a flex, but are they really worth the price? I’m not convinced.",
  "Jabra Elite 45h has the best battery life I’ve seen on headphones! Lasts forever!",
  "Sennheiser HD 450BT is solid, but the build quality feels a bit cheap.",
  "Sony WH-1000XM5 is amazing, but the touch controls can be a bit annoying sometimes.",
  "Apple AirPods Pro have great ANC for earbuds, but over-ear headphones are still king!",
];

const usernames = [
  "CoolGamer42",
  "EpicEditor99",
  "VlogMaster",
  "TechNerd22",
  "CinematicPro",
  "TravelFreak",
  "MusicLover",
  "RandomUser89",
  "HyperVibes",
  "CreativeSoul",
  "ReactionKing",
  "TutoGeek",
  "LifeHacksOnly",
  "SkitsGuy",
  "FoodieFan",
];

const getRandomComment = () => {
  const randomUsername = usernames[Math.floor(Math.random() * usernames.length)];
  const randomComment = fakeComments[Math.floor(Math.random() * fakeComments.length)];
  return {
    username: randomUsername,
    text: randomComment,
  };
};

const YoutubeComments = ({ showComments }) => {
  // Create 10 initial comments
  const [comments, setComments] = useState(() => {
    return Array.from({ length: 8 }, getRandomComment);
  });

  useEffect(() => {
    if (!showComments) return;

    let index = 0;
    const interval = setInterval(() => {
      const randomUsername = usernames[Math.floor(Math.random() * usernames.length)];
      const newComment = {
        username: randomUsername,
        text: fakeComments[index],
      };

      // Shift out the oldest, push the newest to maintain a stable length
      setComments((prev) => {
        const updated = [...prev];
        updated.shift();      // remove first
        updated.push(newComment);
        return updated;
      });

      index = (index + 1) % fakeComments.length;
    }, 300); // Updated to 1-second interval

    return () => clearInterval(interval);
  }, [showComments]);

  return (
    <div className="youtube-comments-container">
      {comments.map((comment, i) => (
        <div key={i} className="youtube-comment">
          <div className="comment-user">
            <User className="user-icon" size={20} />
            <span className="username">{comment.username}</span>
          </div>
          <p className="comment-text">{comment.text}</p>
        </div>
      ))}
    </div>
  );
};

export default YoutubeComments;
