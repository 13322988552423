import React, { useState } from "react";
import "./BrandAd.css";

const BrandAd = ({
  id,
  image,
  link,
  borderColor,
  voteCount,
  isTopVoted,
  creatorUsername,
  pollId,
  isOwner,
  pollQuestion,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newAmazonUrl, setNewAmazonUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // ✅ Extract ASIN from Amazon URL (frontend validation)
  const extractAsin = (amazonUrl) => {
    try {
      const url = new URL(amazonUrl);
      if (!url.hostname.includes("amazon.com")) return null;
      const parts = url.pathname.split("/");
      const dpIndex = parts.indexOf("dp");
      if (dpIndex !== -1 && parts[dpIndex + 1]) return parts[dpIndex + 1];
      const productIndex = parts.indexOf("product");
      if (productIndex !== -1 && parts[productIndex + 1]) return parts[productIndex + 1];
      return null;
    } catch (error) {
      return null;
    }
  };

  // ✅ Handle Click Event (prevents accidental edit button clicks)
  const handleClick = async (e) => {
    if (!link || !creatorUsername) return;

    if (e.target.closest(".brand-edit-btn") || e.target.closest(".brand-edit-modal")) {
      e.preventDefault();
      return;
    }

    const asin = extractAsin(link);
    if (!asin) {
      console.warn("No ASIN found in link:", link);
      return;
    }

    // ✅ Fire Google Ads conversion event
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-16710986710/v_pNCJnc5qwaENbXtaA-",
        event_callback: function () {
          window.location.href = link; // Redirect after tracking
        },
      });

      e.preventDefault(); // Prevent default click while tracking

      // Ensure redirection even if tracking fails
      setTimeout(() => {
        window.location.href = link;
      }, 300);
    }

    try {
      // ✅ Log click in your backend
      await fetch(`${process.env.REACT_APP_API_URL}/api/track-amazon-click`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amazonUrl: link,
          creatorUsername,
          pollId,
          confidenceLinkId: id,
          asin,
          pollQuestion,
        }),
      });
      console.log("Amazon click logged successfully");
    } catch (error) {
      console.error("Error logging Amazon click:", error);
    }
  };

  // ✅ Handle update submission (only checks front-end validation)
  const handleUpdate = async () => {
    setError("");

    const asin = extractAsin(newAmazonUrl);
    if (!asin) {
      setError("Invalid Amazon product URL.");
      return;
    }

    setLoading(true);

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/single-product`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amazonUrl: newAmazonUrl,
          creatorUsername,
          pollId,
          confidenceLinkId: id,
          asin,
        }),
      });

      setNewAmazonUrl("");
    } catch (error) {
      console.error("Network error:", error);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setIsEditing(false);
      }, 1000);
    }
  };

  return (
    <>
      <a
        href={link || "#"}
        target="_blank"
        rel="noopener noreferrer"
        className="brand-ad-container"
        style={{ border: `2px solid ${borderColor || "gray"}` }}
        onClick={handleClick}
      >
        <div className="brand-ad-image">
          <img
            src={image || "https://bluejuicecomics.com/wp-content/uploads/2017/02/Amazon-icon-white.png"}
            alt="Product"
          />
        </div>

      </a>

      
    </>
  );
};

export default BrandAd;
