import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Poll from './Poll';
import Onboarding from './Onboarding';
import "./Storefront.css";
import StorefrontShareModal from './StorefrontShareModal';
import { Share, Ellipsis } from "lucide-react";
import Ad from './LargeAd'; // Make sure this is imported




const Storefront = () => {
  const { username } = useParams();
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [bio, setUserBio] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [error, setError] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [ads, setAds] = useState([]);


  

  const handleShareClick = () => {
    setIsShareModalOpen(true);
  };
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`/auth/user/by-username/${username}`);
        if (!response.ok) {
          throw new Error('User not found');
        }
        const data = await response.json();
        setUserId(data.userId);
        setUserEmail(data.email);
        setProfileImageUrl(data.profileImageUrl);
        setUserBio(data.bio);

      } catch (err) {
        console.error(err);
        setError('User not found.');
      }
    };

    fetchUserData();
  }, [username]);

  const [polls, setPolls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState('createdAtDesc');
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const loaderRef = useRef(null);
  const [observerEnabled, setObserverEnabled] = useState(false);

  useEffect(() => {
    if (userId) {
      setPolls([]);
      setPage(0);
      setHasMore(true);
      setObserverEnabled(false);
      fetchPolls(0, true);
    }
  }, [sort, userId]);

  const fetchPolls = async (pageNumber, isFirstPage = false) => {
    setIsLoading(true);
    try {
      const limit = 10;
      const skip = pageNumber * limit;
      const url = `/polls?creator=${userId}&pollType=Creator&sort=${sort}&limit=${limit}&skip=${skip}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch polls');
      }
      const data = await response.json();
      const newPolls = data.polls || [];

      if (newPolls.length < limit) {
        setHasMore(false);
      }
      setPolls((prev) => (isFirstPage ? newPolls : [...prev, ...newPolls]));

      if (isFirstPage) {
        setObserverEnabled(true);
      }
    } catch (err) {
      console.error('Error fetching user polls:', err);
    } finally {
      setIsLoading(false);
    }
  };



  const getRandomColor = () => {
    const colors = ["#FF6B6B", "#6BCB77", "#4D96FF", "#F3A712", "#845EC2"];
    return colors[Math.floor(Math.random() * colors.length)];
  };
  
  


  const handleCloseModal = () => {
    setIsShareModalOpen(false);
  };
  

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !isLoading && observerEnabled) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [hasMore, isLoading, observerEnabled]
  );

  useEffect(() => {
    if (page >= 0 && userId) {
      fetchPolls(page, page === 0);
    }
  }, [page, userId]);

  useEffect(() => {
    if (observerEnabled) {
      const observer = new IntersectionObserver(handleObserver, {
        root: null,
        rootMargin: '200px',
        threshold: 0.1,
      });
      if (loaderRef.current) {
        observer.observe(loaderRef.current);
      }
      return () => {
        if (loaderRef.current) {
          observer.unobserve(loaderRef.current);
        }
      };
    }
  }, [handleObserver, observerEnabled, hasMore]);


  useEffect(() => {
    if (userId) {
      fetchTopVotedProducts(userId);
    }
  }, [userId]);

  const fetchTopVotedProducts = async (userId) => {
    try {
      console.log(`Fetching top-voted products for userId: ${userId}`);
  
      const response = await fetch(`/top-voted-products/${userId}`);
      
      if (!response.ok) {
        console.error(`Failed to fetch: ${response.status} ${response.statusText}`);
        return;
      }
      
      const data = await response.json();
      
      console.log('Received data from backend:', data);
      
      if (Array.isArray(data.products)) {
        console.log(`Number of products received: ${data.products.length}`);
        setAds(data.products); // Your existing state setting
      } else {
        console.warn('Received data does not contain a products array. Data:', data);
      }
      
    } catch (error) {
      console.error('Failed to fetch top voted products:', error);
    }
  };
  


 



  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="storefront-polls-container">
      <div>
        {/* Display Profile Image & Username */}
        <div style={{ 
    textAlign: 'left', 
    marginBottom: '1rem', 
    backgroundColor: '#1a1a1a', 
    borderRadius: '10px', 
    padding: '20px' 
}}>
          {profileImageUrl ? (
  <img
    src={profileImageUrl}
    alt={`${username}'s Profile`}
    style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '0.5rem' }}
  />
) : (
  <div
    style={{
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      backgroundColor: getRandomColor(),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      fontSize: '36px',
      fontWeight: '500',
      marginBottom: '0.5rem'
    }}
  >
    {username?.charAt(0).toUpperCase() || "?"}
  </div>
)}


<button className="storefront-share-button" 
          onClick={handleShareClick}>
            <Share size={26} />
            </button>
          <h1>{username}</h1>
          <h2 style={{ fontSize: '20px', marginBottom: '0px' }}>{bio}</h2>
          

          </div>


          <div style={{ 
    textAlign: 'left', 
    marginBottom: '1rem', 
    backgroundColor: '#1a1a1a', 
    borderRadius: '10px', 
    padding: '20px' 
}}>
         


          <h2 style={{ fontSize: '20px', marginBottom: '0px' }}>Top voted products 🏆</h2>
          

          <div className="ad-grid">
  {ads.map(ad => (
    <Ad
      key={ad.id}
      id={ad.id}
      image={ad.image}
      name={ad.name}
      price={ad.price}
      link={ad.url} // Use 'url' not 'link'
      borderColor="gold"
      voteCount={ad.voteCount}
      isTopVoted={false}
      creatorUsername={username}
      onUpdate={() => {}}
      pollId={null}
      isOwner={false}
      pollQuestion={null}
    />
  ))}
</div>



          </div>



          {isShareModalOpen && (
  <StorefrontShareModal
    storefrontLink={`${window.location.origin}/storefront/${username}`}
    onClose={handleCloseModal}
    userId={userId}
    username={username}
  />
)}



        {/* Sorting dropdown */}
        {polls.length > 0 && (
          <div className="Sorting-dropdown filters" style={{ marginBottom: '1rem' }}>
            <select
              id="sort"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              className="filters-select"
            >
              <option value="createdAtDesc">Most Recent</option>
              <option value="votesDesc">Most Votes</option>
              <option value="votesAsc">Least Votes</option>
            </select>
          </div>
        )}

        {/* Render polls with the same styling as MyPolls */}
        {polls.length > 0 && (
          <div className="Root-grid-container">
            {polls.map((poll) => (
              <div key={poll._id} className="Poll-item" style={{ position: 'relative' }}>
                {!poll.approved && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgb(15 15 15 / 55%)',
                      zIndex: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: '24px',
                      borderRadius: '10px',
                    }}
                  >
                    Poll Under Review
                  </div>
                )}
                <Poll
                           pollId={poll._id}
                           question={poll.question}
                           labels={poll.labels}
                           logo={poll.logo || '/logo-poll.png'}
                           logoFill={poll.logoFill}
                           votes={poll.votes}
                           hash={poll.hash}
                           creatorUsername={poll.creatorUsername}
                           creatorId={poll.creator}
                           isLoggedIn={false} // Public view
                           userId={userId}
                           username={username}
                           isProfileView={true}
                           voteAuthenticationMethod={poll.voteAuthenticationMethod}
                           confidenceLabels={poll.confidenceLabels}
                           imageAction={poll.imageAction}
                           roundedCorners={poll.roundedCorners}
                           startDate={poll.startDate}
                           endDate={poll.endDate}
                           scheduledPoll={poll.scheduledPoll}
                           userEmail={userEmail}
                           totalVotes={poll.totalVotes}
                           isTemporary={poll.isTemporary}
                           isLegacyPoll={poll.isLegacyPoll}
                           approved={poll.approved}
                           confidenceLinks={poll.confidenceLinks}
                           isAdvertising={poll.isAdvertising}
                           isFetchingProducts={poll.isFetchingProducts}
                           category={poll.category}
                           pollType={poll.pollType}
                           ads={poll.ads}
                           isFeed={true} // ✅ Ensuring this is included

                />
              </div>
            ))}
          </div>
        )}

        {isLoading && <p style={{ textAlign: 'center' }}>Loading polls...</p>}
        {!isLoading && !hasMore && polls.length === 0 && <Onboarding username={username} />}
        <div ref={loaderRef} style={{ height: '1px', marginTop: '1rem' }} />
      </div>
    </div>
  );
};

export default Storefront;
