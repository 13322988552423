import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import {  generateSvgBackgroundDataUrl } from "./SvgBackground"; // Adjust the path as needed
import Flag from 'react-flagpack';
import 'react-flagpack/dist/style.css';
import HomePoll from './HomePoll';
import HomePollStats from './HomePollStats';
import YoutubeComments from './YoutubeComments';
import BadForm from "./BadForm";
import EasyBuilder from "./EasyBuilder";
import HomeShare from "./HomeShare";
import Product1 from '../assets/product1.png';
import Product2 from '../assets/product2.png';
import Product3 from '../assets/product3.png';
import Commision from '../assets/commission.png';
import CreditCard from "./CreditCard";
import { ShoppingBag, HandCoins, Cpu, Zap } from "lucide-react";
import Icon from '../assets/logo-icon.png';
import Builder from '../assets/Builder.png';
import Share from '../assets/Share.png';
import Partners from '../assets/Partners.png';
import Socials from '../assets/Socials.png';
import AmazonLogo from '../assets/AmazonLogo.png';
import { Youtube, Instagram, Twitter, Linkedin } from "lucide-react";
import PollShareModal from "./PollShareModal"; // Ensure correct path














const LIVE_POLL_HASH = "89256d47b8ca"; // Default poll

const rotatingWords = [
  "Followers",
  "Subscribers",
  "Fans",
  "Readers",
  "Listeners"
];
const Home = () => {
  const [poll, setPoll] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCreatingPoll, setIsCreatingPoll] = useState(false); // NEW state for poll creation process

  const [fetchedVotes, setFetchedVotes] = useState([]); // Static list of 25 votes
  const [visibleVotes, setVisibleVotes] = useState([]); // Dynamic rotating list
  const [dotColor, setDotColor] = useState("#222222"); // Default dot color
  const [bgDataUrl, setBgDataUrl] = useState(""); // Background SVG
  const [animationKey, setAnimationKey] = useState(0); // Used to force animation restart
  const [showStats, setShowStats] = useState(false); // Controls visibility
  const statsRef = useRef(null); // Reference for the stats container
  const [currentText, setCurrentText] = useState(rotatingWords[0]);
  const [index, setIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState("");
  const [showComments, setShowComments] = useState(true);
  const commentsRef = useRef(null);
  const [showShare, setShowShare] = useState(false);
const [pollLink, setPollLink] = useState("");
const [activeIndex, setActiveIndex] = useState(null);
const inputRef = useRef(null); // Reference to input field
const topRef = useRef(null); // Ref to the top of the page
// At the top of your component, before the return:
const pollRef = useRef(null);

const [selectedPollHash, setSelectedPollHash] = useState(LIVE_POLL_HASH);
const [statsPoll, setStatsPoll] = useState(null);
const [showShareModal, setShowShareModal] = useState(false);

useEffect(() => {
  let isMounted = true;

  const fetchPollStats = async () => {
    try {
      const response = await fetch(`/polls/${selectedPollHash}`);
      if (!response.ok) throw new Error("Failed to fetch poll stats");
      const data = await response.json();
      if (isMounted && data.poll) {
        setStatsPoll(data.poll);
      }
    } catch (error) {
      console.error("Error fetching poll stats:", error);
    }
  };

  // Fetch immediately, then poll every 1 second
  fetchPollStats();
  const intervalId = setInterval(fetchPollStats, 1000);

  return () => {
    isMounted = false;
    clearInterval(intervalId);
  };
}, [selectedPollHash]);

const affiliateMarketingSectionRef = useRef(null); // Reference for scrolling

const isMobile = /Mobi|Android/i.test(navigator.userAgent);


const openShareModal = () => {
  setShowShareModal(true);
};


const products = [
  { src: Product2, price: 56.00 },
  { src: Product2, price: 42.99 },
  { src: Product3, price: 78.50 },
  { src: Product3, price: 34.75 },
  { src: Product3, price: 89.99 },
];

const [totalRevenue, setTotalRevenue] = useState(0.00); // Initial revenue
const [isCardPopping, setIsCardPopping] = useState(false); // State to trigger animation

const productList = products.concat(products);

const handleMouseEnter = (index, price) => {
  setActiveIndex(index);
  
  // Increase revenue dynamically when a product is hovered
  setTotalRevenue((prevRevenue) => {
    const newRevenue = prevRevenue + price;
    triggerCardPop(); // Animate card when revenue updates
    return newRevenue;
  });
};

const scrollToAffiliateSection = () => {
  if (affiliateMarketingSectionRef.current) {
    affiliateMarketingSectionRef.current.scrollIntoView({ behavior: "smooth" });
  }
};

useEffect(() => {
  window.scrollToAffiliateSection = scrollToAffiliateSection;
}, []);



const handleScrollToTopAndFocus = () => {
  window.scrollTo({ top: 0, behavior: "smooth" }); // Smoothly scroll to top
  setTimeout(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus on input field after scrolling
    }
  }, 500); // Delay focus slightly to ensure smooth experience
};




const handleAnimationEnd = (index) => {
  if (activeIndex === index) {
    setActiveIndex(null);
  }
};

const triggerCardPop = () => {
  setIsCardPopping(true);
  setTimeout(() => setIsCardPopping(false), 300); // Remove class after animation
};
  // Callback from EasyBuilder when a poll is created
  const handlePollCreated = async (newHash) => {
    console.log("New poll created with hash:", newHash);
    setSelectedPollHash(newHash);
    const generatedPollLink = `${window.location.origin}/poll/${newHash}`;
    setPollLink(generatedPollLink);
    setShowShare(true);
  
    // Send Slack alert for demo poll creation
    try {
      const response = await fetch('/demo-poll-slack-alert', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          question: inputRef.current.value, // Assuming the input is referenced by inputRef
          pollLink: generatedPollLink // Include the generated poll link
        })
      });
  
      if (response.ok) {
        console.log('✅ Slack alert sent successfully.');
      } else {
        console.error('❌ Failed to send Slack alert.');
      }
    } catch (error) {
      console.error('❌ Error sending Slack alert:', error);
    }
  };
  
  

      
      
      
      

  // Callback from EasyBuilder to update creation state.
  const handleCreating = (status) => {
    setIsCreatingPoll(status);
  };

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
  };
  
  
  
  
  
  const handleCreatingForFooter = (status) => {
    setIsCreatingPoll(status);
    if (status) scrollToTop(); // Scroll up when poll creation starts
  };
  
  
  
  

  
  
  

  useEffect(() => {
    // Generate initial background
    setBgDataUrl(
      generateSvgBackgroundDataUrl({
        dotColor,
        bgColor: "#0c0c0c",
        spacing: 20,
        dotRadius: 1,
      })
    );
  }, [dotColor]);


    const handleClick = () => {
      window.location.href = "/signup";
    };


  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % rotatingWords.length);
    }, 2000); // Change every 2 seconds
  
    return () => clearInterval(interval);
  }, []);



  
  

  useEffect(() => {
    setFadeClass("rotating-text-exit"); // Start fade-out
    setTimeout(() => {
      setCurrentText(rotatingWords[index]); // Update text
      setFadeClass("rotating-text-enter"); // Start fade-in
    }, 500); // Match with CSS transition time
  }, [index]);
  
  // Fetch 25 random votes on load
  useEffect(() => {
    fetch('/random-votes')
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch random votes");
        }
        return response.json();
      })
      .then((data) => {
        if (data.votes && data.votes.length > 0) {
          setFetchedVotes(data.votes); // Store the 25 fetched votes
          setVisibleVotes(data.votes.slice(0, 5)); // Initialize with the first 5 votes
        }
      })
      .catch((error) => {
        console.error("Error fetching random votes:", error);
      });
  }, []);

  // Cycle through votes every 2 seconds
  // Cycle through votes at a random interval between 0.5s and 4s
useEffect(() => {
  if (fetchedVotes.length === 0) return; // Ensure we have votes before cycling

  let voteIndex = 5; // Start at index 5 since the first 5 are already shown

  const getRandomInterval = () => Math.random() * (2500 - 500) + 500; // Random time between 500ms and 4000ms

  const cycleVotes = () => {
    setVisibleVotes((prevVotes) => {
      const nextVote = fetchedVotes[voteIndex % fetchedVotes.length]; // Get next vote
      voteIndex++; // Increment index, loop back if needed

      return [nextVote, ...prevVotes.slice(0, 4)]; // Keep only the latest 5 votes
    });

    // Set the next interval with a new random delay
    setTimeout(cycleVotes, getRandomInterval());
  };

  const timeout = setTimeout(cycleVotes, getRandomInterval());

  return () => clearTimeout(timeout);
}, [fetchedVotes]);








useEffect(() => {
  const protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const socket = new WebSocket(`${protocol}://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`);

  socket.onopen = () => console.log("✅ Connected to WebSocket");
  socket.onerror = (error) => console.error("❌ WebSocket Error:", error);
  socket.onclose = () => console.log("❌ WebSocket Disconnected");

  socket.onmessage = async (event) => {
    const data = JSON.parse(event.data);

    if (data.type === "vote") {
      console.log("📩 New vote received:", data.vote);

      setVisibleVotes((prevVotes) => [
        { 
          ...data.vote, 
          pollData: { question: data.vote.pollQuestion } // ✅ Use pollQuestion directly
        },
        ...prevVotes.slice(0, 4),
      ]);
    }
  };

  return () => {
    console.log("🛑 Closing WebSocket connection...");
    socket.close(); // Cleanup on unmount
  };
}, []);








  // Fetch the specific poll data by hash
  // Fetch the poll based on the selected hash
  useEffect(() => {
    const fetchPoll = async () => {
      try {
        const response = await fetch(`/polls/${selectedPollHash}`);
        if (!response.ok) throw new Error("Failed to fetch poll");
  
        const data = await response.json();
        if (data.poll) {
          setPoll(data.poll);
          setAnimationKey(prev => prev + 1); // Update key to trigger animation
        } else {
          throw new Error("Poll data missing in response");
        }
      } catch (error) {
        console.error("Error fetching poll:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPoll();
  }, [selectedPollHash]); // Runs when selectedPollHash changes



  // Combine the two loading conditions (poll fetch and poll creation)
  const pollContainerClass = `home-poll-container home-poll-pop ${loading || isCreatingPoll ? 'loading' : ''}`;


  

 
  

  return (
    <div 
  className="home-bg"
  style={{
    backgroundImage: `url(${bgDataUrl})`,
    backgroundAttachment: "fixed", // Ensures parallax effect
    backgroundRepeat: "repeat", // Ensures the pattern tiles
    backgroundSize: `20px 20px`, // Makes sure the pattern tiles correctly
    minHeight: "100vh",
  }}
>

    
      <div ref={topRef} className="home-container">
        <div className="hero-container">
        <div className="home-hero-section">
   {/* 
     <h1>
        Opinion Driven Affiliate Marketing.
     </h1>

     <p>
        Automatic poll based shopping that earns you more.
        {!isMobile ? <br /> : " "}
        Sign up to start earning or create a demo poll.
     </p>
*/}



<h1>
  Make Money Asking <br />
  <span className={`rotating-text ${fadeClass}`}>{currentText}</span>
</h1>

  <p className="subheading-with-logo">
  Profit from polling on social with
  <img src={AmazonLogo} alt="Amazon Logo" className="amazon-logo" />
</p>

      <EasyBuilder onCreate={handlePollCreated} onCreating={handleCreating} ref={inputRef} />
      {showShare && <HomeShare pollLink={pollLink} />}


            <div className="home-live-votes-container">
  <div className="home-live-votes">
  {visibleVotes.map((vote, index) => (
    <div
      key={vote._id || vote.createdAt}
      className={`home-live-vote ${index === 0 ? "new-vote" : ""}`}
      style={{ zIndex: visibleVotes.length - index }}
      onClick={() => {
        setSelectedPollHash(vote.pollData.hash);
        // Only scroll on mobile devices
        if (isMobile && pollRef.current) {
          pollRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }}      
    >
      {/* Vote Content Container */}
      <div className="home-vote-content">
        {/* Poll Question - Ensure no nested <p> */}
        <span className="home-vote-question">{vote.pollData?.question || "Unknown Poll"}</span>

        {/* Answer and Dot */}
        <div className="home-vote-choice">
          <span className="home-vote-label">{vote.choiceLabel}</span>
          <span
            className="home-vote-dot"
            style={{ backgroundColor: `hsl(${vote.choice}, 100%, 50%)` }}
          ></span>
        </div>
      </div>

      {/* Country Label */}
      <Flag
        code={vote.country}
        size="m"
        hasBorderRadius
      />
    </div>
  ))}
</div>

</div>



{/* <button className="home-sign-up-btn">Sign Up for Free</button> */}


</div>

  
          {/* Right-side container for the live poll */}
          <div ref={pollRef} key={animationKey} className={pollContainerClass}>
          {loading ? (
            <p>Loading poll...</p>
          ) : poll ? (
            <HomePoll
  onColorChange={setDotColor}
  pollId={poll._id}
  question={poll.question}
  labels={poll.labels}
  logo={poll.logo || "/logo-poll.png"}
  logoFill={poll.logoFill}
  votes={poll.votes}
  hash={poll.hash}
  creatorUsername={poll.creator?.username || "Unknown"}
  creatorId={poll.creator?._id || ""}
  isLoggedIn={false}
  userId={null}
  username={""}
  voteAuthenticationMethod={poll.voteAuthenticationMethod}
  confidenceLabels={poll.confidenceLabels}
  imageAction={poll.imageAction}
  roundedCorners={poll.roundedCorners}
  startDate={poll.startDate}
  endDate={poll.endDate}
  scheduledPoll={poll.scheduledPoll}
  userEmail={""}
  totalVotes={poll.totalVotes}
  isTemporary={poll.isTemporary}
  isLegacyPoll={poll.isLegacyPoll}
  approved={poll.approved}
  onVote={scrollToAffiliateSection} // ✅ Add this callback
  backgroundColor={poll.backgroundColor}
/>

          ) : (
            <p>Poll not found</p>
          )}
        </div>


          
        </div>

        















        
       
        <div ref={affiliateMarketingSectionRef} className="second-section">
        <div className="second-section-container">
        <div className="section-header">
        <div className="section-header-text">

          <h2>Poll answers matched to products people purchase.</h2>
          <p>Share everywhere to become the go-to shopping authority on any topic.</p>
          </div>
          <button className="start-earning-button" onClick={handleClick}>
      <img src={Icon} alt="Icon" className="button-icon" />
      <span>Start Earning!</span>
    </button>

    </div>

          <div className="home-card-grid"> 



              <div className="card-grid-item-right"> 


              <div key={animationKey} className="Home-Stats-Poll-Container">
          {loading ? (
            <p>Loading poll...</p>
          ) : statsPoll ? (
            <HomePollStats
    pollId={statsPoll._id}
    question={statsPoll.question}
    labels={statsPoll.labels}
    logo={statsPoll.logo || "/logo-poll.png"}
    logoFill={statsPoll.logoFill}
    votes={statsPoll.votes}
    hash={statsPoll.hash}
    creatorUsername={statsPoll.creator?.username || "Unknown"}
    creatorId={statsPoll.creator?._id || ""}
    voteAuthenticationMethod={statsPoll.voteAuthenticationMethod}
    confidenceLabels={statsPoll.confidenceLabels}
    imageAction={statsPoll.imageAction}
    roundedCorners={statsPoll.roundedCorners}
    startDate={statsPoll.startDate}
    endDate={statsPoll.endDate}
    scheduledPoll={statsPoll.scheduledPoll}
    userEmail={""}
    totalVotes={statsPoll.totalVotes}
    isTemporary={statsPoll.isTemporary}
    isLegacyPoll={statsPoll.isLegacyPoll}
    approved={statsPoll.approved}
    isAdvertising={statsPoll.isAdvertising}
    category={statsPoll.category}
    pollType={statsPoll.pollType}
    ads={statsPoll.ads}
    confidenceLinks={statsPoll.confidenceLinks}
    isFetchingProducts={statsPoll.isFetchingProducts}
    backgroundColor={poll.backgroundColor}
  />
          ) : (
            <p>Poll not found</p>
          )}
        </div>


            </div>


            <div className="card-grid-item-left">
      {/* Shop by Opinion */}
      <div className="feature-item">
        <div className="feature-content">
          <h3>Shop by Opinion</h3>
          <p>Browse items by popular opinion and purchase with confidence.</p>
        </div>
        <div className="feature-icon">
          <ShoppingBag strokeWidth={1.5} />
        </div>
      </div>

      {/* Unlimited Earnings */}
      <div className="feature-item">
        <div className="feature-content">
          <h3>Unlimited Earnings</h3>
          <p>Earn 70% of the affiliate commission on every item sold through your polls.</p>
        </div>
        <div className="feature-icon">
          <HandCoins strokeWidth={1.5} />
        </div>
      </div>

      {/* AI Poll Creation */}
      <div className="feature-item">
        <div className="feature-content">
          <h3>AI Poll Creation</h3>
          <p>Not sure what to ask? No problem, AI generates the most relevant polls with direct product links based on answers, making it profitable every time.</p>
        </div>
        <div className="feature-icon">
          <Cpu strokeWidth={1.5} />
        </div>
      </div>

      {/* Built for Speed */}
      <div className="feature-item">
        <div className="feature-content">
          <h3>Built for Interaction</h3>
          <p>Revolutionary thumb-driven color wheel provides next level engagement.</p>
        </div>
        <div className="feature-icon">
          <Zap strokeWidth={1.5} />
        </div>
      </div>

      <img src={Partners} alt="Partners" className="partners-image" />




      

    </div>

              </div>

              </div>

                
              </div>

        
              <div className="third-section">
  <div className="third-section-container">
  <div className="section-header">

  <button className="start-earning-button" onClick={handleClick}>
      <img src={Icon} alt="Icon" className="button-icon" />
      <span>Start Earning!</span>
    </button>
    
  <div className="section-header-text">

 
    <h2>Start earning in seconds.</h2>
    <p>
    OPINARI’s Dashboard lets you create detailed polls automatically linked to products, organize them, see clicks, view revenue and get paid.
    </p>
    </div>


    </div>

    {/* Remove the extra container around the image */}
    <img src={Builder} alt="Builder" className="builder-image" />
    <button className="Create-Home-Button" onClick={handleScrollToTopAndFocus}>
      Create Poll!
    </button>
  </div>
</div>





<div className="second-section">
        <div className="second-section-container">

        <div className="section-header">
        <div className="section-header-text">
          <h2>Easily Share.</h2>
          <p>Unique URLs and QR codes allow seamless integration to social.</p>
          </div>

          <button className="start-earning-button" onClick={handleClick}>
      <img src={Icon} alt="Icon" className="button-icon" />
      <span>Start Earning!</span>
    </button>



          </div>

          <div className="share-image-container">
          <button className="share-invisible-button" onClick={openShareModal}></button>

{/* Conditionally render PollShareModal */}
{showShareModal && (
  <PollShareModal
    pollLink={`${window.location.origin}/poll/89256d47b8ca`}
    onClose={() => setShowShareModal(false)}
    pollId="dummyPollId" // If needed, replace with actual poll ID
    hash="89256d47b8ca"
  />
)}

          <img src={Share} alt="Share" className="builder-image" />




              </div>
              </div>


                
              </div>


              <div className="third-section">
  <div className="third-section-container">
  <div className="section-header">

  <button className="start-earning-button" onClick={handleClick}>
      <img src={Icon} alt="Icon" className="button-icon" />
      <span>Start Earning!</span>
    </button>
    
  <div className="section-header-text">

 
    <h2>Turn the Comment Section into the Commerce Section.</h2>
    <p>
    Excessive comments are transformed into actionable insights for product promotion.
    </p>
    </div>


    </div>

    <div className="home-card-grid"> 


      
<div className="comments-container">


<YoutubeComments showComments={showComments} />


<img src={Socials} alt="Socials" className="partners-image" />


</div>



<div className="card-grid-item-right"> 


<div key={animationKey} className="Home-Stats-Poll-Container">
{loading ? (
<p>Loading poll...</p>
) : statsPoll ? (
<HomePollStats
    pollId={statsPoll._id}
    question={statsPoll.question}
    labels={statsPoll.labels}
    logo={statsPoll.logo || "/logo-poll.png"}
    logoFill={statsPoll.logoFill}
    votes={statsPoll.votes}
    hash={statsPoll.hash}
    creatorUsername={statsPoll.creator?.username || "Unknown"}
    creatorId={statsPoll.creator?._id || ""}
    voteAuthenticationMethod={statsPoll.voteAuthenticationMethod}
    confidenceLabels={statsPoll.confidenceLabels}
    imageAction={statsPoll.imageAction}
    roundedCorners={statsPoll.roundedCorners}
    startDate={statsPoll.startDate}
    endDate={statsPoll.endDate}
    scheduledPoll={statsPoll.scheduledPoll}
    userEmail={""}
    totalVotes={statsPoll.totalVotes}
    isTemporary={statsPoll.isTemporary}
    isLegacyPoll={statsPoll.isLegacyPoll}
    approved={statsPoll.approved}
    isAdvertising={statsPoll.isAdvertising}
    category={statsPoll.category}
    pollType={statsPoll.pollType}
    ads={statsPoll.ads}
    confidenceLinks={statsPoll.confidenceLinks}
    isFetchingProducts={statsPoll.isFetchingProducts}

  />
) : (
<p>Poll not found</p>
)}
</div>


</div>



</div>
  </div>
</div>





        </div>

        <footer className="footer">
      <div className="footer-content">
      <EasyBuilder onCreate={handlePollCreated} onCreating={handleCreatingForFooter} scrollToTop={scrollToTop} />

      <div className="footer-socials">
          <a href="https://www.youtube.com/@Opinaripolls" className="footer-icon"><Youtube strokeWidth={1.25} /></a>
          <a href="https://www.instagram.com/opinari.io/" className="footer-icon"><Instagram strokeWidth={1.25} /></a>
          <a href="https://x.com/Opinaripolls" className="footer-icon"><Twitter strokeWidth={1.25} /></a>
          <a href="https://www.linkedin.com/company/opinari-technologies/" className="footer-icon"><Linkedin strokeWidth={1.25} /></a>
        </div>

        <div className="footer-links">
          <a href="/privacy">Privacy</a>
          <a href="/terms">Terms</a>
          <a href="/contact">Contact</a>
        </div>

        <p className="footer-copyright">
          &copy; 2025 OPINARI Technologies Inc.
        </p>

        <p className="footer-patent">Patent Pending</p>
      </div>
    </footer>

      </div>

  );
  
  
  
  
};

export default Home;










