import React from "react";
import "./Onboarding.css";
import { useNavigate } from "react-router-dom";

const Onboarding = ({ username, headline, subheadline }) => {
  const navigate = useNavigate();

  // const suggestions = [
  //   {
  //     question: "What should my next video be about?",
  //     answers: ["Tech", "Gaming", "Vlogging", "Tips & Tricks"],
  //     confidenceLevels: [
  //       ["Smartphones", "Laptops", "AI"],
  //       ["Let's Play", "Game Reviews", "Competitive"],
  //       ["Day in the life", "Travel", "Pranks"],
  //       ["Tech", "Business", "Travel"],
  //     ],
  //   },
  //   {
  //     question: "Which product should I review next?",
  //     answers: ["Smartphone", "Laptop", "Headphones", "Gaming Console"],
  //     confidenceLevels: [
  //       ["Budget", "Mid-range", "Flagship"],
  //       ["Entry-level", "Productive", "Gaming"],
  //       ["Casual", "Audiophile", "Studio"],
  //       ["Sony", "Microsoft", "Steam"],
  //     ],
  //   },
  //   {
  //     question: "What time should I go live?",
  //     answers: ["Morning", "Afternoon", "Evening", "Late Night"],
  //     confidenceLevels: [
  //       ["6-9 AM", "9-11 AM", "11 AM - 1 PM"],
  //       ["1-3 PM", "3-5 PM", "5-7 PM"],
  //       ["7-9 PM", "9-11 PM", "11 PM - 1 AM"],
  //       ["1-3 AM", "3-5 AM", "5-6 AM"],
  //     ],
  //   },
  //   {
  //     question: "What type of giveaways do you prefer?",
  //     answers: ["Cash", "Tech Gadgets", "Gift Cards", "Merch"],
  //     confidenceLevels: [
  //       ["Small Amount", "Medium", "Large Prize"],
  //       ["Smartphones", "Laptops", "Gaming PC's"],
  //       ["Steam", "Apple", "Amazon"],
  //       ["Stickers", "T-Shirts", "Hats"],
  //     ],
  //   },
  //   {
  //     question: "What type of merch should I drop?",
  //     answers: ["T-Shirts", "Hoodies", "Accessories", "Limited Edition"],
  //     confidenceLevels: [
  //       ["Graphic Tees", "Minimalist", "Bold Designs"],
  //       ["Pullover", "Zip-Up", "Oversized"],
  //       ["Phone Cases", "Stickers", "Hats"],
  //       ["Signed", "Limited Stock", "Exclusive Colors"],
  //     ],
  //   },
  //   {
  //     question: "Who should I collab with next?",
  //     answers: ["Tech YouTuber", "Gaming Streamer", "Vlogger", "Comedian"],
  //     confidenceLevels: [
  //       ["Small Creator", "Growing Creator", "Top Creator"],
  //       ["Casual Gamer", "Competitive Gamer", "Pro Player"],
  //       ["Daily Vlogger", "Travel Vlogger", "Lifestyle Vlogger"],
  //       ["Funny Clips", "Comedy Sketches", "Stand-up"],
  //     ],
  //   },
  // ];



  const suggestions = [
    {
      question: "Which phone brand and model do you prefer?",
      answers: ["Apple", "Samsung", "Google", "OnePlus"],
      confidenceLevels: [
        ["iPhone 15", "iPhone 15 Pro", "iPhone 15 Pro Max"],
        ["Galaxy S24", "Galaxy Z Fold", "Galaxy A Series"],
        ["Pixel 8", "Pixel 8 Pro", "Pixel Fold"],
        ["OnePlus 12", "OnePlus Nord", "OnePlus Open"],
      ],
    },
    {
      question: "What’s your go-to lipstick brand?",
      answers: ["MAC", "Fenty Beauty", "Maybelline", "Charlotte Tilbury"],
      confidenceLevels: [
        ["Matte", "Satin", "Gloss"],
        ["Liquid Lipstick", "Lip Balm", "Lip Stain"],
        ["Drugstore", "Long-lasting", "Moisturizing"],
        ["Luxury", "Nude Shades", "Bold Colors"],
      ],
    },
    {
      question: "Which automotive accessory is a no-brainer?",
      answers: ["Dash Cam", "Wireless Charger", "LED Headlights", "Seat Covers"],
      confidenceLevels: [
        ["Front & Rear", "Night Vision", "4K"],
        ["MagSafe", "Fast Charging", "Vent Mount"],
        ["White Light", "Multi-color", "Smart Control"],
        ["Leather", "Fabric", "Heated"],
      ],
    },
    {
      question: "What should I drop as swag on my next post?",
      answers: ["Stickers", "T-Shirts", "Hats", "Phone Cases"],
      confidenceLevels: [
        ["Minimalist", "Bold Logos", "Holographic"],
        ["Graphic", "Plain", "Oversized"],
        ["Snapback", "Dad Hat", "Beanie"],
        ["Soft Shell", "Custom Print", "Limited Edition"],
      ],
    },
    {
      question: "What is your favorite style of pizza 🍕?",
      answers: ["New York", "Chicago Deep Dish", "Neapolitan", "Detroit"],
      confidenceLevels: [
        ["Thin Crust", "Foldable", "Classic Toppings"],
        ["Thick Crust", "Layered Cheese", "Tomato on Top"],
        ["Wood-Fired", "Fresh Mozzarella", "Basil"],
        ["Square Cut", "Crispy Edges", "Cheese on Edge"],
      ],
    },
    {
      question: "What is your favorite brand of sunglasses?",
      answers: ["Ray-Ban", "Oakley", "Persol", "Gucci"],
      confidenceLevels: [
        ["Wayfarer", "Aviator", "Clubmaster"],
        ["Sport", "Polarized", "Wraparound"],
        ["Handmade", "Classic", "Timeless"],
        ["Luxury", "Trendy", "Statement"],
      ],
    },
    {
      question: "What's the best bang for buck PC hard drive?",
      answers: ["Seagate", "Western Digital", "Samsung", "Crucial"],
      confidenceLevels: [
        ["HDD", "SSD", "NVMe"],
        ["Blue Series", "Black Series", "Red Series"],
        ["SATA", "M.2", "External"],
        ["Gaming", "Enterprise", "Everyday Use"],
      ],
    },
    {
      question: "Which Amazon Game is your Fav?",
      answers: ["New World", "Lost Ark", "Tomb Raider", "Blue Protocol"],
      confidenceLevels: [
        ["PvE", "PvP", "Crafting"],
        ["MMORPG", "Dungeon Crawling", "Guild Wars"],
        ["Story-Driven", "Action-Adventure", "Exploration"],
        ["Anime RPG", "Open World", "Multiplayer"],
      ],
    },
];

  





  const handleSuggestionClick = (suggestion) => {
    navigate("/dashboard/main?openTab=Create Poll", {
      state: {
        question: suggestion.question,
        customLabels: suggestion.answers,
        customConfidenceLabels: suggestion.confidenceLevels, // Ensure this is passed
      },
    });
  };
  
  
  
  

  return (
    <div className="Onboarding-container">
      <div className="Onboarding-card">
        <h1 className="Onboarding-welcome">{headline || `${username}, Welcome Aboard!`}</h1>
        <p className="Onboarding-subtitle">
          {subheadline ||
            "Start by creating a poll, then share it with your audience to start earning!"}
        </p>
        <button
          className="Onboarding-create-button"
          onClick={() => navigate("/dashboard/main?openTab=Create Poll")}
        >
          Create Poll +
        </button>

        <iframe 
    src="https://www.youtube.com/embed/Qjaydhptk8o?si=wR9Wbiq8hW91Ahji"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerPolicy="strict-origin-when-cross-origin"
    allowFullScreen
    style={{
        width: "100%",
        height: "auto",
        aspectRatio: "16 / 9",
        borderRadius: "10px",
        marginBottom: '5px',
    }}
></iframe>


        <p className="Onboarding-suggestion">Not sure what to ask? How about...</p>

        <div className="Onboarding-suggestions-list">
  {suggestions.map((suggestion, index) => (
    <button
      key={index}
      className="Onboarding-suggestion-item"
      onClick={() => handleSuggestionClick(suggestion)} // Pass as a function
    >
      {suggestion.question}
      <span className="Onboarding-arrow">→</span>
    </button>
  ))}
</div>
      </div>
    </div>
  );
};

export default Onboarding;
